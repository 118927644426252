import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  Pagination,
  Tooltip,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import styles from "./index.module.css";
import {
  CheckCircle,
  Circle,
  DownloadSimple,
  FlowArrow,
  PencilSimple,
  Sparkle,
  SparkleOutlined,
} from "components/newSVG";
import FlexBox from "components/FlexBox";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown } from "components/SVG";
import { useCallback, useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import { useURLState } from "custom-hooks/useUrlState";
import theme from "theme";
import { sampleCSVLink } from "text-constants/common";
import {
  Check,
  ChevronRight,
  PlusCircle,
  Trash2,
  Type,
  X,
} from "react-feather";
import FunCollapse from "components/FunCollapse";
import ProgressBar from "components/ProgressBar";
import {
  getQuesAnsInKnowledgeBase as getQuesAnsInKnowledgeBaseApi,
  getAllWorkFlow as getAllWorkFlowApi,
  getCategoriesByCompany as getCategoriesByCompanyApi,
  createQuesAnsInKnowledgeBase as createQuesAnsInKnowledgeBaseApi,
  editQuesAnsInKnowledgeBase as editQuesAnsInKnowledgeBaseApi,
  deleteAnswer as deleteAnswerAPI,
  downloadQuestionAnswerCsv as downloadQuestionAnswerCsvApi,
  quesAndAnsProcessCsv as quesAndAnsProcessCsvApi,
  preProcessBeforeTranscription as preProcessBeforeTranscriptionApi,
} from "services";
import Loader from "components/Loader";
import EditFaqModal from "pages/DatasetDashboard/components/EditFaqModal";
import DeleteModal from "components/DeleteModal";
import fileDownload from "js-file-download";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { getJSONDecoded } from "utils/utils";
import { Loader as LoaderIcon } from "react-feather";
import { useAdminSocket } from "context/socket";
import { useUserContext } from "context/user";
import ManageCategories from "../components/ManageCategories";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "components/SearchBox";
import _ from "lodash";

const csvFileTypes = ["text/csv"];

const Intent = () => {
  const { showSkillrToast } = useSkillrToast();
  const [categoryList, setCategoryList] = useState();
  const [categoryFilter, setCategoryFilter] = useURLState([], "category");
  const [answerTypeFilter, setAnswerTypeFilter] = useURLState("", "answerType");
  const [intents, setIntents] = useState();
  const [loading, setLoading] = useState(false);
  const [editFaqModal, setEditFaqModal] = useState(false);
  const [quesAnsIndex, setQuesAnsIndex] = useState(null);
  const [workflows, setWorkFlows] = useState([]);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [deleteModal, setDeleteModal] = useState(false);
  const [csvUploadStatus, setCsvUploadStatus] = useState();
  const [manageCategoryModal, setManageCategoryModal] = useState(false);
  const answerTypes = [
    { name: "Workflow", id: "workflow" },
    { name: "FAQ", id: "faq" },
    { name: "Smart+", id: "smartPlus" },
  ];
  const {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    uploadStatus,
    uploadFiles,
  } = useFileUpload({
    fileTypes: csvFileTypes,
    maxUploadLimit: 0,
    sizeAlreadyConsumed: 0,
  });
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ?? null);
  const companyInfo = tokenInfo?.companies?.[0];
  const socket = useAdminSocket(tokenInfo?.companies?.[0]?.id);
  const { companyDetails } = useUserContext();
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const navigate = useNavigate();
  const handleSearchChange = (value) => {
    setCurrentPage(1);
    setSearchBoxValue(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedSearch = useCallback(_.debounce(handleSearchChange, 1500), []);

  const columns = [
    {
      field: "intents",
      header: (
        <Box display="flex" alignItems="center">
          <Typography variant="caption" color="#262626">
            intents ({intents?.totalIntentsCount ?? 0})
          </Typography>
          {/* <Box className={styles.nodes}>
            <Typography variant="caption">100</Typography>
          </Box> */}
        </Box>
      ),
    },
    { field: "language", header: "Language" },
    { field: "category", header: "Category" },
    { field: "answerType", header: "Intent Type" },
    {
      field: "actions",
      header: "Actions",
    },
  ];

  const getCategory = async () => {
    try {
      const response = await getCategoriesByCompanyApi();
      setCategoryList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const handleFilter = (e, filter, setFilter) => {
    const selectedFilter = e.target.value;
    let newFilter;
    if (Array.isArray(filter)) {
      newFilter = [...filter];
      const index = filter.indexOf(selectedFilter);
      if (index > -1) {
        newFilter.splice(index, 1);
      } else {
        newFilter.push(selectedFilter);
      }
    } else {
      newFilter = selectedFilter === filter ? "" : selectedFilter;
    }
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const getQuesAns = async (queryParams) => {
    try {
      const quesAns = await getQuesAnsInKnowledgeBaseApi(queryParams);
      return quesAns;
    } catch (error) {
      throw error;
    }
  };

  const getTableData = async () => {
    try {
      setLoading(true);
      const newQueryParams = new URLSearchParams();
      newQueryParams.set("pageNumber", currentPage);
      newQueryParams.set("categoryIds", categoryFilter);
      newQueryParams.set("answerType", answerTypeFilter);
      newQueryParams.set("search", searchBoxValue);

      const quesAns = await getQuesAns(newQueryParams.toString());
      setIntents(quesAns);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getAllWorkFlow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlows(response);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const addQuesAns = async (newQuesAns) => {
    try {
      const apiData = {
        question: newQuesAns?.question?.question,
        category: newQuesAns?.category,
        questionVariant: newQuesAns?.questionVariant,
        ...(newQuesAns.answer && { answer: newQuesAns.answer }),
        ...(newQuesAns.workflow && { workFlow: newQuesAns.workflow }),
        type: newQuesAns?.type,
        languageVariations: newQuesAns?.languageVariations,
      };
      const addQuesAnsResponse = await createQuesAnsInKnowledgeBaseApi(apiData);
      return addQuesAnsResponse;
    } catch (error) {
      throw error;
    }
  };

  const editQuesAns = async (editedQuesAns) => {
    try {
      const apiData = {
        _id: editedQuesAns.id,
        question: editedQuesAns?.question?.question,
        primaryQuestionReplacedWithVariantId:
          editedQuesAns?.question === intents?.data?.[quesAnsIndex]?.question
            ? null
            : editedQuesAns.question?.id,
        questionVariant: editedQuesAns.questionVariant,
        ...(editedQuesAns.answer && { answer: editedQuesAns.answer }),
        ...(editedQuesAns.workflow && { workFlow: editedQuesAns.workflow }),
        category: editedQuesAns?.category,
        type: editedQuesAns?.type,
        languageVariations: editedQuesAns?.languageVariations,
      };
      const editQuesAnsResponse = await editQuesAnsInKnowledgeBaseApi(apiData);
      return editQuesAnsResponse;
    } catch (error) {
      throw error;
    }
  };

  const handleManualQuesAns = (index) => {
    setEditFaqModal(true);
    if (index !== null) {
      setQuesAnsIndex(index);
    }
  };

  const handleCloseQAManually = () => {
    setEditFaqModal(false);
    setQuesAnsIndex(null);
  };

  const handleSaveQAManually = async (formData) => {
    try {
      if (quesAnsIndex !== null) {
        await editQuesAns(formData);
      } else {
        await addQuesAns(formData);
      }
      handleCloseQAManually();
      getTableData();
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const downloadCSV = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("answerType", answerTypeFilter);
      queryParams.set("category", categoryFilter.join(","));
      const response = await downloadQuestionAnswerCsvApi(null, queryParams, {
        responseType: "blob",
      });
      fileDownload(response, "CSV_file.csv");
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const deleteAnswer = async () => {
    try {
      const response = await deleteAnswerAPI(quesAnsIndex);
      if (response) {
        setDeleteModal(false);
        showSkillrToast("Answer deleted successfully", "success");

        const newIntents = { ...intents };

        const deletedIntentIndex = newIntents?.data.findIndex(
          (intent) => intent._id === quesAnsIndex
        );
        newIntents?.data?.splice(deletedIntentIndex, 1);

        setIntents(newIntents);
        setQuesAnsIndex(null);

        if (newIntents?.data?.length === 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleDeleteQuesAns = (quesId) => {
    setQuesAnsIndex(quesId);
    setDeleteModal(true);
  };

  const handleUploadCsvFiles = () => {
    fileInputRef.current.accept = csvFileTypes.join(",");
    fileInputRef.current.removeAttribute("multiple");
    triggerFileInput();
  };

  const handleGenerateUsingAI = () => {
    navigate("/ai-labs");
  };

  const preProcessBeforeTranscription = async (files) => {
    try {
      const apiData = files.map((file) => ({
        category: "",
        gcsUri: `gs://${process.env.REACT_APP_GCS_BUCKET_NAME}/${companyInfo.id}/${file.newFileName}`,
        name: file.newFileName,
        size: file.size,
        extension: file.extension,
      }));
      const response = await preProcessBeforeTranscriptionApi(apiData);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const processCsvFile = async (file) => {
    try {
      const apiData = {
        gcsUri: `gs://${process.env.REACT_APP_GCS_BUCKET_NAME}/${companyInfo.id}/${file.newFileName}`,
        fileSize: file.size,
      };
      const response = await quesAndAnsProcessCsvApi(apiData);
      showSkillrToast(
        <>
          Question created {response?.questionsCreated} <br />
          Question deleted {response?.questionsDeleted} <br />
          Question updated {response?.questionsUpdated}
        </>,
        "success"
      );
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const trackUploadStatus = async () => {
    if (
      uploadStatus?.status === "filesValidated" &&
      uploadStatus?.files?.length > 0
    ) {
      uploadFiles(uploadStatus?.files);
    }

    // Handling csv upload when all files get uploaded to bucket
    if (uploadStatus?.status === "completed") {
      await preProcessBeforeTranscription(uploadStatus?.files);
      await processCsvFile(uploadStatus?.files[0]);
      getTableData();
      /**
       * Calling get category api again so that we can get all the new categories created by csv upload
       */
      getCategory();
    }
  };

  const handleCsvDuplicationCheck = (csvUploadData) => {
    setCsvUploadStatus(csvUploadData);
  };

  const handleErrorMessage = (error) => {
    const errorMessage = error?.message
      ? error.messsage
      : "Something went wrong";
    showSkillrToast(errorMessage, "error");
  };

  const handleSocketUpdate = () => {
    socket.on("duplicate-check-status", (data) => {
      try {
        handleCsvDuplicationCheck(data);
      } catch (error) {
        handleErrorMessage(error);
      }
    });
  };

  const handleOnCloseManageCategories = () => {
    setManageCategoryModal(false);
  };

  const getRowIcon = (row) => {
    if (row?.type) {
      if (row?.type === "workflow") {
        return <FlowArrow width={12} height={12} />;
      } else if (row?.type === "faq") {
        return <Type width={12} height={12} />;
      } else {
        return <SparkleOutlined width={12} height={12} />;
      }
    } else {
      if (row?.answer) {
        return <Type width={12} height={12} />;
      } else {
        return <FlowArrow width={12} height={12} />;
      }
    }
  };

  const getRowType = (row) => {
    if (row?.type) {
      if (row?.type === "workflow") {
        // return (
        //   <span fontSize={10} color={theme.palette.primary.main}>
        //     {row?.workFlow?.name}
        //   </span>
        // );
        return "Workflow";
      } else if (row?.type === "faq") {
        return "FAQ";
      } else {
        return "Smart+";
      }
    } else {
      if (row?.answer) {
        return "FAQ";
      } else {
        return "Workflow";
      }
    }
  };

  const isFilterApplied = () => {
    if (categoryFilter.length > 0 || answerTypeFilter) {
      return true;
    } else {
      return false;
    }
  };

  const clearAllFilters = () => {
    setCategoryFilter([]);
    setAnswerTypeFilter("");
  };

  useEffect(() => {
    getCategory();
    getAllWorkFlow();
    handleSocketUpdate();
  }, []);

  useEffect(() => {
    getTableData();
  }, [currentPage, categoryFilter, answerTypeFilter, searchBoxValue]);

  useEffect(() => {
    trackUploadStatus();
  }, [uploadStatus]);

  return (
    <>
      {["started", "processing"].indexOf(uploadStatus?.status) > -1 && (
        <ProgressBar
          startIcon={
            uploadStatus?.status === "completed" ? (
              <Check width={16} height={16} />
            ) : (
              <LoaderIcon width={16} height={16} />
            )
          }
          message={
            uploadStatus?.status === "completed"
              ? "File uploaded"
              : "Uploading files"
          }
          progress={
            (uploadStatus?.processedFiles / uploadStatus?.totalFiles) * 100
          }
          cancelGeneration={false}
          handleCancelGeneration={() => {}}
        />
      )}

      {csvUploadStatus &&
        csvUploadStatus?.processed !== csvUploadStatus?.total && (
          <ProgressBar
            startIcon={
              csvUploadStatus?.processed === csvUploadStatus?.total ? (
                <Check width={16} height={16} />
              ) : (
                <LoaderIcon width={16} height={16} />
              )
            }
            message={
              csvUploadStatus?.processed === csvUploadStatus?.total
                ? "Duplication check completed"
                : "Checking duplicate in csv"
            }
            progress={Math.floor(
              (csvUploadStatus?.processed / csvUploadStatus?.total) * 100
            )}
            cancelGeneration={false}
            handleCancelGeneration={() => {}}
          />
        )}

      <Box className={styles.container}>
        <FlexBox sx={{ padding: "24px 48px 0px" }}>
          <FundamentoPopup
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            disableCloseOnClick={true}
            triggeringComponent={
              <Button
                variant="outlined"
                className={categoryFilter.length > 0 && styles.activeFilter}
                sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                endIcon={<ChevronDown width={16} height={16} />}
              >
                Category
              </Button>
            }
          >
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={categoryList}
              getOptionLabel={(option) => option.name}
              value={categoryFilter}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} id={option?._id}>
                    <Checkbox
                      value={option?._id}
                      style={{ marginRight: 8 }}
                      checked={categoryFilter.indexOf(option?._id) > -1}
                    />
                    <Typography variant="body2">{option?.name}</Typography>
                  </li>
                );
              }}
              onChange={(event, value, reason) => {
                const parent = event.target.closest(["li"]);
                if (
                  (reason === "selectOption" || reason === "removeOption") &&
                  parent?.id
                ) {
                  const dataObj = { target: { value: parent.id } };
                  handleFilter(dataObj, categoryFilter, setCategoryFilter);
                }

                if (reason === "clear") {
                  setCategoryFilter([]);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      variant="outlined"
                      label={categoryList.find((c) => c._id === option)?.name}
                      {...getTagProps({ index })}
                      onDelete={() => {
                        handleFilter(
                          { target: { value: option } },
                          categoryFilter,
                          setCategoryFilter
                        );
                      }}
                    />
                  );
                })
              }
              isOptionEqualToValue={(option, valueId) => {
                return option?._id === valueId;
              }}
              style={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search" />
              )}
            />
          </FundamentoPopup>

          <FundamentoPopup
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            disableCloseOnClick={true}
            triggeringComponent={
              <Button
                variant="outlined"
                className={answerTypeFilter.length > 0 && styles.activeFilter}
                sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                endIcon={<ChevronDown width={16} height={16} />}
              >
                Intent Type
              </Button>
            }
          >
            <Box style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              {answerTypes?.map((item) => (
                <FlexBox columnGap={2} component="label">
                  <Checkbox
                    icon={<Circle />}
                    checkedIcon={<CheckCircle />}
                    value={item?.id}
                    onChange={(e) =>
                      handleFilter(e, answerTypeFilter, setAnswerTypeFilter)
                    }
                    checked={item.id === answerTypeFilter}
                  />
                  <Typography variant="body2">{item?.name}</Typography>
                </FlexBox>
              ))}
            </Box>
          </FundamentoPopup>

          {isFilterApplied() && (
            <Tooltip title="Clear all filters">
              <IconButton onClick={clearAllFilters}>
                <X width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}

          <Box className={styles.searchContainer}>
            <SearchBox
              placeholder={"Search"}
              disabled={loading}
              onChange={optimizedSearch}
              defaultValue={searchBoxValue}
              onClear={() => setSearchBoxValue("")}
            />
          </Box>

          <Box ml="auto" display="flex" gap={3}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 82,
                padding: "8px 14px",
                minWidth: 0,
              }}
              onClick={() => setManageCategoryModal(true)}
            >
              Manage categories
            </Button>
            <FundamentoPopup
              triggeringComponent={
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Actions
                </Button>
              }
              className={styles.popup}
            >
              <Box>
                <Box
                  className={styles.popupItem}
                  onClick={handleUploadCsvFiles}
                >
                  <PlusCircle width={16} height={16} />
                  <Typography variant="body2">Upload csv</Typography>
                </Box>
                {companyDetails?.canDownLoadTheFAQCsv && (
                  <Box className={styles.popupItem} onClick={downloadCSV}>
                    <DownloadSimple width={16} height={16} />
                    <Typography variant="body2">Download csv</Typography>
                  </Box>
                )}
                <Box
                  className={styles.popupItem}
                  component={"a"}
                  href={sampleCSVLink}
                  download
                  sx={{ color: theme.palette.text.primary }}
                >
                  <DownloadSimple width={16} height={16} />
                  <Typography variant="body2">Download sample csv</Typography>
                </Box>
                {/* <Box
                  className={styles.popupItem}
                  onClick={() => handleManualQuesAns(null)}
                >
                  <PlusCircle width={16} height={16} />
                  <Typography variant="body2">Add Intent</Typography>
                </Box> */}
              </Box>
            </FundamentoPopup>

            <Button
              variant="dark"
              sx={{ minWidth: 0, padding: "8px 14px" }}
              onClick={() => handleManualQuesAns(null)}
              startIcon={<PlusCircle width={16} height={16} />}
            >
              Add Intent
            </Button>
          </Box>
        </FlexBox>

        {loading && <Loader />}

        {!loading && intents?.data?.length === 0 && (
          <Box className={styles.emptyStateContainer}>
            <Typography variant="h3" fontWeight={500}>
              No intents found
            </Typography>
            <Typography variant="body2" mt={2}>
              We’ll collect all your new questions here. Make sure to check
              again later.
            </Typography>

            {!searchBoxValue &&
              !answerTypeFilter &&
              categoryFilter.length === 0 && (
                <Box mt={6} className={styles.buttonsContainer}>
                  <Button
                    variant="outline"
                    className={styles.button}
                    startIcon={<Sparkle />}
                    onClick={handleGenerateUsingAI}
                  >
                    Generate using AI
                  </Button>
                  <Button
                    variant="outline"
                    className={styles.button}
                    onClick={handleUploadCsvFiles}
                  >
                    Import from CSV
                  </Button>
                  <Button
                    variant="outline"
                    className={styles.button}
                    onClick={() => handleManualQuesAns(null)}
                  >
                    Add intent
                  </Button>
                </Box>
              )}
          </Box>
        )}

        {intents?.data?.length > 0 && (
          <>
            <Box
              mt={4}
              sx={{
                position: "relative",
                flex: 1,
                overflowY: "auto",
                padding: "0px 48px",
              }}
            >
              <Table className={styles.table}>
                <TableHead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1000,
                  }}
                >
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        style={
                          column.header === "Actions"
                            ? { textAlign: "right" }
                            : {}
                        }
                        key={index}
                      >
                        <Typography
                          variant="caption"
                          fontWeight={500}
                          color="#262626"
                        >
                          {column.header}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {intents?.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ width: "80%" }}>
                        <FunCollapse
                          iconPosition={"start"}
                          header={
                            <FlexBox columnGap={2}>
                              <Typography
                                variant="caption"
                                fontWeight={400}
                                color={theme.palette.grey[800]}
                              >
                                {row.question}
                              </Typography>
                              {row?.questionVariant?.length > 0 && (
                                <Box className={styles.questionVariant}>
                                  <Typography
                                    fontWeight={500}
                                    fontSize={10}
                                    lineHeight="normal"
                                  >
                                    +{row?.questionVariant?.length} var
                                  </Typography>
                                  <ChevronRight
                                    width={8}
                                    height={8}
                                    color="#0094FF"
                                  />
                                </Box>
                              )}
                            </FlexBox>
                          }
                        >
                          {row?.answer ? (
                            <Typography
                              variant="caption"
                              component="p"
                              mt={2}
                              fontWeight={400}
                              color={theme.palette.grey[800]}
                              style={{
                                paddingLeft: 22,
                                whiteSpace: "pre-line",
                              }}
                            >
                              {row.answer}
                            </Typography>
                          ) : (
                            <FlexBox
                              sx={{ paddingLeft: "22px", marginTop: "8px" }}
                            >
                              <FlowArrow
                                width={12}
                                height={12}
                                className={styles.flowArrow}
                                gap={1}
                              />
                              <Typography
                                fontSize={10}
                                color={theme.palette.primary.main}
                              >
                                Workflow linked:{" "}
                                <Link
                                  to={`/workflows/${row?.workFlow?._id}`}
                                  target="_blank"
                                >
                                  {row?.workFlow?.name}
                                </Link>
                              </Typography>
                            </FlexBox>
                          )}
                        </FunCollapse>
                      </TableCell>

                      <TableCell>
                        <FlexBox className={styles.languagesBox}>
                          {Object.keys(row?.languageVariations).map(
                            (language, index) => {
                              if (index < 2) {
                                return (
                                  <Box className={styles.box} key={index}>
                                    <Typography
                                      fontSize={10}
                                      fontWeight={500}
                                      lineHeight="normal"
                                      color={"grey.400"}
                                    >
                                      {language.slice(0, 2).toUpperCase()}
                                    </Typography>
                                  </Box>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                          {Object.keys(row?.languageVariations) > 2 && (
                            <Box className={styles.box}>
                              <Typography
                                fontSize={10}
                                fontWeight={500}
                                lineHeight="normal"
                                color={"grey.400"}
                              >
                                +
                                {Object.keys(row?.languageVariations).length -
                                  2}
                              </Typography>
                            </Box>
                          )}
                        </FlexBox>
                      </TableCell>

                      <TableCell>
                        <Box className={styles.box}>
                          <Typography
                            fontSize={10}
                            fontWeight={500}
                            lineHeight="normal"
                          >
                            {row?.category?.name}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <FlexBox
                          className={styles.box}
                          gap="6px"
                          justifyContent="space-around"
                        >
                          {getRowIcon(row)}

                          <Typography
                            fontSize={10}
                            fontWeight={500}
                            lineHeight="normal"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {getRowType(row)}
                          </Typography>
                        </FlexBox>
                      </TableCell>

                      <TableCell align="right">
                        <>
                          <FlexBox columnGap={0} width="max-content" ml="auto">
                            <IconButton
                              onClick={() => handleManualQuesAns(index)}
                            >
                              <PencilSimple
                                color="#0f0f0f"
                                width={16}
                                height={16}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteQuesAns(row._id)}
                            >
                              <Trash2 width={16} height={16} />
                            </IconButton>
                          </FlexBox>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box className={styles.pagination}>
                <Pagination
                  page={currentPage}
                  count={intents?.pageCount}
                  shape="rounded"
                  onChange={(e, pageCount) => {
                    setCurrentPage(pageCount);
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      <EditFaqModal
        open={editFaqModal}
        onClose={() => {
          setEditFaqModal(false);
          setQuesAnsIndex(null);
        }}
        question={intents?.data?.[quesAnsIndex]}
        onSave={handleSaveQAManually}
        workflows={workflows?.workFlows}
        loading={false}
        categories={categoryList}
        categoryKey="category"
        questionIdKey="_id"
      />

      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={deleteAnswer}
        title={"Delete Intent?"}
        subtitle={
          "This action can't be undone and the data will be deleted from your account permanently"
        }
        list={[
          "This will delete the answer permanently",
          "You will have to regenerate the answer",
        ]}
      />

      <ManageCategories
        open={manageCategoryModal}
        onClose={handleOnCloseManageCategories}
      />

      {/* Getting used for file upload */}
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </>
  );
};

export default Intent;
