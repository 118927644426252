import FundamentoTable from "components/FundamentoTable";
import styles from "./index.module.css";
import { Box, Typography } from "@mui/material";
import { statusColorMapping, statusTextMapping } from "text-constants/campaign";
import PropTypes from "prop-types";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const DetailsList = (props) => {
  const navigate = useNavigate();
  const {
    campaingContacts,
    currentPage,
    totalPageCount,
    setCurrentPage,
    isPreProcessEnabled,
  } = props;

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id && row.id !== "") {
      const selectedCampaign = campaingContacts.find(
        (cd) => cd.voiceCallId === row.id
      );
      navigate(
        `/calls/${selectedCampaign.callSessionId}?voiceCallId=${selectedCampaign.voiceCallId}`
      );
    }
  };

  const columns = [
    {
      field: "callId",
      header: "Call ID",
      render: (row) => {
        return row?.voiceCallId ?? "-";
      },
    },
    {
      field: "phoneNumber",
      header: "Phone Number",
      render: (row) => {
        return row?.contactNumber;
      },
    },
    {
      field: "status",
      header: "Status",
      render: (row) => {
        return (
          <Box
            className={styles.box}
            sx={{ background: statusColorMapping[row?.status].backgroundColor }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight="normal"
              color={statusColorMapping[row?.status].color}
            >
              {statusTextMapping[row?.status] ?? "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "callDuration",
      header: "Call Duration (Sec)",
      render: (row) => {
        return row?.callDuration ?? "N/A";
      },
    },
    {
      field: "redialAttempts",
      header: "Redial Attempts",
      render: (row) => {
        return row?.redialAttempts ?? "N/A";
      },
    },
    {
      field: "callBack",
      header: "Callback",
      render: (row) => {
        return row?.callBack ?? "N/A";
      },
    },
    {
      field: "callSummary",
      header: "Call Summary",
      render: (row) => {
        return row?.callSummary ?? "N/A";
      },
    },
    ...(isPreProcessEnabled
      ? [
          {
            field: "PreProcessStatus",
            header: "Preprocess Status",
            render: (row) => {
              return (
                <Box
                  className={styles.box}
                  sx={{ background: row?.preProcessed ? "#E8F5E9" : "#FFF4CB" }}
                >
                  <Typography
                    fontSize={10}
                    fontWeight={500}
                    lineHeight="normal"
                    color={row?.preProcessed ? "#388E3C" : "#8A6C00"}
                  >
                    {row?.preProcessed ? "Processed" : "Pending"}
                  </Typography>
                </Box>
              );
            },
          },
        ]
      : []),
    {
      field: "dateTime",
      header: "Date and Time",
      render: (row) => {
        return moment(row?.createdAt).format("D MMM, hh:mm a");
      },
    },
  ];

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const handleTableOnClick = () => {};

  return (
    <FundamentoTable
      columns={columns}
      data={campaingContacts}
      passRowToRender={true}
      pagination={true}
      currentPage={currentPage}
      onPageChange={handlePageOnChange}
      totalPageCount={totalPageCount}
      tableOnClick={handleTableOnClick}
      className={styles.table}
      rowIdKey="voiceCallId"
    />
  );
};

DetailsList.propTypes = {
  campaingContacts: PropTypes.array,
  currentPage: PropTypes.number,
  totalPageCount: PropTypes.number,
};

export default DetailsList;
