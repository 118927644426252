import moment from "moment";
import { lighten, darken } from "@mui/material";
// import AssessmentAPI from "services/rest/Assessment";
// import fileDownload from "js-file-download";
// import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';

// Permission decoding
export const hasPermissionFor = (action, subject) => {
  const permissionsObj = JSON.parse(
    localStorage.getItem("rolesAndPermissions")
  );
  if (permissionsObj) {
    const {
      rolesAndPermissions: { permissions },
    } = permissionsObj;
    if (
      permissions &&
      permissions.length > 0 &&
      permissions[0].subject === "*" &&
      permissions[0].action === "*"
    ) {
      return true;
    }
    const filteredPermission = permissions.filter(
      (permission) =>
        permission.action === action && permission.subject === subject
    );
    const havePermission = filteredPermission.length > 0;
    return havePermission;
  }
  return {};
};

// JSON DECODING
let b64DecodeUnicode = (str) =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        window.atob(str),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );

let parseJwt = (token) =>
  JSON.parse(
    b64DecodeUnicode(token.split(".")[1].replace("-", "+").replace("_", "/"))
  );

export const getJSONDecoded = (form) => {
  if (form !== null) {
    const value = parseJwt(form);
    return value;
  }
  return {};
};

// GET COMPLETE ROUND-OFF INTEGER VALUE

export const getCompleteIntegerValue = (decimalNumber) => {
  const roundOffValue = Math.round(decimalNumber);
  const intRoundOffValue = parseInt(roundOffValue, 10);
  return intRoundOffValue;
};

// GET PERCENATGE

export const getPercentageValue = (percentageOf, percentageFrom) => {
  if (percentageFrom) {
    const percentageValue = (percentageOf / percentageFrom) * 100;
    const intRoundOffValue = getCompleteIntegerValue(percentageValue);
    return intRoundOffValue;
  }
  return 0;
};

// ERROR HANDLER

export const getErrorMessage = (errorInfo) => {
  const { graphQLErrors } = errorInfo;
  let finalErrorMessage = "Oops !! Something went wrong";
  if (graphQLErrors.length > 0) {
    finalErrorMessage = graphQLErrors[0].message;
  } else if (
    errorInfo &&
    errorInfo.response &&
    errorInfo.response.data &&
    errorInfo.response.data.error
  ) {
    finalErrorMessage = errorInfo.response.data.error;
  }
  /* eslint-disable no-debugger, no-console */
  console.error("ERROR:", finalErrorMessage);
  /* eslint-enable no-alert, no-console */
  return finalErrorMessage;
};

export const nameRegEx = /^([a-zA-Z ])+$/;

export const emailRegEx =
  /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const youtubeUrlRegEx =
  /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/;

export const numericRegEx = /^[0-9]+$/;

export const phoneNumberRegEx =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const candidateUrlRegEx = [
  /^\/assessment\/\d+/,
  /^\/tests/,
  /^\/assessment\/login/,
  /^\/assessment\/create\-account/,
];

export const youtubeUrlParser =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

export const websiteUrlRegEx =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const linkedinUrlRegEx =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))/;

export const testWithRegEx = (RegEx, value) => {
  return RegEx.test(value);
};

export const testForCandidateURLRegEx = (RegEx, value) => {
  let isCandidateURL = false;
  for (let i = 0; i < candidateUrlRegEx.length; i = i + 1) {
    const testResult = candidateUrlRegEx[i].test(window.location.pathname);
    if (testResult) {
      isCandidateURL = true;
    }
  }
  return isCandidateURL;
};

export const testForSurveyURLRegEx = () => {
  const testResult = /^\/survey\//.test(window.location.pathname);
  return !!testResult;
};

export const textTypes = ["phone_number", "email", "open_text", "long_text"];

/* excluded functional areas */
export const otherFA = [
  "Production",
  "Research and Development",
  "Purchasing",
  "Senior Leadership",
  "Data Science",
  "Administration",
];

const momentizer = (numberDateVal) => {
  const stringDateVal = String(moment(numberDateVal).format("YYYY-MM-DD"));
  const arrayedDateVal = stringDateVal.split("-");
  const keysInNumber = arrayedDateVal.map((dateKey) => parseInt(dateKey, 10));
  const momentizedNumberVal = moment(keysInNumber);
  return momentizedNumberVal;
};

export const dateFormatter = (dateValue) => {
  let finalDate = "N/A";
  const todaysDate = new Date();
  const todaysFormattedDate = momentizer(todaysDate);
  if (dateValue && dateValue.length > 0) {
    const numberDateVal = Number(dateValue);
    const stringDateVal = momentizer(numberDateVal);
    const dateDifference = todaysFormattedDate.diff(stringDateVal, "days");
    if (dateDifference < 7) {
      finalDate = `${dateDifference} days ago`;
    } else {
      finalDate = moment(numberDateVal).format("DD / MM / YY");
    }
  }
  return finalDate;
};

export const hideActionFun = ({ isBundle, isBadge, hasAudioVideoQuesOnly }) => {
  return parseInt(
    Number(isBundle).toString() +
      Number(isBadge).toString() +
      Number(hasAudioVideoQuesOnly).toString(),
    2
  );
};

export const getTokenInfo = () => {
  const tokenStrCode = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(tokenStrCode);
  return tokenInfo;
};

export const isSuperAdmin = getTokenInfo().roleTemplateId === 1;
export const isAdmin = getTokenInfo().roleTemplateId === 2;
export const isCollaborator = getTokenInfo().roleTemplateId === 3;
export const isViewer =
  getTokenInfo().roleTemplateId === 6 || getTokenInfo().roleTemplateId === 7;

export const getTitleCase = (str) =>
  str
    .split("_")
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(" ");

export const kFormatter = (number) => {
  return Math.abs(number) > 999
    ? Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + "k"
    : Math.sign(number) * Math.abs(number);
};

export const suffixNumber = (number) => {
  const lastDigit = number % 10;
  const lastTwoDigit = number % 100;

  if (lastDigit === 1 && lastTwoDigit !== 11) {
    return `${number}st`;
  }

  if (lastDigit === 2 && lastTwoDigit !== 12) {
    return `${number}nd`;
  }

  if (lastDigit === 3 && lastTwoDigit !== 13) {
    return `${number}rd`;
  }

  return `${number}th`;
};

export const coBrandingDefaultColor = "#0094FF";

export const modifyEmployeerTheme = (themeObject, jobDetails) => {
  if (jobDetails?.company?.color) {
    themeObject.palette.primary.main = jobDetails.company.color;
    themeObject.palette.primary.dark = darken(jobDetails.company.color, 0.2);
    themeObject.overrides.MuiCheckbox.colorPrimary[
      "&.Mui-focusVisible"
    ].backgroundColor = lighten(jobDetails.company.color, 0.9);
    // Updating the focus-visible color to brand color
    themeObject.overrides.MuiCssBaseline["@global"][
      ":focus-visible"
    ].outlineColor = lighten(jobDetails.company.color, 0.9);
    // Updating the input hover color to brand color
    themeObject.overrides.MuiOutlinedInput.root[
      "&:hover:not($error) $notchedOutline"
    ].borderColor = lighten(jobDetails.company.color, 0.9);
    themeObject.overrides.MuiButton.containedPrimary[
      "&.Mui-focusVisible"
    ].backgroundColor = darken(jobDetails.company.color, 0.2);
  }
  return { ...themeObject };
};

// export const getEditorContentState = text => {
//   const textContentHtml = convertFromHTML(text);
//   const textState = ContentState.createFromBlockArray(
//     textContentHtml.contentBlocks,
//     textContentHtml.entityMap
//   );
//   const contentState = JSON.stringify(convertToRaw(textState));
//   return contentState;
// };

export const getFormattedFullNameAndIntials = (
  firstName = "",
  lastName = ""
) => {
  const formattedFirstName =
    firstName?.slice(0, 1)?.toUpperCase() + firstName?.slice(1);
  const formattedLastName =
    lastName?.slice(0, 1)?.toUpperCase() + lastName?.slice(1);

  return {
    fullName: formattedLastName
      ? formattedFirstName + " " + formattedLastName
      : formattedFirstName,
    firstNameInitial: formattedFirstName?.[0],
    lastNameInitial: formattedLastName?.[0],
    fullNameInitials: formattedLastName?.[0]
      ? formattedFirstName?.[0] + formattedLastName?.[0]
      : formattedFirstName?.[0],
  };
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export async function downloadCSV(url, fileName = "CSV_file.xlsx") {
  try {
    // const response = await AssessmentAPI.getResponseInBlob(url);
    // const responseFileName = response?.headers?.get("x-filename");
    // fileDownload(response.data, responseFileName ?? fileName);
    // return response;
  } catch (error) {
    throw error;
  }
}

export const getFormattedTime = (timeInSeconds) => {
  const duration = moment.duration(timeInSeconds, "seconds");
  let totalHours = Math.floor(duration.asHours());
  let hour = totalHours ? `${totalHours}h` : "";
  let minute = duration.minutes() ? `${duration.minutes()}m` : "";
  let seconds = duration.seconds() ? `${duration.seconds()}s` : "";
  let formattedtime = "";

  if (hour) {
    formattedtime = formattedtime + hour + " ";
  }

  if (minute) {
    formattedtime = formattedtime + minute + " ";
  }

  if (seconds) {
    formattedtime = formattedtime + seconds;
  }

  return formattedtime;
};

export const getNewFileName = (file) => {
  const lastDotIndex = file.name.lastIndexOf(".");
  const originalFileName = file.name
    .slice(0, lastDotIndex)
    .trim()
    .replace(/[^\w\s]/gi, "_"); // converting all the special character (" ", "(", ")") to "_"
  const fileExtension = file.name.slice(lastDotIndex + 1);
  const newFile = new File(
    [file],
    `${originalFileName}_${new Date().getTime()}.${fileExtension}`,
    { type: file.type }
  );

  return newFile;
};

// export const getKey = (searchKeyName, object) => {
//   let result = "";
//   const searchKey = (object, parent) => {
//     for (let k in object) {
//       if (searchKeyName === k) {
//         result = parent + "." + k;
//         return result;
//       } else if (Array.isArray(object[k])) {
//         for (let [index, value] of Object.entries(object[k])) {
//           if (typeof value === "object" && value !== null) {
//             searchKey(value, `${parent}[${index}]`);
//           }
//         }
//       } else if (
//         object[k] instanceof Object &&
//         object[k]?.constructor === Object
//       ) {
//         searchKey(object[k], `${parent}.${k}`);
//       }
//     }
//     return result;
//   };

//   return searchKey(object, "");
// };

export const getKey = (searchKeyName, object) => {
  let result = "";
  const searchKey = (object, parent) => {
    for (let k in object) {
      if (searchKeyName === k) {
        result = parent + "." + k;
        return result;
      } else if (Array.isArray(object[k])) {
        for (let [index, value] of Object.entries(object[k])) {
          if (typeof value === "object" && value !== null) {
            const result = searchKey(value, `${parent}[${index}]`);
            if (result) {
              return result;
            }
          }
        }
      } else if (
        object[k] instanceof Object &&
        object[k]?.constructor === Object
      ) {
        searchKey(object[k], `${parent}.${k}`);
      }
    }
    return result;
  };

  return searchKey(object, "");
};

export const getAudioDuration = (file) => {
  return new Promise((resolve) => {
    const fileURL = URL.createObjectURL(file);
    const audio = new Audio(fileURL);

    audio.onloadedmetadata = () => {
      URL.revokeObjectURL(fileURL);
      audio.remove();
      resolve(Math.ceil(audio.duration));
    };
  });
};

export const checkTokenExpiry = (token) => {
  if (token) {
    const decodedToken = getJSONDecoded(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      return true;
    } else {
      return false;
    }
  }
};

export const wait = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getTimeVariations = () => {
  const times = [];

  for (let i = 0; i < 24; i = i + 1) {
    const isAM = i < 12;
    const amPM = isAM ? "am" : "pm";
    const hoursIn12HoursFormat = i > 12 ? i - 12 : i;
    const hours = i === 0 ? 12 : hoursIn12HoursFormat;
    for (let j = 0; j < 60; j = j + 60) {
      const minutes = j < 10 ? `0${j}` : `${j}`;
      const time = `${hours}:${minutes} ${amPM}`;
      const value = `${i < 10 ? "0" + i : i}:${minutes}`;
      const id = time.length;
      times.push({ id, time, value });
    }
  }

  return times;
};
