import { Box, Popper } from "@mui/material";
import React, { cloneElement, useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import { getAllVariables } from "services";
import styles from "./index.module.css";

const VariableDropdown = ({
  value,
  onChange,
  control,
  watch,
  setValue,
  children,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [variables, setVariables] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const textFieldRef = useRef(null);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);

    const cursorPos = event.target.selectionStart;

    const textBeforeCursor = newValue.slice(0, cursorPos);
    const isCurlyBracesOpen =
      textBeforeCursor.lastIndexOf("{{") > textBeforeCursor.lastIndexOf("}}");

    setShowDropdown(isCurlyBracesOpen);
    setAnchorEl(event.target);

    if (isCurlyBracesOpen) {
      const searchTerm = textBeforeCursor.slice(
        textBeforeCursor.lastIndexOf("{{") + 2
      );
      setSearchQuery(searchTerm);
    }
  };

  const handleVariableClick = (variable) => {
    const cursorPosition = textFieldRef.current.selectionStart;
    const textBeforeCursor = value.slice(0, cursorPosition);
    const textAfterCursor = value.slice(cursorPosition);

    const openingBracketIndex = textBeforeCursor.lastIndexOf("{{");
    const newTextBeforeCursor = textBeforeCursor.slice(0, openingBracketIndex);

    const newMessage =
      newTextBeforeCursor + "{{" + variable + "}}" + textAfterCursor;

    // setValue("message", newMessage);
    setShowDropdown(false);
    textFieldRef.current.focus();
    const newCursorPosition = newTextBeforeCursor.length + variable.length + 4;
    textFieldRef.current.setSelectionRange(
      newCursorPosition,
      newCursorPosition
    );
    onChange(newMessage);
  };

  const getVariables = async () => {
    try {
      const params = new URLSearchParams();
      params.set("search", searchQuery);
      const response = await getAllVariables(params);
      setVariables(response);
    } catch (error) {
      console.error("Error fetching variables", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    if (showDropdown) {
      getVariables();
    }
  }, [searchQuery, showDropdown]);

  const clonedChild = cloneElement(children, {
    value,
    onChange: handleInputChange,
    inputRef: (ref) => {
      ref && (textFieldRef.current = ref);
    },
  });

  return (
    <>
      {clonedChild}
      <Popper open={showDropdown} anchorEl={anchorEl} placement="bottom-start">
        <Box ref={dropdownRef} className={styles.container}>
          {variables.map((variable) => (
            <div
              className={styles.item}
              key={variable.variable}
              onClick={() => handleVariableClick(variable.variable)}
              style={{ cursor: "pointer" }}
            >
              {variable.variable}
            </div>
          ))}
        </Box>
      </Popper>
    </>
  );
};

export default VariableDropdown;
