import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grow,
  IconButton,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import FlexBox from "components/FlexBox";
import EmptyState from "components/EmptyState";
import FundamentoTable from "components/FundamentoTable";
import { useCallback, useEffect, useState } from "react";
import { ChevronDown, Loader, X } from "react-feather";
import {
  getCalls,
  getAgentCallsCountOverview as getAgentCallsCountOverviewApi,
  getMarkedByDropdown as getMarkedByDropdownAPI,
} from "services";
import { useURLState } from "custom-hooks/useUrlState";
import FundamentoPopup from "components/FundamentoPopup";
import { useSkillrToast } from "context/toast";
import {
  Info,
  SortAscending,
  ThumbDownRedFilled,
  ThumbUpGreenFilled,
} from "components/newSVG";
import theme from "theme";
import { getFormattedTime, getJSONDecoded } from "utils/utils";
import moment, { duration } from "moment";
import _, { debounce } from "lodash";
import { useUserContext } from "context/user";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { statusFilter as statusFilterList } from "text-constants/audit";
import getParentCategoriesApi from "services/getParentCategories";
import AutoComplete from "components/AutoComplete";
import { Sort } from "@mui/icons-material";

const debouncedGetCallData = debounce((callback) => {
  callback();
}, 500);

const Audit = (props) => {
  const {
    search: headerSearch,
    date: headerDate,
    setDate: setHeaderDate,
  } = useOutletContext();
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [callsData, setCallsData] = useState([]);
  const [totalCallsCount, setTotalCallsCount] = useState(0);
  const [validCallsCount, setValidCallsCount] = useState(0);
  const [blankCallsCount, setBlankCallsCount] = useState(0);
  const [junkCallsCount, setJunkCallsCount] = useState(0);
  const [currentCall, setCurrentCall] = useState({});
  const { showSkillrToast } = useSkillrToast();
  const [loading, setLoading] = useState(false);
  const [callTypeTab, setCallTypeTab] = useURLState("All", "callType");
  const [feedback1Filter, setFeedback1Filter] = useURLState([], "feedback1");
  const [feedback2Filter, setFeedback2Filter] = useURLState([], "feedback2");
  const [callTypeFilter, setCallTypeFilter] = useURLState([], "callTypeFilter");
  const [callsCountInfo, setCallsCountInfo] = useState();
  const feedbackList = ["satisfied", "dissatisfied", "unrated"];
  const [paginationCount, setPaginationCount] = useState();
  const [startDateForUrl, setStartDateForUrl] = useURLState("", "startDate");
  const [endDateForUrl, setEndDateForUrl] = useURLState("", "endDate");
  const [statusFilter, setStatusFilter] = useURLState([], "status");
  const [startDateFilter, setStartDateFilter] = useState(() =>
    startDateForUrl
      ? { date: moment(startDateForUrl) }
      : { date: moment().startOf("month") }
  );
  const [endDateFilter, setEndDateFilter] = useState(() =>
    endDateForUrl ? { date: moment(endDateForUrl) } : { date: moment() }
  );
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const { token, buildMode } = useUserContext();
  const tokenInfo = getJSONDecoded(token ?? null);
  // const [showMarkedByPopup, setShowMarkedByPopup] = useState(false);
  const [showMarkedByPopup, setShowMarkedByPopup] = useState({});
  const [markedByDropdownData, setMarkedByDropdownData] = useState([]);
  const [selectedMarkedByID, setSelectedMarkedByID] = useURLState(
    "",
    "markedBy"
  );
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [parentCategoryFilter, setParentCategoryFilter] = useURLState(
    [],
    "parentCategory"
  );
  const [childCategoryFilter, setChildCategoryFilter] = useURLState(
    [],
    "subCategory"
  );
  const [minDurationFilter, setMinDurationFilter] = useURLState(
    "",
    "minDuration"
  );
  const [maxDurationFilter, setMaxDurationFilter] = useURLState(
    "",
    "maxDuration"
  );
  const navigate = useNavigate();
  const autoCompleteFilter = createFilterOptions();
  const [sortOrder, setSortOrder] = useURLState("", "sorting");
  const [maxDurationOfCalls, setMaxDurationOfCalls] = useState(0);

  const columns = [
    {
      field: "callId",
      header: "Call ID",
      render: (row) => {
        return row.voiceCallId;
      },
    },
    {
      field: "phoneNumber",
      header: "Phone Number",
    },
    {
      field: "timestamp",
      header: "Date & Time",
      render: (row) => {
        const date = new Date(row.timestamp);
        const day = date.toLocaleString("en-US", { day: "2-digit" });
        const month = date.toLocaleString("en-US", { month: "short" });
        const time = date.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
        return `${day} ${month}, ${time}`;
      },
      // style: { width: "10%" },
    },
    {
      field: "callType",
      header: "Call Type",
      render: (rowInfo) => {
        if (rowInfo?.callType === "agentHandOff") {
          return "Agent Handoff";
        } else if (rowInfo?.callType === "blank") {
          return "Blank Call";
        } else if (rowInfo?.callType === "virtualAgent") {
          return "Virtual Agent";
        }
      },
    },
    {
      field: "feedback1",
      header: (
        <Tooltip title={"Is there an intent match?"} placement="top">
          <Box display="flex" alignItems={"center"} columnGap={1}>
            <Typography variant="caption">Feedback 1</Typography>
            <Info width={16} height={16} />
          </Box>
        </Tooltip>
      ),
      render: (rowInfo) => {
        return (
          <Box display="flex" alignItems="center" columnGap={2}>
            <Typography variant="body2" verticalAlign="start">
              {rowInfo.isIntentMatchedUserResponse === true
                ? "Satisfied"
                : rowInfo.isIntentMatchedUserResponse === false
                ? "Dissatisfied"
                : "Unrated"}
            </Typography>
            {rowInfo.isIntentMatchedUserResponse}
            {rowInfo.isIntentMatchedUserResponse === true && (
              <ThumbUpGreenFilled width={16} height={16} />
            )}
            {rowInfo.isIntentMatchedUserResponse === false && (
              <ThumbDownRedFilled width={16} height={16} />
            )}
          </Box>
        );
      },
    },
    {
      field: "feedback2",
      header: (
        <Tooltip title={"Is the answer correct?"} placement="top">
          <Box display="flex" alignItems={"center"} columnGap={1}>
            <Typography variant="caption">Feedback 2</Typography>
            <Info width={16} height={16} />
          </Box>
        </Tooltip>
      ),
      render: (rowInfo) => {
        return (
          <Box display="flex" alignItems="center" columnGap={2}>
            <Typography variant="body2">
              {rowInfo.isAnswerCorrect === true
                ? "Satisfied"
                : rowInfo.isAnswerCorrect === false
                ? "Dissatisfied"
                : "Unrated"}
            </Typography>
            {rowInfo.isAnswerCorrect === true && (
              <ThumbUpGreenFilled width={16} height={16} />
            )}
            {rowInfo.isAnswerCorrect === false && (
              <ThumbDownRedFilled width={16} height={16} />
            )}
          </Box>
        );
      },
    },
    {
      field: "category",
      header: "Category",
      render: (rowInfo) => {
        return rowInfo?.parentCategoryName;
      },
    },
    {
      field: "subCategory",
      header: "Sub Category",
      render: (rowInfo) => {
        return rowInfo?.subCategoryName;
      },
    },

    {
      field: "duration",
      header: "Duration (in seconds)",
      render: (rowInfo) => {
        return `${Math.ceil(isNaN(rowInfo?.duration) ? 0 : rowInfo?.duration)}`;
      },
    },
    {
      field: "status",
      header: "Status",
      render: (rowInfo) => {
        if (rowInfo?.auditedCallTypeSub) {
          return rowInfo?.auditedCallTypeSub;
        } else if (rowInfo?.auditedCallType) {
          return rowInfo?.auditedCallType;
        } else {
          return "Pending";
        }
      },
    },
  ];

  const getCallsCount = async () => {
    try {
      setLoading(true);
      const urlParams = new URLSearchParams();
      /**
       * isOnPhoneCall
       * For development build we will show all calls made from playground
       * and for production build we will show all calls made from actual phone number
       */
      urlParams.set("isOnPhoneCall", buildMode === "production" ? true : null);
      if (headerDate?.startDate) {
        const modifiedStartDate = headerDate.startDate;
        const modifiedEndDate = headerDate.endDate
          .clone()
          .add(1, "day")
          .startOf("day");
        urlParams.set("startDate", modifiedStartDate.toISOString());
        urlParams.set("endDate", modifiedEndDate.toISOString());
      }
      const response = await getAgentCallsCountOverviewApi(
        urlParams.toString()
      );
      setCallsCountInfo(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCallData = async () => {
    setLoading(true);
    try {
      const urlParams = new URLSearchParams();
      urlParams.set("page", currentPage);
      urlParams.set("feedback1", feedback1Filter);
      urlParams.set("feedback2", feedback2Filter);
      // urlParams.set("search", searchBoxValue);
      urlParams.set("search", headerSearch);
      urlParams.set("status", statusFilter);
      urlParams.set("parentCategory", parentCategoryFilter);
      urlParams.set("subCategory", childCategoryFilter);
      urlParams.set("minDuration", minDurationFilter);
      urlParams.set("maxDuration", maxDurationFilter);
      urlParams.set("sorting", sortOrder);
      /**
       * isOnPhoneCall
       * For development build we will show all calls made from playground
       * and for production build we will show all calls made from actual phone number
       */
      urlParams.set("isOnPhoneCall", buildMode === "production" ? true : null);
      if (selectedMarkedByID) urlParams.set("markedBy", selectedMarkedByID);
      if (callTypeTab === "All") {
        urlParams.delete("callType");
      } else {
        urlParams.set("callType", callTypeTab);
      }
      if (headerDate?.startDate) {
        const modifiedStartDate = headerDate.startDate;
        const modifiedEndDate = headerDate.endDate
          .clone()
          .add(1, "day")
          .startOf("day");
        urlParams.set("startDate", modifiedStartDate.toISOString());
        urlParams.set("endDate", modifiedEndDate.toISOString());
      }
      const response = await getCalls(urlParams.toString());
      if (response?.calls) {
        setLoading(false);
        updateTableData(response?.calls);
        setTotalCallsCount(response?.totalCallsCount);
        setValidCallsCount(response?.validCount);
        setBlankCallsCount(response?.blankCallCount);
        setJunkCallsCount(response?.junkCount);
        setPaginationCount(() => {
          if (callTypeTab === "All") {
            return Math.ceil(response?.totalCallsCount / 10);
          } else if (callTypeTab === "Valid") {
            return Math.ceil(response?.validCount / 10);
          } else if (callTypeTab === "Blank") {
            return Math.ceil(response?.blankCallCount / 10);
          } else if (callTypeTab === "Junk") {
            return Math.ceil(response?.junkCount / 10);
          }
        });
        setMaxDurationOfCalls(Math.ceil(response?.maxDuration));
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getMarkedByDropdown = async () => {
    try {
      const response = await getMarkedByDropdownAPI();
      if (response) {
        setMarkedByDropdownData(response);
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const updateTableData = (callsData) => {
    const newData = callsData?.map((item, index) => {
      return {
        id: index,
        // this is the index we've created to track the row from callsData for onClick
        _id: item._id,
        // this is the id from the backend
        callId: item.callId,
        question: item.startLine,
        answer: item.endLine,
        agentEmail: item.agent ? item.agent.email : "N/A",
        agentName: item?.agent
          ? item.agent?.firstName + item.agent?.lastName
          : "N/A",
        audio: item.gcsUri,
        timestamp: item.startTime,
        callType: item.callType,
        reaction: item?.endLineReaction,
        reasonForDislike: item?.thumbsDownReason,
        logs: item?.logs,
        thumbsDownReason: item?.thumbsDownReason,
        weaviateQuestionPicked: item?.weaviateQuestionPicked,
        totalResponseTime: item?.totalResponseTimeInMilliSeconds,
        phoneNumber: item?.phoneNumber,
        vectorDbLog: item?.logs?.find((log) => log.processId === 5),
        gptLog: item?.logs?.find((log) => log.processId === 4),
        audited: item?.audited,
        auditUserObjects: item?.auditUserObjects,
        auditedCallType: item?.auditedCallType,
        auditedCallTypeSub: item?.auditedCallTypeSub,
        auditTime: item?.auditTime,
        voiceCallId: item?.voiceCallId,
        intentMatchedUserResponseExplanation:
          item?.intentMatchedUserResponseExplanation,
        isIntentMatchedUserResponse: item?.isIntentMatchedUserResponse,
        isAnswerCorrect: item?.isAnswerCorrect,
        intentCategory: item?.intentCategory,
        status: item?.status,
        answerCorrectnessExplanation: item?.answerCorrectnessExplanation,
        parentCategoryName: item?.callSessionData?.parentCategoryName,
        subCategoryName: item?.callSessionData?.subCategoryName,
        duration: item?.audioCallDurationInSeconds,
      };
    });
    setCallsData(newData);
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row) {
      const singleCallDetails = callsData.find((call) => call._id === row.id);

      navigate(
        `/calls/${singleCallDetails._id}?voiceCallId=${singleCallDetails?.voiceCallId}`
      );

      setHeaderDate({ ...headerDate, changed: "default" });

      // setModal(true);
      // setCurrentCall(singleCallDetails);
    }
  };

  const handleSelectFeedback = (e, feedback) => {
    setCurrentPage(1);

    const selectedFileType = e.target.value;
    if (feedback === "feedback1") {
      const newFeedbackFilter = [...feedback1Filter];
      const index = feedback1Filter.indexOf(selectedFileType);
      if (index > -1) {
        newFeedbackFilter.splice(index, 1);
      } else {
        newFeedbackFilter.push(selectedFileType);
      }
      setFeedback1Filter(newFeedbackFilter);
    } else {
      const newFeedbackFilter = [...feedback2Filter];
      const index = feedback2Filter.indexOf(selectedFileType);
      if (index > -1) {
        newFeedbackFilter.splice(index, 1);
      } else {
        newFeedbackFilter.push(selectedFileType);
      }
      setFeedback2Filter(newFeedbackFilter);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentPage(1);
    setCallTypeTab(newValue);
  };

  const handleFilter = (e, filter, setFilter) => {
    const selectedFilter = e.target.value;
    let newFilter;
    if (Array.isArray(filter)) {
      newFilter = [...filter];
      const index = filter.indexOf(selectedFilter);
      if (index > -1) {
        newFilter.splice(index, 1);
      } else {
        newFilter.push(selectedFilter);
      }
    } else {
      newFilter = selectedFilter === filter ? "" : selectedFilter;
    }
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (headerDate.changed === "manual") {
      setHeaderDate({ ...headerDate, changed: "default" });
    }
  };

  const updateChildCategory = (event, _parentCategories) => {
    let allChildCategories = [];
    const parentCategoryName = event?.target?.value;
    const selectedParentCategory = [...parentCategoryFilter];
    let childCategoriesToRemove = [];
    const newParentCategoryIndex =
      selectedParentCategory.indexOf(parentCategoryName);
    if (parentCategoryName) {
      if (newParentCategoryIndex > -1) {
        childCategoriesToRemove = _parentCategories.find(
          (pc) => pc.name === parentCategoryName
        )?.categories;
        selectedParentCategory.splice(newParentCategoryIndex, 1);
      } else {
        selectedParentCategory.push(parentCategoryName);
      }
    }

    if (selectedParentCategory.length > 0) {
      _parentCategories.forEach((pc) => {
        if (selectedParentCategory.includes(pc.name)) {
          allChildCategories = [...allChildCategories, ...pc.categories];
        }
      });
    } else {
      _parentCategories.forEach((pc) => {
        allChildCategories = [...allChildCategories, ...pc.categories];
      });
    }
    setChildCategories(allChildCategories);

    // Remove the child categories if the parent category is removed from filter
    if (childCategoriesToRemove.length > 0) {
      const newChildCategory = [...childCategoryFilter];
      childCategoriesToRemove.forEach((cc) => {
        const ccIndex = newChildCategory.indexOf(cc.name);
        if (ccIndex > -1) {
          newChildCategory.splice(ccIndex, 1);
        }
      });
      setChildCategoryFilter(newChildCategory);
    }
  };

  const getParentCategories = async () => {
    try {
      const allParentCategories = await getParentCategoriesApi();
      setParentCategories(allParentCategories);
      // updateChildCategory({}, allParentCategories);
      let allChildCategories = [];
      if (parentCategoryFilter.length > 0) {
        allParentCategories.forEach((pc) => {
          if (parentCategoryFilter.includes(pc.name)) {
            allChildCategories = [...allChildCategories, ...pc.categories];
          }
        });
      } else {
        allParentCategories.forEach((pc) => {
          allChildCategories = [...allChildCategories, ...pc.categories];
        });
      }
      setChildCategories(allChildCategories);
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const isFilterApplied = () => {
    if (
      feedback1Filter.length > 0 ||
      feedback2Filter.length > 0 ||
      parentCategoryFilter.length > 0 ||
      childCategoryFilter.length > 0 ||
      statusFilter.length > 0 ||
      minDurationFilter > 0 ||
      maxDurationFilter > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSliderChange = (event, newValue) => {
    setCurrentPage(1);
    setMinDurationFilter(newValue[0]);
    setMaxDurationFilter(newValue[1]);
  };

  const clearAllFilters = () => {
    setFeedback1Filter([]);
    setFeedback2Filter([]);
    setParentCategoryFilter([]);
    setChildCategoryFilter([]);
    setStatusFilter([]);
    setMinDurationFilter("");
    setMaxDurationFilter("");
  };

  useEffect(() => {
    getParentCategories();
  }, []);

  useEffect(() => {
    getCallsCount();
    getMarkedByDropdown();
  }, [headerDate.startDate, headerDate.endDate]);

  useEffect(() => {
    debouncedGetCallData(() => {
      getCallData();
    });
  }, [minDurationFilter, maxDurationFilter]);

  useEffect(() => {
    getCallData();
  }, [
    currentPage,
    callTypeTab,
    feedback1Filter,
    feedback2Filter,
    statusFilter,
    // searchBoxValue,
    // headerSearch,
    headerDate.startDate,
    headerDate.endDate,
    selectedMarkedByID,
    parentCategoryFilter,
    childCategoryFilter,
    sortOrder,
  ]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (headerSearch && paginationCount) {
      if (currentPage === 1) {
        getCallData();
      } else {
        setCurrentPage(1);
      }
    }

    if (
      urlSearchParams.has("search") &&
      urlSearchParams.get("search") === "" &&
      paginationCount !== undefined // checked for pagination count because it should not work on first load, once search then only it should
    ) {
      getCallData();
    }

    if (headerDate.changed === "manual") {
      setCurrentPage(1);
    }
  }, [headerSearch, headerDate.changed]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        padding: "16px 48px",
      }}
    >
      <FlexBox>
        <FlexBox className={styles.stats} sx={{ flex: 1 }} columnGap={0}>
          <Box className={styles.box}>
            <Typography variant="body1">Total Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalCallsCount || 0}
            </Typography>
          </Box>

          <Box className={styles.box}>
            <Typography variant="body1">Valid Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalValidCallsCount || 0}
            </Typography>
          </Box>
          <Box className={styles.box}>
            <Typography variant="body1">Blank Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalBlankCallsCount || 0}
            </Typography>
          </Box>
          {/* <Box className={styles.box}>
            <Typography variant="body1">Junk Calls</Typography>
            <Typography variant="h5" fontWeight={500}>
              {callsCountInfo?.totalJunkCallsCount || 0}
            </Typography>
          </Box> */}
        </FlexBox>
        <FlexBox className={styles.stats} columnGap={0} minWidth={"25%"}>
          <Box className={styles.box}>
            <Typography variant="body1">Total Minutes</Typography>
            <Typography variant="h5" fontWeight={500}>
              {getFormattedTime(callsCountInfo?.totalBillDurationInSeconds) ||
                0}
            </Typography>
          </Box>
        </FlexBox>
      </FlexBox>
      <Box className={styles.container}>
        <Box className={styles.tabContainer}>
          <Tabs
            value={callTypeTab}
            onChange={handleTabChange}
            aria-label="call tabs"
            className={styles.tabs}
            TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
            sx={{
              "& span.MuiTabs-indicator": {
                borderRadius: "10px 10px 0 0",
                height: 3,
              },
            }}
          >
            <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  All calls {totalCallsCount}
                </Typography>
              }
              value="All"
            />
            <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  Valid {validCallsCount}
                </Typography>
              }
              value="Valid"
            />
            <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  Blank {blankCallsCount}
                </Typography>
              }
              value="Blank"
            />
            {/* <Tab
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="body2"
                  fontWeight={500}
                >
                  Junk {junkCallsCount}
                </Typography>
              }
              value="Junk"
            /> */}
          </Tabs>
        </Box>

        <FlexBox mt={4} mb={2}>
          {/* feedback1 */}
          <Box className={styles.filters}>
            <FundamentoPopup
              disableCloseOnClick={true}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={feedback1Filter.length > 0 && styles.active}
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Feedback 1
                </Button>
              }
            >
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={feedbackList}
                getOptionLabel={(option) => option}
                value={feedback1Filter}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} id={option}>
                      <Checkbox
                        value={option}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <Typography variant="body2">{option}</Typography>
                    </li>
                  );
                }}
                onChange={(event, value, reason) => {
                  const parent = event.target.closest(["li"]);
                  if (
                    (reason === "selectOption" || reason === "removeOption") &&
                    parent?.id
                  ) {
                    const dataObj = { target: { value: parent.id } };
                    handleSelectFeedback(dataObj, "feedback1");
                  }

                  if (reason === "clear") {
                    setFeedback1Filter([]);
                  }
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      onDelete={() =>
                        handleSelectFeedback(
                          { target: { value: option } },
                          "feedback1"
                        )
                      }
                    />
                  ))
                }
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search" />
                )}
              />
            </FundamentoPopup>
          </Box>

          {/* feedback2 */}
          <Box className={styles.filters}>
            <FundamentoPopup
              disableCloseOnClick={true}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={feedback2Filter.length > 0 && styles.active}
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Feedback 2
                </Button>
              }
            >
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={feedbackList}
                getOptionLabel={(option) => option}
                value={feedback2Filter}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} id={option}>
                      <Checkbox
                        value={option}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <Typography variant="body2">{option}</Typography>
                    </li>
                  );
                }}
                onChange={(event, value, reason) => {
                  const parent = event.target.closest(["li"]);
                  if (
                    (reason === "selectOption" || reason === "removeOption") &&
                    parent?.id
                  ) {
                    const dataObj = { target: { value: parent.id } };
                    handleSelectFeedback(dataObj, "feedback2");
                  }

                  if (reason === "clear") {
                    setFeedback2Filter([]);
                  }
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      onDelete={() =>
                        handleSelectFeedback(
                          { target: { value: option } },
                          "feedback2"
                        )
                      }
                    />
                  ))
                }
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search" />
                )}
              />
            </FundamentoPopup>
          </Box>

          {/* marked by filter */}
          {/* {markedByDropdownData.length > 0 && (
            <Box className={styles.filters}>
              <FundamentoPopup
                disableCloseOnClick={true}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    className={selectedMarkedByID && styles.active}
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Marked By
                  </Button>
                }
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                  }}
                >
                  {markedByDropdownData.map((item) => (
                    <Box
                      key={item?._id}
                      columnGap={2}
                      display="flex"
                      alignItems="center"
                      component={"label"}
                      onClick={() =>
                        selectedMarkedByID === item?._id
                          ? setSelectedMarkedByID(null)
                          : setSelectedMarkedByID(item?._id)
                      }
                    >
                      <Checkbox checked={item?._id === selectedMarkedByID} />
                      <Typography variant="body2">{`${item?.firstName} ${item?.lastName}`}</Typography>
                    </Box>
                  ))}
                </Box>
              </FundamentoPopup>
            </Box>
          )} */}

          {/* category filter */}
          {parentCategories.length > 0 && (
            <Box className={styles.filters}>
              <FundamentoPopup
                disableCloseOnClick={true}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    className={parentCategoryFilter.length > 0 && styles.active}
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Category
                  </Button>
                }
              >
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={parentCategories}
                  getOptionLabel={(option) => option.name}
                  value={parentCategoryFilter}
                  isOptionEqualToValue={(option, valueId) =>
                    option?.name === valueId
                  }
                  onChange={(event, value, reason) => {
                    const parent = event.target.closest(["li"]);
                    if (
                      (reason === "selectOption" ||
                        reason === "removeOption") &&
                      parent?.id
                    ) {
                      const dataObj = { target: { value: parent.id } };
                      handleFilter(
                        dataObj,
                        parentCategoryFilter,
                        setParentCategoryFilter
                      );
                      updateChildCategory(dataObj, parentCategories);
                    }

                    if (reason === "clear") {
                      setParentCategoryFilter([]);
                    }
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props} id={option?.name}>
                        <Checkbox
                          value={option?.name}
                          style={{ marginRight: 8 }}
                          checked={
                            parentCategoryFilter.indexOf(option?.name) > -1
                          }
                        />
                        <Typography variant="body2">{option?.name}</Typography>
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            handleFilter(
                              { target: { value: option } },
                              parentCategoryFilter,
                              setParentCategoryFilter
                            );
                            updateChildCategory(
                              { target: { value: option } },
                              parentCategories
                            );
                          }}
                        />
                      );
                    })
                  }
                  style={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search" />
                  )}
                />
              </FundamentoPopup>
            </Box>
          )}

          {/* sub category filter */}
          {childCategories.length > 0 && (
            <Box className={styles.filters}>
              <FundamentoPopup
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                disableCloseOnClick={true}
                triggeringComponent={
                  <Button
                    variant="outlined"
                    className={childCategoryFilter.length > 0 && styles.active}
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Sub category
                  </Button>
                }
              >
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={childCategories}
                  getOptionLabel={(option) => option.name}
                  value={childCategoryFilter}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props} id={option?.name}>
                        <Checkbox
                          value={option?.name}
                          style={{ marginRight: 8 }}
                          checked={
                            childCategoryFilter.indexOf(option?.name) > -1
                          }
                        />
                        <Typography variant="body2">{option?.name}</Typography>
                      </li>
                    );
                  }}
                  onChange={(event, value, reason) => {
                    const parent = event.target.closest(["li"]);
                    if (
                      (reason === "selectOption" ||
                        reason === "removeOption") &&
                      parent?.id
                    ) {
                      const dataObj = { target: { value: parent.id } };
                      handleFilter(
                        dataObj,
                        childCategoryFilter,
                        setChildCategoryFilter
                      );
                    }

                    if (reason === "clear") {
                      setChildCategoryFilter([]);
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                          onDelete={() =>
                            handleFilter(
                              { target: { value: option } },
                              childCategoryFilter,
                              setChildCategoryFilter
                            )
                          }
                        />
                      );
                    })
                  }
                  style={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search" />
                  )}
                />
              </FundamentoPopup>
            </Box>
          )}

          {/* status filter */}
          <Box className={styles.filters}>
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              disableCloseOnClick={true}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={statusFilter.length > 0 && styles.active}
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Status
                </Button>
              }
            >
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={statusFilterList}
                getOptionLabel={(option) => option.value}
                value={statusFilter}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props} id={option?.value}>
                      <Checkbox
                        value={option?.value}
                        style={{ marginRight: 8 }}
                        checked={statusFilter.indexOf(option?.value) > -1}
                      />
                      <Typography variant="body2">{option?.value}</Typography>
                    </li>
                  );
                }}
                onChange={(event, value, reason) => {
                  const parent = event.target.closest(["li"]);
                  if (
                    (reason === "selectOption" || reason === "removeOption") &&
                    parent?.id
                  ) {
                    const dataObj = { target: { value: parent.id } };
                    handleFilter(dataObj, statusFilter, setStatusFilter);
                  }

                  if (reason === "clear") {
                    setStatusFilter([]);
                  }
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() =>
                          handleFilter(
                            { target: { value: option } },
                            statusFilter,
                            setStatusFilter
                          )
                        }
                      />
                    );
                  })
                }
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Search" />
                )}
              />
            </FundamentoPopup>
          </Box>

          {/* duration filter */}
          <Box className={styles.filters}>
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              disableCloseOnClick={true}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={
                    (minDurationFilter || maxDurationFilter) && styles.active
                  }
                  sx={{
                    borderRadius: 82,
                    padding: "8px 14px",
                    minWidth: 0,
                  }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Duration
                </Button>
              }
            >
              <FlexBox
                sx={{
                  // mt: 4,
                  borderRadius: 3,
                  border: `1px solid ${theme.palette.borderColor.light}`,
                  padding: "20px 18px",
                }}
              >
                <Typography
                  variant="body2"
                  fontFamily={"monospace"}
                  sx={{ marginLeft: 2 }}
                >
                  0s
                </Typography>
                <FlexBox minWidth={100}>
                  <Slider
                    valueLabelDisplay="auto"
                    value={[minDurationFilter, maxDurationFilter]}
                    onChange={(event, value) => {
                      handleSliderChange(event, value);
                    }}
                    marks={[
                      {
                        value: 0,
                      },
                      {
                        value: maxDurationOfCalls,
                      },
                    ]}
                    min={0}
                    max={maxDurationOfCalls}
                    sx={{
                      flex: "1 1 80%",
                      height: "2.5px",
                      "& .MuiSlider-rail": { backgroundColor: "#DADADA" },
                      "& .MuiSlider-track": {
                        backgroundColor: "#0F0F0F",
                        border: 0,
                      },
                      "& .MuiSlider-thumb": {
                        backgroundColor: "#0F0F0F",
                        width: 15,
                        height: 15,
                      },
                      marginBottom: 0,
                      "& .MuiSlider-valueLabel": {
                        backgroundColor: theme.palette.text.primary,
                        borderRadius: "12px",
                        padding: "8px 12px",
                      },
                    }}
                  />
                </FlexBox>
                <Typography
                  variant="body2"
                  fontFamily={"monospace"}
                  sx={{ marginLeft: 2 }}
                >
                  {maxDurationOfCalls}s
                </Typography>
              </FlexBox>
            </FundamentoPopup>
          </Box>

          {isFilterApplied() && (
            <Tooltip title="Clear all filters">
              <IconButton
                // sx={{ margin: "16px 0 8px" }}
                onClick={clearAllFilters}
              >
                <X width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className={styles.popup}
              triggeringComponent={
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<SortAscending />}
                  sx={{
                    padding: 3,
                    minWidth: 0,
                    width: "fit-content",
                  }}
                >
                  Sort
                </Button>
              }
            >
              <Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Duration-ASC")}
                >
                  <Typography variant="body2">Duration (min to max)</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Duration-DESC")}
                >
                  <Typography variant="body2">Duration (max to min)</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-ASC")}
                >
                  <Typography variant="body2">Date - Ascending</Typography>
                </Box>
                <Box
                  className={styles.popupItem}
                  onClick={() => setSortOrder("Date-DESC")}
                >
                  <Typography variant="body2">Date - Descending</Typography>
                </Box>
              </Box>
            </FundamentoPopup>
          </div>
        </FlexBox>

        {loading && callsData.length === 0 && (
          <Box className={styles.emptyContainer}>
            <Loader width={40} height={40} color={theme.palette.grey[400]} />
          </Box>
        )}

        {!loading && callsData.length > 0 && (
          <Box
            sx={{
              "& th": {
                backgroundColor: `${theme.palette.grey[50]} !important`,
              },
            }}
          >
            <FundamentoTable
              columns={columns}
              data={callsData}
              passRowToRender={true}
              pagination={true}
              currentPage={currentPage}
              onPageChange={handlePageOnChange}
              totalPageCount={paginationCount}
              tableOnClick={handleTableOnClick}
              className={styles.table}
              rowIdKey="_id"
            />
          </Box>
        )}

        {!loading &&
          callsData.length === 0 &&
          paginationCount !== undefined && (
            <EmptyState title="No calls found" />
          )}
      </Box>

      {/* <CallDetailsModal
        open={modal}
        onClose={() => setModal(false)}
        callDetails={currentCall}
        editAgentCall={editAgentCall}
        onAuditLogChange={getCallData}
      /> */}
    </Box>
  );
};

export default Audit;
