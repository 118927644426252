import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import { useUserContext } from "context/user";
import {
  ChevronDown,
  IconDownload,
  IconInfo,
  IconTrash,
  IconUpload2,
  RedAlert,
} from "components/SVG";
import { Controller, useForm } from "react-hook-form";
import { getJSONDecoded, getTimeVariations } from "utils/utils";
import { Circle } from "react-feather";
import { Copy, CSV, Loader, RadioButton } from "components/newSVG";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { useSkillrToast } from "context/toast";
import PropTypes from "prop-types";
import moment from "moment";

const importCallDataOptions = [
  { name: "Webhook", value: "webhook", active: true },
  { name: "CSV", value: "csv", active: true },
  { name: "Apps", value: "apps", active: false },
];

const sampleCSVDownloadLink =
  "https://s3.ap-south-1.amazonaws.com/assets.skillr.ai/CAMPAIGN.csv";

const CreateCampaignStep1 = forwardRef((props, ref) => {
  const { onSave, campaignDetails, disabled } = props;
  const { allBot } = useUserContext();
  const { showSkillrToast } = useSkillrToast();
  const timeVariations = getTimeVariations();
  const supportedFileTypes = ["text/csv"];
  const {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    uploadStatus,
    uploadFiles,
    completeFileUpload,
  } = useFileUpload({ fileTypes: supportedFileTypes });
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ?? null);

  const {
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
      bot: "",
      didNumber: "",
      startTime: "12:00",
      endTime: "16:00",
      durationType: "indefinite",
      parallelCalls: "30",
      importType: "csv",
      webhookIdentifier: `${
        process.env.REACT_APP_BACKEND_API_ENDPOINT
      }/api/campaign/webhook?importId=${Date.now()}${
        tokenInfo?.companies[0]?.id
      }`,
      csvUpload: {},
      startDate: moment().format("YYYY-MM-DD"),
      // endDate: moment().add(2, "day").format("YYYY-MM-DD"),
      preProcessCampaign: false,
    },
  });

  const handleOnCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleRemoveFile = () => {
    setValue("csvUpload", {});
  };

  const handleOnSave = (data) => {
    onSave(data);
  };

  const getEstimatedCalls = () => {
    const formData = watch();
    const averageCallDuration = 3; // 3 mins;
    const minutes = 60; // 60 minutes
    const formattedStartTime = parseInt(formData.startTime);
    const formattedEndTime = parseInt(formData?.endTime);
    const estimatedCalls =
      (formattedEndTime - formattedStartTime) *
      (minutes / averageCallDuration) *
      formData?.parallelCalls;

    return estimatedCalls;
  };

  const endTimeIsGreaterThanStartTime = () => {
    const formData = watch();
    const formattedStartTime = parseInt(formData.startTime);
    const formattedEndTime = parseInt(formData?.endTime);

    return formattedEndTime > formattedStartTime;
  };

  const validateForm = () => {
    handleSubmit(handleOnSave)();
  };

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  useEffect(() => {
    if (allBot) {
      reset({
        ...getValues(),
        bot: allBot?.[0]?._id,
        didNumber: allBot?.[0]?.didNumbers?.[0] ?? "",
      });
    }
  }, [allBot]);

  useEffect(() => {
    if (Object.keys(campaignDetails).length > 0) {
      reset({
        name: campaignDetails?.name,
        bot: campaignDetails?.bot,
        didNumber: campaignDetails?.didNumber,
        startTime: campaignDetails?.startTime,
        endTime: campaignDetails?.endTime,
        durationType: campaignDetails?.durationType,
        parallelCalls: campaignDetails?.parallelCalls,
        importType: campaignDetails?.importType,
        webhookIdentifier: campaignDetails?.webhookIdentifier,
        csvUpload: campaignDetails?.csvUpload,
        startDate: campaignDetails?.startDate,
        endDate: campaignDetails?.endDate,
        preProcessCampaign: campaignDetails?.preProcessCampaign,
      });
    }
  }, [campaignDetails]);

  useEffect(() => {
    if (uploadStatus?.status === "filesValidated") {
      uploadFiles(uploadStatus?.files);
    }

    if (uploadStatus?.status === "completed") {
      setValue("csvUpload", uploadStatus, { shouldValidate: true });
      completeFileUpload();
    }
  }, [uploadStatus]);

  return (
    <Box className={styles.container}>
      <Box>
        <Typography variant="subtitle1">Import Call Data</Typography>
        <Typography variant="body2" mt={1}>
          Configure how your bots will post data to external APIs. Each bot can
          be set up to post data to one or more APIs.
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="caption"
          display="block"
          color="#0f0f0f"
          fontWeight={500}
        >
          Campaign Name
        </Typography>
        <Controller
          control={control}
          name="name"
          rules={{
            required: "Campaign name is required",
          }}
          render={({ field: { onChange, value } }) => (
            <TextField
              disabled={disabled}
              sx={{ mt: 1, width: "50%" }}
              onChange={onChange}
              value={value}
              error={errors?.name ? true : false}
              helperText={
                errors?.name && (
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.name?.message}
                  </Box>
                )
              }
            />
          )}
        />
      </Box>

      <Box>
        <Typography
          variant="caption"
          display="block"
          color="#0f0f0f"
          fontWeight={500}
        >
          Bot Assigned
        </Typography>
        <Controller
          control={control}
          name="bot"
          rules={{
            required: "Bot selection is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Select
                disabled={disabled}
                sx={{ width: "50%", mt: 1 }}
                IconComponent={ChevronDown}
                inputProps={{
                  classes: {
                    icon: styles.chevronDown,
                  },
                }}
                onChange={onChange}
                value={value}
                className={styles.dropDown}
                error={errors?.bot ? true : false}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "40vh",
                      width: "20vw",
                    },
                  },
                }}
              >
                {allBot?.map((bot) => (
                  <MenuItem value={bot?._id} sx={{ gap: 3 }} key={bot._id}>
                    <Typography variant="body2">{bot.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
              {errors?.bot && (
                <FormHelperText error={errors?.bot ? true : false}>
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.bot?.message}
                  </Box>
                </FormHelperText>
              )}
            </>
          )}
        />
      </Box>

      <Box>
        <Typography variant="caption" display="block" color="#0f0f0f">
          DID Number
        </Typography>
        <Controller
          control={control}
          name="didNumber"
          rules={{
            required: "DID Number selection is required",
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Select
                disabled={disabled}
                sx={{ width: "50%", mt: 1 }}
                IconComponent={ChevronDown}
                inputProps={{
                  classes: {
                    icon: styles.chevronDown,
                  },
                }}
                onChange={onChange}
                value={value}
                className={styles.dropDown}
                error={errors?.didNumber ? true : false}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: "40vh",
                      width: "20vw",
                    },
                  },
                }}
              >
                {allBot
                  ?.find((b) => b?._id === watch("bot"))
                  ?.didNumbers?.map((didNumber) => (
                    <MenuItem value={didNumber} sx={{ gap: 3 }} key={didNumber}>
                      <Typography variant="body2">{didNumber}</Typography>
                    </MenuItem>
                  ))}
              </Select>
              {errors?.didNumber && (
                <FormHelperText error={errors?.didNumber ? true : false}>
                  <Box component="span" display="flex" alignItems="center">
                    <RedAlert style={{ marginRight: 8 }} />
                    {errors?.didNumber?.message}
                  </Box>
                </FormHelperText>
              )}
            </>
          )}
        />
      </Box>

      <Box>
        <Typography
          variant="caption"
          display="block"
          color="#0f0f0f"
          fontWeight={500}
        >
          Time Slot
        </Typography>

        <Box mt={4} display="flex" gap={2}>
          <Box width="25%">
            <Typography
              variant="caption"
              display="block"
              color="#0f0f0f"
              fontWeight={500}
            >
              Start Time
            </Typography>
            <Controller
              control={control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <Select
                  disabled={disabled}
                  sx={{ mt: 1, width: "100%" }}
                  IconComponent={ChevronDown}
                  inputProps={{
                    classes: {
                      icon: styles.chevronDown,
                    },
                  }}
                  onChange={onChange}
                  value={value}
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {timeVariations?.map((tv) => (
                    <MenuItem value={tv.value} sx={{ gap: 3 }} key={tv.value}>
                      <Typography variant="body2">{tv.time}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>

          <Box width="25%">
            <Typography
              variant="caption"
              display="block"
              color="#0f0f0f"
              fontWeight={500}
            >
              End Time
            </Typography>
            <Controller
              control={control}
              name="endTime"
              render={({ field: { onChange, value } }) => (
                <Select
                  disabled={disabled}
                  sx={{ width: "100%", mt: 1 }}
                  IconComponent={ChevronDown}
                  onChange={onChange}
                  inputProps={{
                    classes: {
                      icon: styles.chevronDown,
                    },
                  }}
                  value={value}
                  className={styles.dropDown}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "40vh",
                        width: "20vw",
                      },
                    },
                  }}
                >
                  {timeVariations?.map((tv) => (
                    <MenuItem value={tv.value} sx={{ gap: 3 }} key={tv.value}>
                      <Typography variant="body2">{tv.time}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Box>
        </Box>

        {!endTimeIsGreaterThanStartTime() && (
          <Box component="span" display="flex" alignItems="center" mt={1}>
            <RedAlert style={{ marginRight: 8 }} />
            <Typography variant="caption" color="error">
              End time should be greater than start time
            </Typography>
          </Box>
        )}
      </Box>

      <Box display="flex" gap={8}>
        <Controller
          control={control}
          name="durationType"
          render={({ field: { onChange, value } }) => (
            <>
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                component={"label"}
                sx={{ cursor: "pointer" }}
              >
                <Radio
                  disabled={disabled}
                  onClick={() => onChange("indefinite")}
                  checked={value === "indefinite"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                >
                  Indefinite
                </Typography>
              </Box>
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                component={"label"}
                sx={{ cursor: "pointer" }}
              >
                <Radio
                  disabled={disabled}
                  onClick={() => onChange("timeSpecific")}
                  checked={value === "timeSpecific"}
                  sx={{ padding: 0 }}
                  icon={<Circle width={20} height={20} />}
                  checkedIcon={<RadioButton width={20} height={20} />}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                >
                  Time bound
                </Typography>
              </Box>
            </>
          )}
        />
      </Box>

      {watch("durationType") === "timeSpecific" && (
        <Box display="flex" gap={2}>
          <Box width="25%">
            <Typography
              variant="caption"
              display="block"
              color="#0f0f0f"
              fontWeight={500}
            >
              Start Date
            </Typography>
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value } }) => (
                <TextField
                  sx={{ width: "100%", mt: 1 }}
                  type="date"
                  onChange={onChange}
                  value={value}
                  inputProps={{
                    // max: moment(watch("endDate"))
                    //   .subtract(1, "day")
                    //   .format("YYYY-MM-DD"),
                    min: moment().format("YYYY-MM-DD"),
                  }}
                />
              )}
            />
          </Box>

          <Box width="25%">
            <Typography
              variant="caption"
              display="block"
              color="#0f0f0f"
              fontWeight={500}
            >
              End Date
            </Typography>
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value } }) => {
                return (
                  <TextField
                    sx={{ width: "100%", mt: 1 }}
                    type="date"
                    onChange={onChange}
                    value={value}
                    inputProps={{
                      min: moment(watch("startDate"))
                        .add(1, "day")
                        .format("YYYY-MM-DD"),
                    }}
                  />
                );
              }}
            />
          </Box>
        </Box>
      )}

      <Box>
        <Box display="flex" gap={2} alignItems="center">
          <Typography
            variant="caption"
            display="block"
            color="#0f0f0f"
            fontWeight={500}
          >
            Parallel Call Count
          </Typography>

          <Tooltip
            placement="top"
            title="This feature allows you to choose the number of simultaneous calls the bot can handle. The estimated call count is based on an average call duration of 3 minutes and the selected time slot."
          >
            <IconInfo />
          </Tooltip>
        </Box>
        <Controller
          rules={{ required: "Parallel call count is required" }}
          control={control}
          name="parallelCalls"
          render={({ field: { onChange, value } }) => (
            <TextField
              disabled={disabled}
              type="number"
              sx={{ mt: 1, width: "50%" }}
              onChange={onChange}
              value={value}
            />
          )}
        />

        <Typography variant="body2" fontWeight={500} display="block" mt={1}>
          Avg call duration: 3mins. Estimated Calls per Day:{" "}
          {getEstimatedCalls()}
        </Typography>

        {errors?.parallelCalls && (
          <Box component="span" display="flex" alignItems="center">
            <RedAlert style={{ marginRight: 8 }} />
            <Typography variant="caption" color="error">
              {errors?.parallelCalls.message}
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <Box display="flex" gap={2} alignItems="center">
          <Typography
            variant="caption"
            display="block"
            color="#0f0f0f"
            fontWeight={500}
          >
            Import Call Data
          </Typography>
        </Box>
        <Controller
          control={control}
          name="importType"
          render={({ field: { onChange, value } }) => (
            <Select
              disabled={disabled}
              sx={{ mt: 1, width: "50%" }}
              IconComponent={ChevronDown}
              inputProps={{
                classes: {
                  icon: styles.chevronDown,
                },
              }}
              onChange={onChange}
              value={value}
              className={styles.dropDown}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "40vh",
                    width: "20vw",
                  },
                },
              }}
            >
              {importCallDataOptions?.map((cd) => (
                <MenuItem
                  value={cd.value}
                  sx={{ gap: 3 }}
                  key={cd.value}
                  disabled={!cd.active}
                >
                  <Typography variant="body2">{cd.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {!watch("csvUpload")?.files && watch("importType") === "csv" && (
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Button
              startIcon={uploadStatus?.status ? <Loader /> : <IconUpload2 />}
              variant="outlined"
              sx={{
                border: "1px solid #505050",
                "&:hover": {
                  border: "1px solid #505050",
                },
              }}
              disabled={disabled}
              onClick={triggerFileInput}
            >
              Upload csv
            </Button>

            <Button
              startIcon={<IconDownload />}
              variant="text"
              color="primary"
              download
              href={sampleCSVDownloadLink}
            >
              Download Sample Template
            </Button>
          </Box>
        )}

        {watch("importType") === "csv" && watch("csvUpload")?.files && (
          <Box mt={4} width="50%">
            <Typography
              variant="caption"
              display="block"
              color="#0f0f0f"
              fontWeight={500}
            >
              Call Data
            </Typography>
            <Box
              padding="8px 12px"
              border="1px solid #efefef"
              borderRadius={3}
              display="flex"
              alignItems="center"
              mt={1}
              gap={3}
            >
              <CSV />
              <Typography
                variant="caption"
                display="block"
                color="#0f0f0f"
                fontWeight={500}
              >
                {getValues("csvUpload")?.files[0].name}
              </Typography>

              {!disabled && (
                <IconButton onClick={handleRemoveFile} sx={{ ml: "auto" }}>
                  <IconTrash />
                </IconButton>
              )}
            </Box>
          </Box>
        )}

        {watch("importType") === "webhook" && (
          <Box mt={4}>
            <Typography
              variant="caption"
              display="block"
              color="#0f0f0f"
              fontWeight={500}
            >
              URL
            </Typography>
            <Controller
              name="webhookIdentifier"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled
                  onChange={onChange}
                  value={value}
                  sx={{ mt: 2, width: "50%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleOnCopy(value)}>
                          <Copy width={20} height={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        )}
      </Box>

      <Box>
        <Controller
          name="preProcessCampaign"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Switch
                  checked={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              }
              label={
                <Typography variant="caption" color="#0f0f0f">
                  Pre-process
                </Typography>
              }
              labelPlacement="start"
            />
          )}
        />
      </Box>

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={supportedFileTypes.join(",")}
      />
    </Box>
  );
});

CreateCampaignStep1.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  campaignDetails: PropTypes.object,
};

CreateCampaignStep1.defaultProps = {
  onSave: () => {},
  disabled: false,
  campaignDetails: {},
};

export default CreateCampaignStep1;
