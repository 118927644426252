import FundamentoTable from "components/FundamentoTable";
import styles from "./index.module.css";
import { Box, Typography } from "@mui/material";
import { HandMic } from "components/newSVG";
import theme from "theme";
import PropTypes from "prop-types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { statusColorMapping, statusTextMapping } from "text-constants/campaign";

const CampaignList = (props) => {
  const navigate = useNavigate();
  const { currentPage, setCurrentPage, campaignData, totalPageCount } = props;

  const handlePageOnChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTableOnClick = (event) => {
    const row = event.target.closest("tr[id]");
    if (row?.id) {
      const selectedCampaign = campaignData.find((cd) => cd._id === row.id);
      if (selectedCampaign?.status === "draft") {
        navigate(`/campaign/edit/${row.id}`);
      } else {
        navigate(`/campaign/${row.id}`);
      }
    }
  };

  const columns = [
    {
      field: "campaign",
      header: "Campaign",
      style: { width: "40%" },
      render: (row) => {
        return (
          <Box display="flex" alignItems="center" gap={4}>
            <HandMic color="#295B74" width={24} height={24} />
            <Typography variant="body2" fontWeight={500}>
              {row?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "bot",
      header: "Bot",
      render: (row) => {
        return (
          <Box
            className={styles.box}
            sx={{ background: theme.palette.grey[100] }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight="normal"
              color="#505050"
            >
              {row?.bot?.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      header: "Status",
      render: (row) => {
        return (
          <Box
            className={styles.box}
            sx={{ background: statusColorMapping[row?.status].backgroundColor }}
          >
            <Typography
              fontSize={10}
              fontWeight={500}
              lineHeight="normal"
              color={statusColorMapping[row?.status].color}
            >
              {statusTextMapping[row?.status] ?? "N/A"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "timeSlot",
      header: "Time Slot",
      render: (rowInfo) => {
        return `${rowInfo?.startTime} - ${rowInfo?.endTime}`;
      },
    },
    {
      field: "campaignSchedule",
      header: "Campaign Schedule",
      render: (rowInfo) => {
        // combine start date and end date
        return `${moment(rowInfo?.startDate).format("DD-MM-YYYY")} - ${moment(
          rowInfo?.endDate
        ).format("DD-MM-YYYY")}`;
      },
    },
    // {
    //   field: "startDate",
    //   header: "Start Date",
    //   render: (rowInfo) => {
    //     return rowInfo?.startDate
    //       ? moment(rowInfo?.startDate).format("DD-MM-YYYY")
    //       : "-";
    //   },
    // },
    // {
    //   field: "endDate",
    //   header: "End Date",
    //   render: (rowInfo) => {
    //     return rowInfo?.endDate
    //       ? moment(rowInfo?.endDate).format("DD-MM-YYYY")
    //       : "-";
    //   },
    // },
  ];

  return (
    <Box>
      <FundamentoTable
        columns={columns}
        data={campaignData}
        passRowToRender={true}
        pagination={true}
        currentPage={currentPage}
        onPageChange={handlePageOnChange}
        totalPageCount={totalPageCount}
        tableOnClick={handleTableOnClick}
        className={styles.table}
        rowIdKey="_id"
      />
    </Box>
  );
};

CampaignList.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  campaignData: PropTypes.array,
};

export default CampaignList;
