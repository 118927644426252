import FundamentoPopup from "components/FundamentoPopup";
import { useURLState } from "custom-hooks/useUrlState";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { CalendarBlank } from "components/newSVG";
import FlexBox from "components/FlexBox";
import { Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { DateCalendar } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useSkillrToast } from "context/toast";
import PropTypes from "prop-types";

const FundamentoDatePicker = (props) => {
  const { onEndDateChange, onStartDateChange } = props;
  const { showSkillrToast } = useSkillrToast();
  const [startDateForUrl, setStartDateForUrl] = useURLState("", "startDate");
  const [endDateForUrl, setEndDateForUrl] = useURLState("", "endDate");
  const [startDateFilter, setStartDateFilter] = useState(() => {
    if (startDateForUrl) {
      // onStartDateChange({
      //   startDate: moment(startDateForUrl),
      //   changed: "default",
      // });
      return { date: moment(startDateForUrl) };
    } else {
      // onStartDateChange({
      //   startDate: moment().startOf("month"),
      //   changed: "default",
      // });
      return { date: moment().subtract(30, "days") };
    }
  });
  const [endDateFilter, setEndDateFilter] = useState(() => {
    if (endDateForUrl) {
      // onEndDateChange({ endDate: moment(endDateForUrl), changed: "default" });
      return { date: moment(endDateForUrl) };
    } else {
      // onEndDateChange({ endDate: moment(), changed: "default" });
      return { date: moment() };
    }
  });

  const handleEndDateChange = (newValue) => {
    if (moment(newValue).isBefore(startDateFilter)) {
      showSkillrToast("Please select valid date");
    } else {
      setEndDateFilter({ date: newValue });
      setEndDateForUrl(moment(newValue).format("YYYY-MM-DD"));
      onEndDateChange({ endDate: newValue, changed: "manual" });
    }
  };

  const handleStartDateChange = (newValue) => {
    setStartDateFilter({ date: newValue });
    setStartDateForUrl(moment(newValue).format("YYYY-MM-DD"));
    onStartDateChange({ startDate: newValue, changed: "manual" });
  };

  return (
    <div style={{ marginLeft: "auto", cursor: "pointer" }}>
      <FundamentoPopup
        className={styles.popupCalendar}
        triggeringComponent={
          <FlexBox>
            <CalendarBlank width={16} height={16} />
            <Typography
              variant="caption"
              fontWeight={500}
              color="rgba(15,15,15,0.7)"
            >
              Date range: {startDateFilter.date.format("DD MMM")} -{" "}
              {endDateFilter.date.format("DD MMM")}
            </Typography>

            <ArrowDropDown width={20} height={20} />
          </FlexBox>
        }
        disableCloseOnClick={true}
      >
        <FlexBox>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DateCalendar
              maxDate={endDateFilter.date}
              value={startDateFilter.date}
              onChange={handleStartDateChange}
            />
            <DateCalendar
              minDate={startDateFilter.date}
              value={endDateFilter.date}
              onChange={handleEndDateChange}
            />
          </DemoContainer>
        </FlexBox>
      </FundamentoPopup>
    </div>
  );
};

FundamentoDatePicker.propTypes = {
  onEndDateChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
};

FundamentoDatePicker.defaultProps = {
  onEndDateChange: () => {},
  onStartDateChange: () => {},
};

export default FundamentoDatePicker;
