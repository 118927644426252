import { Fade, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle, useUpdateNodeInternals } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import FlexBox from "components/FlexBox";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import NodeBase from "../../NodeBase";

const MessageOptionsNode = (props) => {
  const data = props.data;
  // const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);
  const updateNodeInternals = useUpdateNodeInternals();

  const handleOptionClick = (option) => {
    if (data?.connectedOptions?.includes(option.id)) {
      data.onOptionMinusClick(option);
    } else {
      data.onOptionPlusClick(option);
    }
  };

  useEffect(() => {
    updateNodeInternals(props.id);
  }, [data]);

  return (
    <NodeBase
      isActive={data.isActive}
      onDelete={data.onDelete}
      localizationMode={data.localizationMode}
      fullyLocalized={data.fullyLocalized}
    >
      <Typography variant="body2" fontWeight={500}>
        {data.name
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
          .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {data.message}
      </Typography>
      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />

      {data.options &&
        data?.options?.map((option, index) => (
          <FlexBox
            key={index}
            sx={{
              p: "8px 16px",
              backgroundColor: theme.palette.grey[100],
              borderRadius: "8px",
              mt: 4,
              minHeight: 51,
              position: "relative",
            }}
          >
            <Typography variant="caption">{option.option}</Typography>

            {data.triggerWorkflow && (
              <CustomHandle
                id={option.id}
                position={"right"}
                style={{ right: 16 }}
                clickHandler={() => handleOptionClick(option)}
                icon={
                  data?.connectedOptions?.includes(option.id) ? (
                    <Minus
                      color={theme.palette.text.primary}
                      width={12}
                      height={12}
                    />
                  ) : (
                    <Plus
                      color={theme.palette.text.primary}
                      width={12}
                      height={12}
                    />
                  )
                }
              />
            )}
          </FlexBox>
        ))}

      {data.defaultType === "addNode" && (
        <FlexBox
          sx={{
            p: "8px 16px",
            backgroundColor: theme.palette.grey[100],
            borderRadius: "8px",
            mt: 4,
            minHeight: 51,
            position: "relative",
          }}
        >
          <Typography variant="caption">Default</Typography>

          {/* <IconButton
            className={styles.plusButton}
            onClick={(e) => {
              e.stopPropagation();
              data.onOptionPlusClick("default");
            }}
            sx={{ ml: "auto" }}
          >
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          </IconButton> */}

          {/* <Handle
            type="source"
            position="right"
            id={"default"}
            style={{
              background: "transparent",
              border: 0,
              height: 0,
              width: 0,
              right: -24,
              position: "absolute",
              top: "50%",
            }}
          /> */}

          <CustomHandle
            id={"default"}
            position={"right"}
            style={{ right: 16 }}
            clickHandler={() => {
              data?.connectedOptions?.includes("default")
                ? data.onOptionMinusClick("default")
                : data.onOptionPlusClick("default");
            }}
            icon={
              data?.connectedOptions?.includes("default") ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
          />
        </FlexBox>
      )}

      {(data.defaultType === "nextNode" ||
        (!data.triggerWorkflow && data.setVariable)) && (
        <CustomHandle
          id="source"
          position={"bottom"}
          clickHandler={() => {
            if (data.connectedOptions.includes("source")) {
              data.onOptionMinusClick("source");
            } else {
              data.onOptionPlusClick("source");
            }
          }}
          icon={
            data.connectedOptions.includes("source") ? (
              <Minus
                color={theme.palette.text.primary}
                width={12}
                height={12}
              />
            ) : (
              <Plus color={theme.palette.text.primary} width={12} height={12} />
            )
          }
          style={{
            bottom: "0%",
            left: "50%",
            transform: "translate(-50%, 50%)",
          }}
        />
      )}
    </NodeBase>
  );
};

export default MessageOptionsNode;
