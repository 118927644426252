import { Button, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

const ScriptProperties = ({ data, onSave }) => {
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const handleOnSave = (formData) => {
    console.log("formdata", formData);
    onSave(formData);
  };

  useEffect(() => {
    if (data) {
      reset({
        ...data,
      });
    }
  }, [data]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="body1" fontWeight={500} mb={4}>
        Script
      </Typography>

      <Controller
        name="code"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <CodeMirror
            value={value}
            height="40vh"
            language="js"
            placeholder="Please enter JS code."
            onChange={onChange}
            extensions={[javascript()]}
            padding={15}
            style={{
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              border: `0 !important`,
              borderRadius: 12,
            }}
          />
        )}
      />

      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          marginTop: "auto",
          width: "max-content",
          mt: "auto",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </div>
  );
};

export default ScriptProperties;
