import { Box, Button, TextField, Typography } from "@mui/material";
import FunCollapse from "components/FunCollapse";
import { IconInfo, IconPlus, RedAlert } from "components/SVG";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, get, useFieldArray, useForm } from "react-hook-form";
import theme from "theme";
import PropTypes from "prop-types";

const CreateCampaignStep2 = forwardRef((props, ref) => {
  const { onSave, disabled, campaignDetails } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      redialConfiguration: campaignDetails?.redialConfiguration ?? [
        { hours: 1, minutes: 1, collapsed: true },
      ],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "redialConfiguration",
  });

  const handleAddCounter = () => {
    append({ hours: 1, minutes: 1, collapsed: true });
  };

  const handleOnSave = (data) => {
    console.log("handle on save data", data);
    onSave(data);
  };

  const validateForm = () => {
    handleSubmit(handleOnSave)();
  };

  useImperativeHandle(ref, () => ({
    validateForm,
  }));

  useEffect(() => {
    if (Object.keys(campaignDetails).length > 0) {
      reset({
        redialConfiguration: campaignDetails?.redialConfiguration,
      });
    }
  }, [campaignDetails]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {fields.map(({ id, collapsed }, index) => (
        <Box
          key={id}
          sx={{
            border: `1px solid ${theme.palette.grey[100]}`,
            padding: 4,
            borderRadius: 3,
          }}
        >
          <FunCollapse
            collapsed={collapsed}
            iconPosition="end"
            header={
              <Typography variant="subtitle2">
                Redial Interval {index + 1}
              </Typography>
            }
          >
            <Typography variant="caption">
              Here, users can configure the minimum duration for the first
              redial attempt of phone numbers.
            </Typography>

            <Box display="flex" gap={2} mt={4} alignItems="center">
              <Typography variant="caption">Repeat Time</Typography>
              <IconInfo width={20} height={20} />
            </Box>

            <Box
              display="flex"
              gap={4}
              mt={2}
              position="relative"
              paddingBottom={4}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ width: "25%" }}
              >
                <Controller
                  rules={{ required: "Hours is required" }}
                  control={control}
                  name={`redialConfiguration.${index}.hours`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={disabled}
                      onChange={onChange}
                      value={value}
                      error={
                        get(errors, `redialConfiguration[${index}].hours`)
                          ?.message
                          ? true
                          : false
                      }
                      helperText={
                        get(errors, `redialConfiguration[${index}].hours`) && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                            sx={{ position: "absolute", bottom: "-16px" }}
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {
                              get(errors, `redialConfiguration[${index}].hours`)
                                .message
                            }
                          </Box>
                        )
                      }
                    />
                  )}
                />
                <Typography variant="body2">Hour</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ width: "25%" }}
              >
                <Controller
                  rules={{ required: "Minutes is required" }}
                  control={control}
                  name={`redialConfiguration.${index}.minutes`}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={disabled}
                      onChange={onChange}
                      value={value}
                      error={
                        get(errors, `redialConfiguration[${index}].minutes`)
                          ?.message
                          ? true
                          : false
                      }
                      helperText={
                        get(
                          errors,
                          `redialConfiguration[${index}].minutes`
                        ) && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                            sx={{ position: "absolute", bottom: "-16px" }}
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {
                              get(
                                errors,
                                `redialConfiguration[${index}].minutes`
                              ).message
                            }
                          </Box>
                        )
                      }
                    />
                  )}
                />
                <Typography variant="body2">Min</Typography>
              </Box>
            </Box>
          </FunCollapse>
        </Box>
      ))}

      {!disabled && (
        <Button
          variant="outline"
          startIcon={<IconPlus width={16} height={16} />}
          sx={{ alignSelf: "flex-start" }}
          onClick={handleAddCounter}
        >
          Add Counter
        </Button>
      )}
    </Box>
  );
});

CreateCampaignStep2.propTypes = {
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
  campaignDetails: PropTypes.object,
};

CreateCampaignStep2.defaultProps = {
  onSave: () => {},
  disabled: false,
  campaignDetails: {},
};

export default CreateCampaignStep2;
