import { useState } from "react";
import styles from "./index.module.css";
import { Fade, IconButton } from "@mui/material";
import { AlertTriangle, Trash2 } from "react-feather";
import theme from "theme";

const NodeBase = (props) => {
  const { isActive, onDelete, localizationMode, fullyLocalized, children } =
    props;
  const [isParentHovered, setIsParentHovered] = useState(false);

  return (
    <div
      className={`${styles.messageNode} ${isActive ? styles.active : ""} ${
        localizationMode && !fullyLocalized ? styles.localizationMode : ""
      }`}
      onMouseEnter={() => setIsParentHovered(true)}
      onMouseLeave={() => setIsParentHovered(false)}
    >
      <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade>

      {localizationMode && !fullyLocalized && !isParentHovered && (
        <AlertTriangle
          color={theme.palette.grey[300]}
          width={12}
          height={12}
          style={{ position: "absolute", top: 18, right: 18 }}
        />
      )}

      {children}
    </div>
  );
};

export default NodeBase;
