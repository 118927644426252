import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import EmptyState from "components/EmptyState";
import FlexBox from "components/FlexBox";
import { IconPlus } from "components/SVG";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AddAccountModal from "../components/AddAccountModal";
import { useGoogleLogin } from "@react-oauth/google";
import styles from "./index.module.css";
import { Loader, MoreVertical, X } from "react-feather";
import theme from "theme";
import FundamentoPopup from "components/FundamentoPopup";
import { PencilSimple } from "components/newSVG";
import {
  createIntegration,
  deleteIntegration as deleteIntegrationAPI,
  editIntegration,
  getIntegrations,
} from "services";
import DeleteModal from "components/DeleteModal";
import { useSkillrToast } from "context/toast";

const GoogleSheet = () => {
  const { showSkillrToast } = useSkillrToast();
  const [show, setShow] = useState(false);
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [code, setCode] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [prevName, setPrevName] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const getAllIntegrations = async () => {
    setLoading(true);
    try {
      const response = await getIntegrations("google-sheet");
      setAllIntegrations(response);
    } catch (error) {
      console.log("Failed to fetch Google Sheet Integrations:", error);
    } finally {
      setLoading(false);
    }
  };

  const createNewIntegration = useCallback(async (name, code) => {
    try {
      const response = await createIntegration({
        name: name,
        code: code,
        type: "google-sheet",
        redirect_uri: `${window.location.origin}/integrations/google-sheet`,
      });

      if (response) {
        localStorage.removeItem("tempAccountName");
        getAllIntegrations();
      }
    } catch (error) {
      console.log("Failed to create integration:", error);
    }
  }, []);

  const renameIntegration = async (prevName, newName) => {
    try {
      const response = await editIntegration(prevName.id, newName);
      if (response) {
        setShow(false);
        setPrevName();
        getAllIntegrations();
      }
    } catch (error) {
      console.log("Failed to rename integration:", error);
    }
  };

  const deleteIntegration = async (id) => {
    try {
      const response = await deleteIntegrationAPI(id);
      if (response) {
        // getAllIntegrations();
        setAllIntegrations((prev) =>
          prev.filter((integration) => integration._id !== id)
        );
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      console.log("Failed to delete integration:", error);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setCode(tokenResponse.code);
    },
    onError: (error) => console.log("Login Failed:", error),
    scope:
      "https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    // this scope includes the necessary permissions for Google Sheets and Google Drive and also the user's email and profile information
    flow: "auth-code",
    redirect_uri: `${window.location.origin}/integrations/google-sheet`,
    ux_mode: "redirect",
  });

  const handleGoogleAuth = (name) => {
    localStorage.setItem("tempAccountName", name);
    setAccountName(name);

    if (name) {
      login();
      setShow(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const codeFromUrl = urlParams.get("code");

    if (codeFromUrl) {
      const accountName = localStorage.getItem("tempAccountName");
      if (accountName) {
        createNewIntegration(accountName, codeFromUrl);
        setCode(codeFromUrl);
        setAccountName(accountName);
        localStorage.removeItem("tempAccountName"); // Clean up
      }
      window.history.replaceState({}, document.title, location.pathname);
      getAllIntegrations();
    }
  }, [location]);

  useEffect(() => {
    getAllIntegrations();
  }, []);

  return (
    <Box p={8} height="100vh">
      <Breadcrumbs separator=">">
        <Link to="/integrations">
          <Typography variant="body2" color={"text.secondary"}>
            Integration
          </Typography>
        </Link>
        <Typography variant="body2">Google Sheet</Typography>
      </Breadcrumbs>

      <FlexBox alignItems={"flex-end"} mt={2} justifyContent={"space-between"}>
        <Box>
          <Typography variant="h4" fontWeight={500}>
            Google Sheet
          </Typography>

          <Typography variant="body1" color={"text.secondary"}>
            Connect your bots with Google Sheet
          </Typography>
        </Box>

        <Button
          variant="dark"
          startIcon={<IconPlus width={20} height={20} />}
          sx={{ borderRadius: 3, width: 143, height: 40, padding: 0 }}
          onClick={() => setShow(true)}
        >
          Add Account
        </Button>
      </FlexBox>

      <Box height="80%" width="100%" mt={8}>
        {loading ? (
          <Box className={styles.emptyContainer}>
            <Loader width={40} height={40} color={theme.palette.grey[400]} />
          </Box>
        ) : (
          allIntegrations?.map((integration) => (
            <Box className={styles.container}>
              <FlexBox>
                <Box flex={1}>
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    color={"text.primary"}
                  >
                    Account Name
                  </Typography>

                  <Box
                    style={{ padding: "12px", marginTop: 8 }}
                    className={styles.container}
                  >
                    {integration.name}
                  </Box>
                </Box>
                <Box flex={1}>
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    color={"text.primary"}
                    mb={2}
                  >
                    Account Email
                  </Typography>

                  <Box
                    style={{ padding: "12px", marginTop: 8 }}
                    className={styles.container}
                  >
                    {integration.email || "N/A"}
                  </Box>
                </Box>

                <Box sx={{ alignSelf: "flex-end" }}>
                  <FundamentoPopup
                    className={styles.popup}
                    triggeringComponent={
                      <>
                        <IconButton>
                          <MoreVertical width={20} height={20} />
                        </IconButton>
                      </>
                    }
                  >
                    <Box className={styles.popupButtons}>
                      <FlexBox
                        sx={{
                          borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                        }}
                        onClick={() => {
                          setPrevName({
                            id: integration._id,
                            name: integration.name,
                          });
                          setShow(true);
                        }}
                      >
                        <PencilSimple width={20} height={20} />
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                        >
                          Rename Account
                        </Typography>
                      </FlexBox>
                      <FlexBox
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteId(integration._id);
                        }}
                      >
                        <X width={20} height={20} />
                        <Typography
                          color={theme.palette.text.primary}
                          variant="caption"
                        >
                          Remove
                        </Typography>
                      </FlexBox>
                    </Box>
                  </FundamentoPopup>
                </Box>
              </FlexBox>
            </Box>
          ))
        )}

        {!loading && allIntegrations?.length === 0 && (
          <EmptyState
            title="No Data Available"
            description={"There's no data to show"}
          />
        )}
      </Box>

      <AddAccountModal
        open={show}
        onClose={() => setShow(false)}
        onConfirm={(name) => {
          if (prevName?.name) {
            renameIntegration(prevName, name);
          } else {
            handleGoogleAuth(name);
          }
        }}
        prevName={prevName}
      />

      <DeleteModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        onConfirm={() => deleteIntegration(deleteId)}
        title={"Delete Integration"}
        subtitle={"Are you sure you want to delete this integration?"}
      />
    </Box>
  );
};

export default GoogleSheet;
