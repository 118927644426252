import PageWrapper from "components/PageWrapper";
import Header from "components/Header";
import styles from "./index.module.css";
import { Outlet } from "react-router";
import { Box, Divider } from "@mui/material";
import theme from "theme";
import { useNavigate, useLocation } from "react-router-dom";
import FlexBox from "components/FlexBox";
import { LockSimple } from "components/newSVG";

const KnowledgeBase = () => {
  const tabs = [
    "intents",
    "dictionary",
    <FlexBox sx={{ pointerEvents: "none", gap: 1, cursor: "default" }}>
      <LockSimple /> ontology
    </FlexBox>,
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const [, parentPath, childPath] = location.pathname.split("/");
  const currentRoute = useLocation().pathname;

  const handleTabChange = (value) => {
    navigate(value);
  };

  return (
    <PageWrapper className={styles.container}>
      <Box
        sx={{
          "& [role='tab']:last-of-type ": {
            pointerEvents: "none",
            cursor: "default",
          },
        }}
      >
        <Header
          title="Knowledge Base"
          subTitle="Centralize the knowledge on which your virtual agent is trained on."
          tabs={tabs}
          defaultActiveTab={childPath ? childPath : "intents"}
          onTabChange={handleTabChange}
        />
      </Box>
      <Divider sx={{ backgroundColor: theme.palette.borderColor.light }} />
      <Outlet />
    </PageWrapper>
  );
};

export default KnowledgeBase;
