import {
  Box,
  Checkbox,
  Dialog,
  Grow,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Typography } from "@mui/material";
import styles from "./index.module.css";
import FlexBox from "components/FlexBox";
import FundamentoPopup from "components/FundamentoPopup";
import { ChevronDown, WarningCircle } from "components/SVG";
import EmptyState from "components/EmptyState";
import GenerateQuestionsModal from "../GeneratingQuestionsModal";
import UploadFilesModal from "../UploadFilesModal";
import { useEffect, useState } from "react";
import {
  Check,
  ChevronRight,
  File,
  FileText,
  Loader,
  Trash2,
  Type,
} from "react-feather";
import { useFileUpload } from "custom-hooks/useFileUpload";
import { useSkillrToast } from "context/toast";
import {
  createVariations,
  editQuesAndAnsInAiLab,
  exportToKb,
  getCategoriesByCompany,
  getAllWorkFlow as getAllWorkFlowApi,
  getProcess,
  getProcessData,
  groupQuestions,
  preProcessBeforeTranscription as preProcessBeforeTranscriptionApi,
  unGroupQuestions,
  deleteInAiLab,
} from "services";
import { useParams } from "react-router-dom";
import { getJSONDecoded } from "utils/utils";
import ProgressBar from "components/ProgressBar";
import theme from "theme";
import {
  ChatCenteredDots,
  FlowArrow,
  PencilSimple,
  ShareFat,
  SparkleOutlined,
  Trash,
  TrashSimple,
} from "components/newSVG";
import FunCollapse from "components/FunCollapse";
import { useURLState } from "custom-hooks/useUrlState";
import ConfirmModal from "../ConfirmModal";
import generateAnswers from "services/generateAnswers";
import { useAdminSocket } from "context/socket";
import FilesModal from "../FilesModal";
import EditFaqModal from "pages/DatasetDashboard/components/EditFaqModal";
import DeleteModal from "components/DeleteModal";

const SingleProcess = () => {
  const [genModal, setGenModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [fileUploadMode, setFileUploadMode] = useState("calls");
  const acceptedFileTypes = [
    "audio/*",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const {
    fileInputRef,
    handleFileChange,
    triggerFileInput,
    uploadStatus,
    uploadFiles,
    removeFile,
    completeFileUpload,
  } = useFileUpload({
    fileTypes: acceptedFileTypes,
    maxUploadLimit: 0,
    sizeAlreadyConsumed: 0,
    additionalProcessing,
  });
  const params = useParams();
  const processId = params.processId;
  const token = localStorage.getItem("token");
  const tokenInfo = getJSONDecoded(token ?? null);
  const companyInfo = tokenInfo?.companies?.[0];
  const [category, setCategory] = useState(null);
  const { showSkillrToast } = useSkillrToast();
  const [processData, setProcessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [processStatus, setProcessStatus] = useState(null);
  const [variationConfirmModal, setVariationConfirmModal] = useState(false);
  const [ansGenConfirmModal, setAnsGenConfirmModal] = useState(false);
  const [exportConfirmModal, setExportConfirmModal] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [categoryList, setCategoryList] = useState();
  const [categoryFilter, setCategoryFilter] = useURLState([], "category");
  const [totalCount, setTotalCount] = useState(0);
  const [filesModal, setFilesModal] = useState(false);
  const [quesAnsIndex, setQuesAnsIndex] = useState(null);
  const [editFaqModal, setEditFaqModal] = useState(false);
  const [workflows, setWorkFlows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [generatingQuestions, setGeneratingQuestions] = useState(false);
  const [generatingAnswers, setGeneratingAnswers] = useState(false);
  const [isGroupingVariations, setIsGroupingVariations] = useState(false);
  const [showQuestionsFound, setShowQuestionsFound] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isExported, setIsExported] = useState(false);
  const socket = useAdminSocket(tokenInfo?.companies?.[0]?.id);

  const getAllWorkFlow = async () => {
    try {
      const response = await getAllWorkFlowApi();
      setWorkFlows(response);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleAddFiles = (data) => {
    if (uploadStatus?.files?.length > 0) {
      uploadFiles(uploadStatus?.files);
    } else {
      showSkillrToast("Please select files to upload", "error");
    }
  };

  async function additionalProcessing(info) {
    await preProcessBeforeTranscription([info?.file]);
  }

  const preProcessBeforeTranscription = async (files) => {
    try {
      const apiData = files.map((file) => ({
        processId: processId,
        category: category,
        // category: "65de06ac1aeb2335b5088b40",
        gcsUri: `gs://${process.env.REACT_APP_GCS_BUCKET_NAME}/${companyInfo.id}/${file.newFileName}`,
        name: file.newFileName,
        size: file.size,
        extension: file.extension,
      }));
      const response = await preProcessBeforeTranscriptionApi(apiData);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const trackUploadStatus = async () => {
    try {
      if (uploadStatus?.status === "completed") {
        setGeneratingQuestions(true);
        setIsLocked(true);
        setShowQuestionsFound(false);
        completeFileUpload();
      }

      // if (uploadStatus?.status === "completed") {
      //   const formattedSignedUrls = uploadStatus?.files.map(
      //     (file) =>
      //       `gs://${
      //         import.meta.env.VITE_QUESTION_BANK_GCS_BUCKET_NAME
      //       }/${companyId}/${file.newFileName}`
      //   );
      //   await generateQuestionAnswer({
      //     topicId,
      //     subTopicId: addQuestionDetails?.subTopic?.id,
      //     gcsUris: formattedSignedUrls,
      //   });
      //   setAddQuestionDetails();
      //   completeFileUpload();
      // }
    } catch (error) {
      const errorMessage = error?.response?.data?.error
        ? error.response.data.error
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCategory = async () => {
    try {
      const response = await getCategoriesByCompany();
      setCategoryList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage);
    }
  };

  const handleFilter = (e, filter, setFilter) => {
    const selectedFilter = e.target.value;
    let newFilter;
    if (Array.isArray(filter)) {
      newFilter = [...filter];
      const index = filter.indexOf(selectedFilter);
      if (index > -1) {
        newFilter.splice(index, 1);
      } else {
        newFilter.push(selectedFilter);
      }
    } else {
      newFilter = selectedFilter === filter ? "" : selectedFilter;
    }
    setFilter(newFilter);
  };

  const getSingleProcessData = async () => {
    setLoading(true);
    try {
      const newQueryParams = new URLSearchParams();
      newQueryParams.set("page", currentPage);
      newQueryParams.set("categoryIds", categoryFilter);

      const response = await getProcessData(
        processId,
        newQueryParams.toString()
      );
      setProcessData(response.resultData);
      setTotalPages(response.totalPages);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.message
        ? error?.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
      setLoading(false);
    }
  };
  const columns = [
    processStatus === 3
      ? { field: "checkbox", header: "", style: { minWidth: "10%" } }
      : null,
    { field: "intents", header: "Intents", style: { width: "100%" } },
    { field: "category", header: "Category" },
    processStatus > 3
      ? {
          field: "answerType",
          header: "Intent Type",
        }
      : null,

    processStatus > 1 && !isExported
      ? { field: "actions", header: "Actions", style: { minWidth: "10%" } }
      : null,
  ];

  const getProcessStatus = async () => {
    try {
      const response = await getProcess(processId);
      // response.map((process) => {
      if (response) {
        if (response._id === processId) {
          setTotalCount(response.questionCount);
          setIsLocked(response.isLocked);
          setIsExported(response.isExported);
          setIsGroupingVariations(
            response.isVariationCheckDone === "inProcess"
          );

          setGeneratingQuestions(response.status === "readyForQueGen");
          setGeneratingAnswers(response.isProcessingAnswerGeneration);
          switch (response.status) {
            case "readyForQueGen":
              setProcessStatus(1);
              break;
            case "readyForGroup":
              setProcessStatus(2);
              break;
            case "readyForAnsGen":
              setProcessStatus(3);
              break;
            case "readyForAnnotation":
              setProcessStatus(4);
              break;
            default:
              setProcessStatus(0);
              break;
          }
        }
      }
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGroupVariation = async () => {
    try {
      const response = await createVariations(processId);
      if (response) {
        showSkillrToast("Started group variation", "success");
      }
      setShowQuestionsFound(false);
      getProcessStatus();
    } catch (error) {
      showSkillrToast(error?.response?.data?.message, "error");
      console.log(error);
    }
  };

  const handleAnswerGeneration = async () => {
    try {
      const response = await generateAnswers(
        { hasSelectedAll: true },
        processId
      );
      if (response) {
        showSkillrToast("Generating answers", "success");
        getProcessStatus();
      }
      setGeneratingAnswers(true);
      setShowQuestionsFound(false);
    } catch (error) {
      showSkillrToast(error?.response?.data?.message, "error");
      console.log(error);
    }
  };

  const handleExportToKb = async () => {
    try {
      const response = await exportToKb(processId);
      if (response) {
        showSkillrToast("Exporting to Knowledge Base", "success");
        getProcessStatus();
      }
    } catch (error) {
      showSkillrToast(error?.response?.data?.message, "error");
      console.log(error);
    }
  };

  const handleManualGroup = async () => {
    if (selectedQuestions.length === 0) {
      showSkillrToast("Please select a question to group", "error");
      return;
    }

    try {
      const response = await groupQuestions({ questionIds: selectedQuestions });
      if (response) {
        showSkillrToast("Grouped variations successfully", "success");
      }

      if (selectedQuestions.length > processData.length) {
        setCurrentPage(currentPage - 1);
      }

      setSelectedQuestions([]);
      getSingleProcessData();
      getProcessStatus();
    } catch (error) {
      showSkillrToast(error?.response?.data?.message, "error");
      console.log(error);
    }
  };

  const handleManualUngroup = async () => {
    if (selectedQuestions.length === 0) {
      showSkillrToast("Please select a question to ungroup", "error");
      return;
    }

    try {
      const response = await unGroupQuestions({
        questionIds: selectedQuestions,
      });
      if (response) {
        showSkillrToast("Ungrouped variations successfully", "success");
      }
      setSelectedQuestions([]);
      getSingleProcessData();
      getProcessStatus();
    } catch (error) {
      showSkillrToast(error?.response?.data?.message, "error");
      console.log(error);
    }
  };

  const handleManualQuesAns = (index) => {
    setEditFaqModal(true);
    if (index !== null) {
      setQuesAnsIndex(index);
    }
  };

  const deleteAnswer = async () => {
    try {
      const response = await deleteInAiLab(quesAnsIndex);
      if (response) {
        setDeleteModal(false);
        showSkillrToast("Intent deleted successfully", "success");

        setProcessData((prevData) =>
          prevData.filter((question) => question._id !== quesAnsIndex)
        );

        // const deletedIntentIndex = processData?.findIndex(
        //   (question) => question._id === quesAnsIndex
        // );

        // processData?.splice(deletedIntentIndex, 1);

        // setProcessData(newData);
        setQuesAnsIndex(null);

        if (processData?.length === 0 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        getProcessStatus();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleDeleteQuesAns = (quesId) => {
    setQuesAnsIndex(quesId);
    setDeleteModal(true);
  };

  const editQuesAns = async (editedQuesAns) => {
    try {
      const apiData = {
        _id: editedQuesAns.id,
        question: editedQuesAns.question?.question,
        primaryQuestionReplacedWithVariantId:
          editedQuesAns?.question === processData?.[quesAnsIndex]?.question
            ? null
            : editedQuesAns.question?.id,
        questionVariant: editedQuesAns.questionVariant,
        ...(editedQuesAns.answer && { answer: editedQuesAns.answer }),
        ...(editedQuesAns.workflow && { workFlow: editedQuesAns.workflow }),
        category: editedQuesAns?.category,
        type: editedQuesAns?.type,
      };
      const editQuesAnsResponse = await editQuesAndAnsInAiLab(apiData);
      return editQuesAnsResponse;
    } catch (error) {
      throw error;
    }
  };

  const handleCloseQAManually = () => {
    setEditFaqModal(false);
    setQuesAnsIndex(null);
  };

  const handleSaveQAManually = async (formData) => {
    try {
      // if (quesAnsIndex !== null) {
      await editQuesAns(formData);
      // } else {
      //   await addQuesAns(formData);
      // }
      handleCloseQAManually();
      getSingleProcessData();
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleErrorMessage = (error) => {
    const errorMessage = error?.message
      ? error.messsage
      : "Something went wrong";
    showSkillrToast(errorMessage, "error");
  };

  const handleSocketUpdate = () => {
    socket.on("questions-stat", (data) => {
      try {
        if (data?.processId === processId) {
          if (data?.isQuestionsGenerated !== true) {
            setGeneratingQuestions(true);
            setProgress(
              Math.ceil(
                (data?.questionGeneratedMediaCount / data?.totalmediaCount) *
                  100
              )
            );
          } else {
            setGeneratingQuestions(false);
            setProgress(0);
            setShowQuestionsFound(true);
          }

          setTotalCount(data?.questionsTotalCount);

          setProcessData(data?.questionDetails?.processData);
          setTotalPages(data?.questionDetails?.totalPages);
          getProcessStatus();
        }
        console.log("media count socket", data);
      } catch (error) {
        handleErrorMessage(error);
      }
    });

    socket.on("variation_check", (data) => {
      try {
        if (data?.processId === processId) {
          setProgress(
            Math.ceil(
              (data?.completedLengthofDupCheck /
                data?.totalQuesInDBForDupCheck) *
                100
            )
          );
          getProcessStatus();
          if (data?.isVariationCheckDone) {
            setIsGroupingVariations(false);
            getSingleProcessData();
            getProcessStatus();
            setProgress(0);
          }
          console.log("variation check socket", data);
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    });

    socket.on("question-answer-count", (data) => {
      try {
        if (data?.processId === processId) {
          setProgress(
            Math.ceil(
              (data?.numberOfQuestionsAnswersGenerated /
                data?.totalNumberOfQuestionsToGenerateAnswers) *
                100
            )
          );
          if (
            data?.numberOfQuestionsAnswersGenerated /
              data?.totalNumberOfQuestionsToGenerateAnswers ===
            1
          ) {
            getSingleProcessData();
            getProcessStatus();
            setProgress(0);
          }
          console.log("generating answer socket", data);
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    });

    socket.on("file-processing-failed", (data) => {
      try {
        if (data?.processId === processId) {
          showSkillrToast(data?.message, "error");
          if (!data?.updatedProcess?.status) {
            setProcessStatus(0);
          } else if (data?.updatedProcess?.status === "readyForGroup") {
            setProcessStatus(2);
          }
        }
      } catch (error) {
        handleErrorMessage(error);
      }
    });
  };

  const getRowIcon = (row) => {
    if (row?.type) {
      if (row?.type === "workflow") {
        return <FlowArrow width={12} height={12} />;
      } else if (row?.type === "faq") {
        return <Type width={12} height={12} />;
      } else {
        return <SparkleOutlined width={12} height={12} />;
      }
    } else {
      if (row?.answer) {
        return <Type width={12} height={12} />;
      } else {
        return <FlowArrow width={12} height={12} />;
      }
    }
  };

  const getRowType = (row) => {
    if (row?.type) {
      if (row?.type === "workflow") {
        return (
          <span fontSize={10} color={theme.palette.primary.main}>
            {row?.workFlow?.name}
          </span>
        );
      } else if (row?.type === "faq") {
        return "FAQ";
      } else {
        return "Smart+";
      }
    } else {
      if (row?.answer) {
        return "FAQ";
      } else {
        return "Workflow";
      }
    }
  };

  useEffect(() => {
    trackUploadStatus();
  }, [uploadStatus]);

  useEffect(() => {
    getSingleProcessData();
    getProcessStatus();
    handleSocketUpdate();
    getAllWorkFlow();
    getCategory();
  }, []);

  useEffect(() => {
    if (
      isGroupingVariations ||
      generatingAnswers ||
      generatingQuestions ||
      processStatus < 1
    ) {
      setShowQuestionsFound(false);
    } else {
      setShowQuestionsFound(true);
    }
  }, [processStatus]);

  useEffect(() => {
    getSingleProcessData();
  }, [currentPage, categoryFilter]);

  return (
    <>
      {["started", "processing"].indexOf(uploadStatus?.status) > -1 && (
        <ProgressBar
          startIcon={
            uploadStatus?.status === "completed" ? (
              <Check width={16} height={16} />
            ) : (
              <Loader className={styles.rotate} width={16} height={16} />
            )
          }
          message={
            uploadStatus?.status === "completed"
              ? "File uploaded"
              : `${uploadStatus?.totalFiles} files are being processed`
          }
          progress={Math.ceil(
            (uploadStatus?.processedFiles / uploadStatus?.totalFiles) * 100
          )}
          cancelGeneration={false}
          handleCancelGeneration={() => {}}
        />
      )}

      {(processStatus === 1 && processData.length === 0) ||
        (generatingQuestions && (
          // <FlexBox
          //   sx={{
          //     backgroundColor: theme.palette.grey[50],
          //     padding: "16px 48px",
          //     borderBottom: `1px solid ${theme.palette.borderColor.light}`,
          //   }}
          // >
          //   <Loader
          //     className={styles.rotate}
          //     color={theme.palette.text.primary}
          //     width={16}
          //     height={16}
          //   />
          //   <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
          //     Generating Questions
          //   </Typography>

          //   {!isNaN(progress) && (
          //     <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
          //       {progress} %
          //     </Typography>
          //   )}
          // </FlexBox>

          <ProgressBar
            startIcon={
              <Loader className={styles.rotate} width={16} height={16} />
            }
            message={"Generating questions"}
            progress={progress}
            cancelGeneration={false}
            handleCancelGeneration={() => {}}
          />
        ))}

      {isGroupingVariations && (
        // <FlexBox
        //   sx={{
        //     backgroundColor: theme.palette.grey[50],
        //     padding: "16px 48px",
        //     borderBottom: `1px solid ${theme.palette.borderColor.light}`,
        //   }}
        // >
        //   <Loader
        //     className={styles.rotate}
        //     color={theme.palette.text.primary}
        //     width={16}
        //     height={16}
        //   />
        //   <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
        //     Grouping variations
        //   </Typography>
        //   {!isNaN(progress) && (
        //     <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
        //       {progress} %
        //     </Typography>
        //   )}
        // </FlexBox>
        <ProgressBar
          startIcon={
            <Loader className={styles.rotate} width={16} height={16} />
          }
          message={"Grouping variations"}
          progress={progress}
          cancelGeneration={false}
          handleCancelGeneration={() => {}}
        />
      )}

      {generatingAnswers && (
        // <FlexBox
        //   sx={{
        //     backgroundColor: theme.palette.grey[50],
        //     padding: "16px 48px",
        //     borderBottom: `1px solid ${theme.palette.borderColor.light}`,
        //   }}
        // >
        //   <Loader
        //     className={styles.rotate}
        //     color={theme.palette.text.primary}
        //     width={16}
        //     height={16}
        //   />
        //   <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
        //     Generating Answers
        //   </Typography>
        //   {!isNaN(progress) && (
        //     <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
        //       {progress} %
        //     </Typography>
        //   )}
        // </FlexBox>
        <ProgressBar
          startIcon={
            <Loader className={styles.rotate} width={16} height={16} />
          }
          message={"Generating answers"}
          progress={progress}
          cancelGeneration={false}
          handleCancelGeneration={() => {}}
        />
      )}

      {/* questions found strip containing files modal button */}
      {showQuestionsFound && (
        <FlexBox
          sx={{
            backgroundColor: theme.palette.grey[50],
            padding: "16px 48px",
            borderBottom: `1px solid ${theme.palette.borderColor.light}`,
          }}
        >
          <Typography variant="caption" color={"rgba(15, 15, 15, 0.5)"}>
            {processStatus < 4
              ? `${totalCount} questions found`
              : `${totalCount} questions and answers generated `}
          </Typography>
          <Button
            variant="text"
            color="inherit"
            sx={{
              padding: 0,
              minWidth: 0,
              ml: "auto",
              fontSize: 12,
              color: "rgba(15, 15, 15, 0.5)",
            }}
            startIcon={<FileText width={16} height={16} />}
            onClick={() => setFilesModal(true)}
          >
            Files associated
          </Button>
        </FlexBox>
      )}

      <Box
        sx={{
          flex: 1,
          padding: "24px 48px",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          ...(isLocked ? { pointerEvents: "none", opacity: 0.5 } : {}),
        }}
      >
        <FlexBox mb={2}>
          {processStatus > 1 && (
            <FundamentoPopup
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              disableCloseOnClick={true}
              triggeringComponent={
                <Button
                  variant="outlined"
                  className={categoryFilter.length > 0 && styles.activeFilter}
                  sx={{ borderRadius: 82, padding: "8px 14px", minWidth: 0 }}
                  endIcon={<ChevronDown width={16} height={16} />}
                >
                  Category
                </Button>
              }
            >
              <Box
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                {categoryList?.map((item) => (
                  <FlexBox columnGap={2} component="label" key={item?.id}>
                    <Checkbox
                      value={item?._id}
                      onChange={(e) =>
                        handleFilter(e, categoryFilter, setCategoryFilter)
                      }
                      checked={categoryFilter.indexOf(item?._id) > -1}
                    />
                    <Typography variant="body2">{item.name}</Typography>
                  </FlexBox>
                ))}
              </Box>
            </FundamentoPopup>
          )}

          <FlexBox ml="auto">
            {processStatus > 0 && processStatus < 3 && (
              <FundamentoPopup
                triggeringComponent={
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: 82,
                      padding: "8px 14px",
                      minWidth: 0,
                    }}
                    endIcon={<ChevronDown width={16} height={16} />}
                  >
                    Actions
                  </Button>
                }
                className={styles.popup}
              >
                <Box>
                  {processStatus > 0 && processStatus < 3 && (
                    <Box
                      className={styles.popupItem}
                      onClick={() => setGenModal(true)}
                    >
                      {/* <Upload width={16} height={16} /> */}
                      <Typography variant="body2">Upload files</Typography>
                    </Box>
                  )}

                  {processStatus === 2 && (
                    <Box
                      className={styles.popupItem}
                      onClick={() => setVariationConfirmModal(true)}
                    >
                      {/* <Upload width={16} height={16} /> */}
                      <Typography variant="body2">Group variations</Typography>
                    </Box>
                  )}
                </Box>
              </FundamentoPopup>
            )}

            {processStatus === 3 && (
              <FlexBox>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: 82, minWidth: 0, height: 36 }}
                  onClick={() => handleManualGroup()}
                >
                  Group
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleManualUngroup([selectedQuestions[0]])}
                  sx={{ borderRadius: 82, minWidth: 0, height: 36 }}
                >
                  Ungroup
                </Button>
              </FlexBox>
            )}

            {processStatus > 1 && processStatus < 4 && (
              <Button
                variant="dark"
                sx={{ px: "14px", fontSize: 12, height: 36 }}
                startIcon={<ChatCenteredDots />}
                onClick={() => setAnsGenConfirmModal(true)}
              >
                Generate Answers
              </Button>
            )}

            {processStatus === 4 && !isExported && (
              <Button
                variant="dark"
                sx={{ px: "14px", fontSize: 12, height: 36 }}
                startIcon={<ShareFat />}
                onClick={() => setExportConfirmModal(true)}
              >
                Export Intents
              </Button>
            )}
          </FlexBox>
        </FlexBox>

        {loading && (
          <FlexBox sx={{ height: "80%", justifyContent: "center" }}>
            <Loader color={theme.palette.grey[400]} width={40} height={40} />
          </FlexBox>
        )}

        {!loading && processData?.length === 0 && (
          <Box sx={{ height: "80%" }}>
            <EmptyState
              title={
                generatingQuestions
                  ? "Questions are being generated"
                  : "Get started with your files"
              }
              subtitle={
                generatingQuestions
                  ? "Please wait while we generate questions for you"
                  : ""
              }
              buttonText={generatingQuestions ? null : "Upload files"}
              buttonAction={
                generatingQuestions ? null : () => setGenModal(true)
              }
            />
          </Box>
        )}

        {!loading && processData?.length > 0 && (
          <>
            <Box
              mt={1}
              sx={{
                position: "relative",
                flex: 1,
                overflowY: "auto",
              }}
            >
              <Table className={styles.table}>
                <TableHead
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1000,
                  }}
                >
                  <TableRow>
                    {columns.map(
                      (column, index) =>
                        column && (
                          <TableCell
                            style={
                              column.header === "Actions"
                                ? { textAlign: "right" }
                                : {}
                            }
                            key={index}
                          >
                            <Typography
                              variant="caption"
                              fontWeight={500}
                              color="#262626"
                            >
                              {column.header}
                            </Typography>
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {processData?.map((row, index) => (
                    <TableRow key={index}>
                      {processStatus === 3 && (
                        <TableCell>
                          <Checkbox
                            color="primary"
                            sx={{ padding: 0 }}
                            value={row._id}
                            checked={selectedQuestions.includes(row._id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedQuestions((prev) => [
                                  ...prev,
                                  row._id,
                                ]);
                              } else {
                                setSelectedQuestions((prev) =>
                                  prev.filter((id) => id !== row._id)
                                );
                              }
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell style={{ width: "80%" }}>
                        {row.answer ||
                        row?.questionVariant?.length > 0 ||
                        row.workFlow ? (
                          <FunCollapse
                            iconPosition={"start"}
                            header={
                              <FlexBox columnGap={2}>
                                <Typography
                                  variant="caption"
                                  fontWeight={400}
                                  fontFamily={"Inter"}
                                  color={theme.palette.grey[800]}
                                >
                                  {row.question}
                                </Typography>
                                {row?.questionVariant?.length > 0 && (
                                  <Box className={styles.questionVariant}>
                                    <Typography
                                      fontSize={10}
                                      fontWeight={500}
                                      fontFamily={"Inter"}
                                      lineHeight="normal"
                                    >
                                      +{row?.questionVariant?.length} var
                                    </Typography>
                                    <ChevronRight
                                      width={8}
                                      height={8}
                                      color="#0094FF"
                                    />
                                  </Box>
                                )}
                              </FlexBox>
                            }
                          >
                            {row?.answer ? (
                              <Typography
                                variant="caption"
                                component="p"
                                mt={2}
                                fontWeight={400}
                                color={theme.palette.grey[800]}
                                style={{
                                  paddingLeft: 22,
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {row.answer}
                              </Typography>
                            ) : row?.workFlow ? (
                              <FlexBox
                                sx={{ paddingLeft: "22px", marginTop: "8px" }}
                              >
                                <FlowArrow
                                  width={12}
                                  height={12}
                                  className={styles.flowArrow}
                                  gap={1}
                                />
                                <Typography
                                  fontSize={10}
                                  color={theme.palette.primary.main}
                                >
                                  Workflow linked: {row?.workFlow?.name}
                                </Typography>
                              </FlexBox>
                            ) : (
                              row?.questionVariant?.map((variant, index) => (
                                <Typography
                                  key={index}
                                  variant="caption"
                                  component="p"
                                  sx={{
                                    my: 4,
                                    whiteSpace: "pre-line",
                                    "&:last-of-type": {
                                      marginBottom: 0,
                                    },
                                  }}
                                  fontWeight={400}
                                  color={theme.palette.grey[800]}
                                >
                                  {variant?.question}
                                </Typography>
                              ))
                            )}
                          </FunCollapse>
                        ) : (
                          <Typography
                            variant="caption"
                            fontWeight={400}
                            fontFamily={"Inter"}
                            color={theme.palette.grey[800]}
                          >
                            {row.question}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell>
                        <Box className={styles.box}>
                          <Typography
                            fontSize={10}
                            fontWeight={500}
                            lineHeight="normal"
                          >
                            {row?.category?.name}
                          </Typography>
                        </Box>
                      </TableCell>

                      {processStatus > 3 && (
                        <TableCell>
                          <FlexBox
                            className={styles.box}
                            gap="6px"
                            justifyContent="space-around"
                          >
                            {/* {row?.answer ? (
                              <Type width={12} height={12} />
                            ) : (
                              <FlowArrow width={12} height={12} />
                            )} */}
                            <Typography
                              fontSize={10}
                              fontWeight={500}
                              lineHeight="normal"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {getRowIcon(row)}

                              {/* {row?.type ? (
                                row?.type === "workFlow" ? (
                                  <FlowArrow width={12} height={12} />
                                ) : row?.type === "faq" ? (
                                  <Type width={12} height={12} />
                                ) : (
                                  <SparkleOutlined width={12} height={12} />
                                )
                              ) : row?.answer ? (
                                <Type width={12} height={12} />
                              ) : (
                                <FlowArrow width={12} height={12} />
                              )} */}
                            </Typography>

                            <Typography
                              fontSize={10}
                              fontWeight={500}
                              lineHeight="normal"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {getRowType(row)}

                              {/* {row?.type ? (
                                  row?.type === "workFlow" ? (
                                    <Typography
                                      fontSize={10}
                                      color={theme.palette.primary.main}
                                    >
                                      {row?.workFlow?.name}
                                    </Typography>
                                  ) : row?.type === "faq" ? (
                                    "Text Answer"
                                  ) : (
                                    "Smart+"
                                  )
                                ) : row.answer ? (
                                  "Text answer"
                                ) : (
                                  "Workflow linked"
                                )} */}
                            </Typography>
                          </FlexBox>
                        </TableCell>
                      )}
                      {/* {row.answer ? "Text answer" : "Workflow linked"}
                            </Typography>
                          </FlexBox>
                        </TableCell> */}
                      {/* )} */}

                      {processStatus > 1 && !isExported && (
                        <TableCell align="right">
                          <>
                            <FlexBox
                              columnGap={0}
                              width="max-content"
                              ml="auto"
                            >
                              {processStatus > 3 && (
                                <IconButton
                                  onClick={() => handleManualQuesAns(index)}
                                >
                                  <PencilSimple
                                    color="#0f0f0f"
                                    width={16}
                                    height={16}
                                  />
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => handleDeleteQuesAns(row._id)}
                              >
                                <TrashSimple width={16} height={16} />
                                {/* <Trash2 width={16} height={16} /> */}
                              </IconButton>
                            </FlexBox>
                          </>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box className={styles.pagination}>
                <Pagination
                  page={currentPage}
                  count={totalPage}
                  shape="rounded"
                  onChange={(e, pageCount) => {
                    setCurrentPage(pageCount);
                  }}
                />
              </Box>
            </Box>
          </>
        )}

        <GenerateQuestionsModal
          open={genModal}
          onClose={() => setGenModal(false)}
          onSave={(mode) => {
            setFileUploadMode(mode);
            setGenModal(false);
            setUploadModal(true);
          }}
        />
        <UploadFilesModal
          open={uploadModal}
          mode={fileUploadMode}
          onBack={() => {
            setGenModal(true);
            setUploadModal(false);
            completeFileUpload();
          }}
          onClose={() => {
            setUploadModal(false);
            completeFileUpload();
          }}
          triggerFileInput={triggerFileInput}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          acceptedFileTypes={acceptedFileTypes}
          handleAddFiles={handleAddFiles}
          removeFile={removeFile}
          selectedFiles={uploadStatus?.files}
          completeFileUpload={completeFileUpload}
          onSave={(category) => {
            setCategory(category);
          }}
        />

        {variationConfirmModal && (
          <ConfirmModal
            open={variationConfirmModal}
            onClose={() => setVariationConfirmModal(false)}
            onConfirm={() => handleGroupVariation()}
            title={"Are you sure you want to group the Intents?"}
            subtitle={"Consolidating similar intents to create distinct ones"}
            list={[
              "All the variations will be permanently grouped",
              "Same answer will be shown for this group",
            ]}
            icon={<WarningCircle />}
          />
        )}

        {ansGenConfirmModal && (
          <ConfirmModal
            open={ansGenConfirmModal}
            onClose={() => setAnsGenConfirmModal(false)}
            onConfirm={() => handleAnswerGeneration()}
            title={"Generate answers for all questions?"}
            subtitle={
              "Use AI to generate answers for the Intents in this folder"
            }
            list={[
              "Once answer generation is started, you won’t be able to add more files, or run group variations on the Intents",
              `You are about to generate answers for ${totalCount} Intents`,
            ]}
            icon={<WarningCircle />}
          />
        )}

        {exportConfirmModal && (
          <ConfirmModal
            open={exportConfirmModal}
            onClose={() => setExportConfirmModal(false)}
            onConfirm={() => handleExportToKb()}
            title={"Export all Intents to knowledge base?"}
            subtitle={"All Intents will be moved to Knowledge Base"}
            list={[
              "You cannot annotate the Intents in this folder once exported",
              "Any changes made in the Knowledge Base will not reflect in this folder",
            ]}
            icon={<WarningCircle />}
          />
        )}

        {filesModal && (
          <FilesModal
            open={filesModal}
            onClose={() => setFilesModal(false)}
            processId={processId}
          />
        )}

        <EditFaqModal
          open={editFaqModal}
          onClose={() => {
            setEditFaqModal(false);
            setQuesAnsIndex(null);
          }}
          question={processData?.[quesAnsIndex]}
          onSave={handleSaveQAManually}
          workflows={workflows?.workFlows}
          loading={false}
          categories={categoryList}
          categoryKey="category"
          questionIdKey="_id"
        />

        <DeleteModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          onConfirm={deleteAnswer}
          title={"Delete Intent?"}
          subtitle={
            "This action can't be undone and the data will be deleted from your account permanently"
          }
          list={[
            "This will delete the intent permanently",
            "You will have to regenerate the intent",
          ]}
        />
      </Box>
    </>
  );
};

export default SingleProcess;
