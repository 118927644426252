import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
  Panel,
  useKeyPress,
} from "reactflow";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.css";
import "reactflow/dist/style.css";
import { Check, ChevronDown, Clipboard, LogOut, Type, X } from "react-feather";
import {
  Box,
  Button,
  IconButton,
  Slide,
  Typography,
  TextField,
} from "@mui/material";
import theme from "theme";
import FlexBox from "components/FlexBox";
import StartNode from "./components/AllNodes/StartNode";
import MessageNode from "./components/AllNodes/MessageNode";
import {
  BracketsCurly,
  CodaLogo,
  DiamondsFour,
  GitBranch,
  ImagesSquare,
  ListBullets,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  SpeakerHigh,
  PencilSimple,
  Textbox,
  ChatBubble,
  Code,
  GoogleSheets,
} from "components/newSVG";
import MessageProperties from "./components/Sidebars/MessageProperties";
import InputProperties from "./components/Sidebars/InputProperties";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import InputNode from "./components/AllNodes/InputNode";
import {
  editWorkFlow,
  getSingleWorkFlow,
  getAllWorkFlow as getAllWorkFlowApi,
} from "services";
import { useSkillrToast } from "context/toast";
import DecisionProperties from "./components/Sidebars/DecisionProperties";
import DeleteModal from "components/DeleteModal";
import DecisionNode from "./components/AllNodes/DecisionNode";
import TriggerWorkflowProperties from "./components/Sidebars/TriggerWorkflowProperties";
import TriggerNode from "./components/AllNodes/TriggerNode";
import RestApiProperties from "./components/Sidebars/RestApiProperties";
import RestApiNode from "./components/AllNodes/RestApiNode";
import MessageOptionsNode from "./components/AllNodes/MessageOptionsNode";
import MessageOptionsProperties from "./components/Sidebars/MessageOptionsProperties";
import AudioNode from "./components/AllNodes/AudioNode";
import AudioProperties from "./components/Sidebars/AudioProperties";
import { IconClose } from "components/SVG";
import ConversationNode from "./components/AllNodes/ConversationNode";
import ConversationProperties from "./components/Sidebars/ConversationProperties";
import DevTools from "./components/Devtools";
import ScriptNode from "./components/AllNodes/ScriptNode";
import ScriptProperties from "./components/Sidebars/ScriptProperties";
import FundamentoPopup from "components/FundamentoPopup";
import { useUserContext } from "context/user";
import { languages as defaultLanguages } from "text-constants/common";
import GoogleSheetNode from "./components/AllNodes/GoogleSheetNode";
import GoogleSheetProperties from "./components/Sidebars/GoogleSheetProperties";

const PanelForZoom = () => {
  const { zoomIn, zoomOut } = useReactFlow();

  return (
    <Panel position="top-right" style={{ position: "fixed", zIndex: 10000 }}>
      <IconButton
        onClick={() => {
          zoomIn({ duration: 400 });
        }}
      >
        <MagnifyingGlassPlus width={20} height={20} />
      </IconButton>
      <IconButton
        onClick={() => {
          zoomOut({ duration: 400 });
        }}
      >
        <MagnifyingGlassMinus width={20} height={20} />
      </IconButton>
    </Panel>
  );
};

const ResetViewport = ({ nodes }) => {
  const { setViewport } = useReactFlow();

  const startNode = nodes?.find((node) => node.id === "0");
  useEffect(() => {
    if (startNode) {
      const x = window.innerWidth / 2 - startNode.position.x - 100;
      setViewport({ x, y: -200, zoom: 1 });
    }
  }, [startNode]);

  return <></>;
};

const Diagram = () => {
  const location = useLocation();
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();
  const nodeTypes = useMemo(
    () => ({
      startNode: StartNode,
      messageNode: MessageNode,
      inputNode: InputNode,
      decisionNode: DecisionNode,
      triggerNode: TriggerNode,
      apiNode: RestApiNode,
      messageOptionsNode: MessageOptionsNode,
      audioNode: AudioNode,
      conversationNode: ConversationNode,
      scriptNode: ScriptNode,
      googleSheetNode: GoogleSheetNode,
    }),
    []
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [nodeSideBarOpen, setNodeSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const workflowID = params.workflowID;
  const [workflowData, setWorkflowData] = useState(null);
  const { showSkillrToast } = useSkillrToast();
  const [messageSaved, setMessageSaved] = useState(false);
  const [clickedNode, setClickedNode] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [decisionNodeFlowType, setDecisionNodeFlowType] = useState(null);
  const [workFlowList, setWorkFlowList] = useState([]);
  const [summarySection, setSummarySection] = useState(false);
  const [workFlowSummary, setWorkFlowSummary] = useState("");
  const [workFlowEdited, setWorkFlowEdited] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [localizationMode, setLocalizationMode] = useState(false);
  const prevBotVoicesRef = useRef();
  const { getBot, bot } = useUserContext();
  const token = localStorage.getItem("token");
  const keysPressed = useKeyPress("Control+Enter");
  const initialNodes = [
    {
      id: "0",
      type: "startNode",
      position: { x: 150, y: 250 },
      data: {
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode(initialNodes[0]);
          setClickedNode((prevClickedNode) => ({
            ...initialNodes[0],
            data: {
              ...initialNodes[0].data,
              selectedOption: "source",
            },
          }));
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === "0") {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              } else {
                return node;
              }
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== "0")
          );
          setTimeout(() => onSave(), 0);
        },
      },
      draggable: false,
    },
  ];

  const onConnect = useCallback(
    (params) => {
      const sourceNode = nodes.find((node) => node.id === params.source);

      if (
        sourceNode.type === "decisionNode" ||
        sourceNode.type === "messageOptionsNode" ||
        sourceNode.type === "inputNode" ||
        sourceNode.type === "conversationNode"
      ) {
        // check if an edge already exists from that source handle
        // also check if there exists an edge to the same target
        const edgeExists = edges.some((edge) => {
          return (
            edge.source === params.source &&
            edge.sourceHandle === params.sourceHandle
            // ||edge.target === params.target
          );
        });

        if (!edgeExists) {
          // Add a specific property to the edge based on the sourceHandle
          const edge = {
            ...params,
            ...(params.sourceHandle &&
              params.sourceHandle !== "source" && {
                option: params.sourceHandle,
              }),
            ...(params.sourceHandle === "default" && {
              edgeType: "default",
            }),
          };

          setEdges((e) => addEdge(edge, e));
        }

        // update the selected option of the source node
        if (params.sourceHandle === "default") {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === params.source) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: params.sourceHandle,
                  },
                };
              }
              return node;
            })
          );
        }

        if (sourceNode.type === "decisionNode") {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === params.source) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    ...(params.sourceHandle === "true"
                      ? { trueFlowAdded: true }
                      : {}),
                    ...(params.sourceHandle === "false"
                      ? { falseFlowAdded: true }
                      : {}),
                  },
                };
              }
              return node;
            })
          );
        }

        if (sourceNode.type === "messageOptionsNode") {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === params.source) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    connectedOptions: [
                      ...node.data.connectedOptions,
                      params.sourceHandle,
                    ],
                  },
                };
              }
              return node;
            })
          );
        }
      } else {
        // Check if an edge already exists from the source node
        const edgeExists = edges.some((edge) => edge.source === params.source);

        if (!edgeExists) {
          setEdges((e) => addEdge(params, e));
        }
      }

      // Update the isLastNode property of the source node EXCEPT IF ITS A OPTION/DEFAULT HANDLE
      if (params.sourceHandle === "source") {
        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === params.source) {
              return {
                ...node,
                data: {
                  ...node.data,
                  isLastNode: false,
                },
              };
            }
            return node;
          })
        );
      }

      setTimeout(() => onSave(), 0);
    },
    [edges, setEdges]
  );

  const getWorkflowData = async () => {
    try {
      const response = await getSingleWorkFlow(workflowID);

      if (response) {
        setWorkflowData(response);
        setWorkFlowSummary(response?.summary ?? "");
        response?.summary && setWorkFlowEdited(true);
      }
    } catch (error) {
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleNodeClick = (element) => {
    if (element.type !== "startNode") {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === element.id && !node.data.isActive) {
            return {
              ...node,
              data: {
                ...node.data,
                isActive: true,
              },
            };
          } else {
            return {
              ...node,
              data: {
                ...node.data,
                isActive: false,
              },
            };
          }
        })
      );

      if (selectedNode && selectedNode.id === element.id) {
        // If the clicked node is already the selected node, deselect it
        setSelectedNode(null);
        setSidebarOpen(false);
      } else {
        // If the clicked node is not the selected node, select it
        setSelectedNode(element);
        setSidebarOpen(true);
      }
    }
  };

  const deleteNode = (nodeId) => {
    let latestEdges;
    setEdges((prevEdges) => {
      latestEdges = [...prevEdges];
      return prevEdges;
    });

    // Find the edge that connects to the node being deleted
    const connectedEdge = latestEdges.find((edge) => edge.target === nodeId);

    // If there is a connected edge, find the source node
    if (connectedEdge) {
      const sourceNodeId = connectedEdge.source;
      const sourceNode = nodes.find((node) => node.id === connectedEdge.source);

      // Update the nodes, setting isLastNode to true for the source node
      setNodes((prevNodes) => {
        prevNodes = prevNodes.map((node) => {
          const isLastNode = prevNodes.find((node) => node.id === nodeId)?.data
            ?.isLastNode;

          if (connectedEdge.sourceHandle === "default") {
            return node.id === sourceNodeId
              ? { ...node, data: { ...node.data, selectedOption: null } }
              : node;
          }

          if (sourceNode.type === "messageOptionsNode") {
            return node.id === sourceNodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    // connectedOptions: node.data.connectedOptions.filter(
                    //   (opt) => opt !== connectedEdge.option.id
                    // ),
                    connectedOptions:
                      // connectedEdge.option === "default" ||
                      // connectedEdge.option === "source"
                      //   ?
                      node.data.connectedOptions.filter(
                        (opt) => opt !== connectedEdge.option
                      ),
                    // : node.data.connectedOptions.filter(
                    //     (opt) => opt !== connectedEdge.option
                    //   ),
                  },
                }
              : node;
          }

          if (connectedEdge.sourceHandle === "true") {
            return node.id === sourceNodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    ...(connectedEdge.sourceHandle === "true"
                      ? { trueFlowAdded: false }
                      : {}),
                  },
                }
              : node;
          }
          if (connectedEdge.sourceHandle === "false") {
            return node.id === sourceNodeId
              ? {
                  ...node,
                  data: {
                    ...node.data,
                    ...(connectedEdge.sourceHandle === "false"
                      ? { falseFlowAdded: false }
                      : {}),
                  },
                }
              : node;
          }

          return node.id === sourceNodeId
            ? { ...node, data: { ...node.data, isLastNode: true } }
            : node;
        });
        return prevNodes;
      });
    }

    setNodes((prevNodes) => {
      const newNodes = prevNodes.filter((node) => node.id !== nodeId);

      // setTimeout(() => onSave(), 0);
      setTimeout(() => onSave(), 0);
      return newNodes;
    });

    setEdges((prevEdges) => {
      // Remove the node and any edges connected to it
      const remainingEdges = prevEdges.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId
      );

      return [...remainingEdges];
    });
  };

  const getMessageNode = (newId) => {
    const messageNode = {
      id: `${newId}`,
      type: "messageNode",
      data: {
        title: "Text Message",
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode(messageNode);
          setClickedNode((prevClickedNode) => ({
            ...messageNode,
            data: {
              ...messageNode.data,
              selectedOption: "source",
            },
          }));
        },
        // onDelete: () => deleteNode(messageNode.id),
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(messageNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === messageNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== messageNode.id)
          );

          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return messageNode;
  };

  const getInputNode = (newId) => {
    const inputNode = {
      id: `${newId}`,
      type: "inputNode",
      data: {
        title: "Input Data",
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...inputNode,
            data: {
              ...inputNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(inputNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === inputNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) => {
            return prevEdges.filter(
              (edge) =>
                !(
                  edge.source === inputNode.id && edge.sourceHandle === "source"
                )
            );
          });
          setTimeout(() => onSave(), 0);
        },
        onOptionPlusClick: (option) => {
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...inputNode,
            data: {
              ...inputNode.data,
              selectedOption: option,
            },
          }));
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === inputNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: [],
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(edge.option === option && edge.source === inputNode.id)
            )
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return inputNode;
  };

  const getDecisionNode = (newId) => {
    const decisionNode = {
      id: `${newId}`,
      type: "decisionNode",
      data: {
        isLastNode: true,
        onTrueClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode(decisionNode);
          setClickedNode((prevClickedNode) => ({
            ...decisionNode,
            data: {
              ...decisionNode.data,
              selectedOption: "true",
            },
          }));
          // setDecisionNodeFlowType("true");
        },
        onFalseClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode(decisionNode);
          // setDecisionNodeFlowType("false");
          setClickedNode((prevClickedNode) => ({
            ...decisionNode,
            data: {
              ...decisionNode.data,
              selectedOption: "false",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(decisionNode);
        },
        onMinusClick: (option) => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === decisionNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    ...(option === "true" ? { trueFlowAdded: false } : {}),
                    ...(option === "false" ? { falseFlowAdded: false } : {}),
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(
                  edge.source === decisionNode.id &&
                  edge.sourceHandle === option
                )
            )
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return decisionNode;
  };

  const getTriggerNode = (newId) => {
    const triggerNode = {
      id: `${newId}`,
      type: "triggerNode",
      data: {
        isLastNode: true,

        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(triggerNode);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return triggerNode;
  };

  const getAudioNode = (newId) => {
    const audioNode = {
      id: `${newId}`,
      type: "audioNode",
      data: {
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode(audioNode);
          setClickedNode((prevClickedNode) => ({
            ...audioNode,
            data: {
              ...audioNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(audioNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === audioNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== audioNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
      token,
    };
    return audioNode;
  };

  const getApiNode = (newId) => {
    const apiNode = {
      id: newId,
      type: "apiNode",
      data: {
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode(apiNode);
          setClickedNode((prevClickedNode) => ({
            ...apiNode,
            data: {
              ...apiNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(apiNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === apiNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== apiNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return apiNode;
  };

  const getMessageOptionsNode = (newId) => {
    const messageOptionsNode = {
      id: `${newId}`,
      type: "messageOptionsNode",
      data: {
        isLastNode: true,
        connectedOptions: [],
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...messageOptionsNode,
            data: {
              ...messageOptionsNode.data,
              selectedOption: "source",
            },
          }));

          setNodes((prevNodes) => {
            return prevNodes.map((node) => {
              if (node.id === messageOptionsNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: "source",
                  },
                };
              }
              return node;
            });
          });
        },

        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(messageOptionsNode);
        },
        onOptionPlusClick: (option) => {
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...messageOptionsNode,
            data: {
              ...messageOptionsNode.data,
              connectedOptions: [
                ...messageOptionsNode.data.connectedOptions,
                option === "default" || option === "source"
                  ? option
                  : option.id,
              ],
              selectedOption:
                option === "default" || option === "source"
                  ? option
                  : option.id,
            },
          }));
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === messageOptionsNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    connectedOptions:
                      option === "default" || option === "source"
                        ? node.data.connectedOptions.filter(
                            (opt) => opt !== option
                          )
                        : node.data.connectedOptions.filter(
                            (opt) => opt !== option.id
                          ),
                    selectedOption: [],
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) => {
            if (option === "default" || option === "source") {
              return prevEdges.filter(
                (edge) =>
                  !(
                    edge.sourceHandle === option &&
                    edge.source === messageOptionsNode.id
                  )
              );
            } else {
              return prevEdges.filter(
                (edge) =>
                  !(
                    edge.sourceHandle &&
                    edge.sourceHandle === option.id &&
                    edge.source === messageOptionsNode.id
                  )
              );
            }
          });
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return messageOptionsNode;
  };

  const getConversationNode = (newId) => {
    const conversationNode = {
      id: `${newId}`,
      type: "conversationNode",
      data: {
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          // setClickedNode(conversationNode);

          setClickedNode((prevClickedNode) => ({
            ...conversationNode,
            data: {
              ...conversationNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(conversationNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === conversationNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) => {
            return prevEdges.filter(
              (edge) =>
                !(
                  edge.source === conversationNode.id &&
                  edge.sourceHandle === "source"
                )
            );
          });
          setTimeout(() => onSave(), 0);
        },
        onOptionPlusClick: (option) => {
          setNodeSideBarOpen(true);
          setClickedNode((prevClickedNode) => ({
            ...conversationNode,
            data: {
              ...conversationNode.data,
              selectedOption: option,
            },
          }));

          // setNodes((prevNodes) =>
          //   prevNodes.map((node) => {
          //     if (node.id === conversationNode.id) {
          //       return {
          //         ...node,
          //         data: {
          //           ...node.data,
          //           selectedOption: option,
          //         },
          //       };
          //     }
          //     return node;
          //   })
          // );
        },
        onOptionMinusClick: (option) => {
          // Delete the connected edge
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === conversationNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    selectedOption: null,
                  },
                };
              }
              return node;
            })
          );

          setEdges((prevEdges) =>
            prevEdges.filter(
              (edge) =>
                !(edge.option === option && edge.source === conversationNode.id)
            )
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };
    return conversationNode;
  };

  const getScriptNode = (newId) => {
    const scriptNode = {
      id: newId,
      type: "scriptNode",
      data: {
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          // setClickedNode(scriptNode);
          setClickedNode((prevClickedNode) => ({
            ...scriptNode,
            data: {
              ...scriptNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(scriptNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === scriptNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== scriptNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return scriptNode;
  };

  const getGoogleSheetNode = (newId) => {
    const googleSheetNode = {
      id: newId,
      type: "googleSheetNode",
      data: {
        message: "",
        isLastNode: true,
        onPlusClick: () => {
          setNodeSideBarOpen(true);
          // setClickedNode(googleSheetNode);
          setClickedNode((prevClickedNode) => ({
            ...googleSheetNode,
            data: {
              ...googleSheetNode.data,
              selectedOption: "source",
            },
          }));
        },
        onDelete: () => {
          setShowDeleteModal(true);
          setClickedNode(googleSheetNode);
        },
        onMinusClick: () => {
          setNodes((prevNodes) =>
            prevNodes.map((node) => {
              if (node.id === googleSheetNode.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    isLastNode: true,
                  },
                };
              }
              return node;
            })
          );

          // Delete the connected edge
          setEdges((prevEdges) =>
            prevEdges.filter((edge) => edge.source !== googleSheetNode.id)
          );
          setTimeout(() => onSave(), 0);
        },
      },
      position: {
        x: clickedNode.position.x,
        y: clickedNode.position.y + 150,
      },
    };

    return googleSheetNode;
  };

  const addNewNode = (nodeType) => {
    let newNode;
    let newNodes = [...nodes];
    const newId = Math.random().toString(36).slice(2, 11);
    setNodeSideBarOpen(false);

    const clickedNodeIndex = nodes.findIndex((n) => clickedNode.id === n.id);
    if (clickedNodeIndex > -1 && clickedNode.data.selectedOption === "source") {
      newNodes[clickedNodeIndex].data.isLastNode = false;
    }

    if (nodeType === "messageNode") {
      newNode = getMessageNode(newId);
    } else if (nodeType === "inputNode") {
      newNode = getInputNode(newId);
    } else if (nodeType === "decisionNode") {
      newNode = getDecisionNode(newId);
    } else if (nodeType === "triggerNode") {
      newNode = getTriggerNode(newId);
    } else if (nodeType === "apiNode") {
      newNode = getApiNode(newId);
    } else if (nodeType === "messageOptionsNode") {
      newNode = getMessageOptionsNode(newId);
    } else if (nodeType === "audioNode") {
      newNode = getAudioNode(newId);
    } else if (nodeType === "conversationNode") {
      newNode = getConversationNode(newId);
    } else if (nodeType === "scriptNode") {
      newNode = getScriptNode(newId);
    } else if (nodeType === "googleSheetNode") {
      newNode = getGoogleSheetNode(newId);
    }

    const textNodes = newNodes.filter((node) => node.type === "messageNode");
    const inputNodes = newNodes.filter((node) => node.type === "inputNode");
    const decisionNodes = newNodes.filter(
      (node) => node.type === "decisionNode"
    );
    const triggerNodes = newNodes.filter((node) => node.type === "triggerNode");
    const messageOptionsNodes = newNodes.filter(
      (node) => node.type === "messageOptionsNode"
    );
    const apiNodes = newNodes.filter((node) => node.type === "apiNode");
    const audioNodes = newNodes.filter((node) => node.type === "audioNode");
    const conversationNodes = newNodes.filter(
      (node) => node.type === "conversationNode"
    );
    const scriptNodes = newNodes.filter((node) => node.type === "scriptNode");
    const googleSheetNodes = newNodes.filter(
      (node) => node.type === "googleSheetNode"
    );

    switch (nodeType) {
      case "messageNode":
        const lastTextNode = textNodes[textNodes.length - 1];
        const lastTextNumber = lastTextNode
          ? parseInt(lastTextNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `text-message-${lastTextNumber + 1}`;
        break;
      case "inputNode":
        const lastInputNode = inputNodes[inputNodes.length - 1];
        const lastInputNumber = lastInputNode
          ? parseInt(lastInputNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `input-data-${lastInputNumber + 1}`;
        break;
      case "decisionNode":
        const lastDecisionNode = decisionNodes[decisionNodes.length - 1];
        const lastDecisionNumber = lastDecisionNode
          ? parseInt(lastDecisionNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `decision-${lastDecisionNumber + 1}`;
        break;
      case "triggerNode":
        const lastTriggerNode = triggerNodes[triggerNodes.length - 1];
        const lastTriggerNumber = lastTriggerNode
          ? parseInt(lastTriggerNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `trigger-workflow-${lastTriggerNumber + 1}`;
        break;
      case "messageOptionsNode":
        const lastMessageOptionsNode =
          messageOptionsNodes[messageOptionsNodes.length - 1];
        const lastMessageOptionsNumber = lastMessageOptionsNode
          ? parseInt(lastMessageOptionsNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `message-options-${lastMessageOptionsNumber + 1}`;
        break;
      case "audioNode":
        const lastAudioNode = audioNodes[audioNodes.length - 1];
        const lastAudioNumber = lastAudioNode
          ? parseInt(lastAudioNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `audio-${lastAudioNumber + 1}`;
        break;
      case "apiNode":
        const lastApiNode = apiNodes[apiNodes.length - 1];
        const lastApiNumber = lastApiNode
          ? parseInt(lastApiNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `api-${lastApiNumber + 1}`;
        break;
      case "conversationNode":
        const lastConversationNode =
          conversationNodes[conversationNodes.length - 1];
        const lastConversationNumber = lastConversationNode
          ? parseInt(lastConversationNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `conversation-${lastConversationNumber + 1}`;
        break;
      case "scriptNode":
        const lastScriptNode = scriptNodes[scriptNodes.length - 1];
        const lastScriptNumber = lastScriptNode
          ? parseInt(lastScriptNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `script-${lastScriptNumber + 1}`;
        break;
      case "googleSheetNode":
        const lastGoogleSheetNode =
          googleSheetNodes[googleSheetNodes.length - 1];
        const lastGoogleSheetNumber = lastGoogleSheetNode
          ? parseInt(lastGoogleSheetNode.data.name.split("-").pop())
          : 0;
        newNode.data.name = `google-sheet-${lastGoogleSheetNumber + 1}`;
        break;
      default:
        return "Node";
    }
    if (clickedNode.type === "messageOptionsNode") {
      newNode.position = {
        x: clickedNode.position.x + 150,
        y: clickedNode.position.y + 150,
      };
    }

    newNode.data.localizationMode = localizationMode;
    newNodes.push(newNode);

    // turn isActive false on every node
    newNodes = newNodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          isActive: false,
        },
      };
    });
    setSidebarOpen(false);

    setNodes(newNodes);

    const currentEdges = [...edges];

    if (clickedNode.type === "decisionNode") {
      currentEdges.push({
        id: `e-from-${clickedNode.id}`,
        source: clickedNode.id,
        sourceHandle: clickedNode.data.selectedOption,
        target: newId,
        label: clickedNode.data.selectedOption === "true" ? "True" : "False",
      });

      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === clickedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                ...(clickedNode.data.selectedOption === "true"
                  ? { trueFlowAdded: true }
                  : {}),
                ...(clickedNode.data.selectedOption === "false"
                  ? { falseFlowAdded: true }
                  : {}),
              },
            };
          }
          return node;
        })
      );

      setDecisionNodeFlowType(null);
    } else if (clickedNode.type === "conversationNode") {
      if (clickedNode.data.selectedOption !== "source") {
        const edge = {
          id: `e-from-${clickedNode.id}-${clickedNode.data.selectedOption}`,
          source: clickedNode.id,
          sourceHandle: clickedNode.data.selectedOption,
          target: newId,
          option: clickedNode.data.selectedOption,
        };
        if (clickedNode.data.selectedOption === "default") {
          edge.edgeType = "default";
        }
        currentEdges.push(edge);
        setEdges(currentEdges);

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === clickedNode.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  selectedOption: clickedNode.data.selectedOption,
                },
              };
            }
            return node;
          })
        );
      } else {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          sourceHandle: "source",
          target: newId,
        });
        setEdges(currentEdges);
      }
      return;
    } else if (clickedNode.type === "messageOptionsNode") {
      currentEdges.push({
        id: `e-from-${clickedNode.id}-${clickedNode.data.selectedOption}`,
        source: clickedNode.id,
        sourceHandle: clickedNode.data.selectedOption,
        target: newId,
        ...(clickedNode.data.selectedOption !== "source" && {
          option: clickedNode.data.selectedOption,
        }),
        ...(clickedNode.data.selectedOption === "default" && {
          edgeType: "default",
        }),
      });

      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === clickedNode.id) {
            return {
              ...node,
              data: {
                ...node.data,
                connectedOptions: [
                  ...node.data.connectedOptions,
                  clickedNode.data.selectedOption,
                ],
              },
            };
          }
          return node;
        })
      );
    } else if (clickedNode.type === "inputNode") {
      if (clickedNode.data.selectedOption !== "source") {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          sourceHandle: "default",
          edgeType: "default",
          target: newId,
          option: "default",
        });

        setNodes((prevNodes) =>
          prevNodes.map((node) => {
            if (node.id === clickedNode.id) {
              return {
                ...node,
                data: {
                  ...node.data,
                  selectedOption: clickedNode.data.selectedOption,
                },
              };
            }
            return node;
          })
        );
      } else {
        currentEdges.push({
          id: `e-from-${clickedNode.id}`,
          source: clickedNode.id,
          target: newId,
          sourceHandle: "source",
        });
      }
    } else {
      currentEdges.push({
        id: `e-from-${clickedNode.id}`,
        source: clickedNode.id,
        target: newId,
      });
    }

    setEdges(currentEdges);
  };

  const sendToServer = async (data) => {
    try {
      const response = await editWorkFlow(workflowID, {
        ...data,
      });

      if (response) {
        showSkillrToast("Workflow saved successfully", "success");
        if (data.nodes.nodes.length === 0) {
          // setNodes(initialNodes);
        } else {
          setNodes((prevNodes) => {
            const updatedNodes = prevNodes.map((currentNode) => {
              // Find the matching node in the response by ID
              const responseNode = response.nodes.find(
                (node) => node.id === currentNode.id
              );

              // If a matching node is found and the data is different, update the currentNode
              if (responseNode) {
                return {
                  ...currentNode,
                  data: {
                    ...responseNode.data,
                    ...currentNode.data,
                  },
                };
              }

              // If no matching node is found or the data is the same, return the currentNode unchanged
              return currentNode;
            });

            return updatedNodes;
          });
        }
      }
    } catch (error) {
      showSkillrToast("Failed to save workflow", "error");
    }
  };

  const onSave = () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      sendToServer({ nodes: flow });
    }
  };

  const getAllWorkFlow = async () => {
    try {
      const params = new URLSearchParams();
      params.set("fetchQuestionCount", false);
      const response = await getAllWorkFlowApi(params.toString());
      setWorkFlowList(response);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleOnWorkFlowSummary = () => {
    setSummarySection(true);
  };

  const handleCloseWorkFlowSummary = () => {
    setSummarySection(false);
  };

  const handleOnChangeSummaryNote = (event) => {
    setWorkFlowSummary(event.target.value);
  };

  const handleOnSaveSummary = () => {
    setWorkFlowEdited(true);
    sendToServer({ summary: workFlowSummary });
    setWorkflowData({ ...workflowData, summary: workFlowSummary });
  };

  const handleOnDiscardSummary = () => {
    setWorkFlowSummary(workflowData?.summary ?? "");
    setWorkFlowEdited(true);
  };

  const handleOnEditSummary = () => {
    setWorkFlowEdited(false);
  };

  const handleOnCopyToClipBoard = () => {
    navigator.clipboard.writeText(workFlowSummary);
    showSkillrToast("Copied to clipboard", "success");
  };

  const handleOnExit = () => {
    if (location?.key === "default") {
      // If location.key equals 'default', it's the first page opened in the browser history.
      navigate("/workflows");
    } else {
      navigate(-1);
    }
  };

  const checkIfFullyLocalized = (nodeId, prevNodes) => {
    let fullyLocalized = false;
    const nonDefaultVoices = bot.botVoices.filter((voice) => !voice.default);
    let node;
    if (prevNodes) {
      node = prevNodes.find((n) => n.id === nodeId);
    } else {
      node = nodes.find((n) => n.id === nodeId);
    }

    if (node?.data?.languageVariations) {
      // Check if every non-default voice is represented in languageVariations
      fullyLocalized = nonDefaultVoices.every((voice) =>
        Object.keys(node.data.languageVariations).includes(voice.languageCode)
      );
    }

    return fullyLocalized;
  };
  const checkIfNodeLocalized = (data, language = currentLanguage) => {
    let nodeLocalized = false;

    nodeLocalized = Boolean(
      data && data.languageVariations && data.languageVariations[language]
    );

    return nodeLocalized;
  };

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
    const defaultLanguage = bot.botVoices.find(
      (voice) => voice.languageCode === language
    ).default;
    setLocalizationMode(!defaultLanguage);

    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        if (node.id !== "0") {
          const nodeLocalized = checkIfNodeLocalized(node.data, language);
          // const fullyLocalized = checkIfFullyLocalized(node.id);

          return {
            ...node,
            data: {
              ...node.data,
              localizationMode: !defaultLanguage,
              fullyLocalized: nodeLocalized,
            },
          };
        } else {
          return node;
        }
      });
    });
  };

  useEffect(() => {
    if (workflowData) {
      if (workflowData.nodes.length === 0) {
        setNodes(initialNodes);
      } else {
        const updatedNodes = workflowData.nodes.map((node, index) => {
          // workflowData.nodes.map((node, index) => {
          if (node.type === "decisionNode") {
            return {
              ...node,
              data: {
                ...node.data,
                isLastNode: false,
                onTrueClick: () => {
                  setNodeSideBarOpen(true);
                  // setClickedNode(node);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "true",
                    },
                  }));
                },
                onFalseClick: () => {
                  setNodeSideBarOpen(true);
                  // setClickedNode(node);
                  // setDecisionNodeFlowType("false");
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "false",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },

                onMinusClick: (option) => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            ...(option === "true"
                              ? { trueFlowAdded: false }
                              : {}),
                            ...(option === "false"
                              ? { falseFlowAdded: false }
                              : {}),
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(
                          edge.source === node.id &&
                          edge.sourceHandle === option
                        )
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else if (node.type === "messageOptionsNode") {
            const updatedOptions = node.data.options?.map((option, index) => {
              if (!option.id) {
                let newId = Math.random().toString(36).substring(7);
                return { ...option, id: newId };
              }
              return option;
            });

            const optionMap = updatedOptions.reduce((acc, optionObj) => {
              if (
                optionObj.option !== "source" &&
                optionObj.option !== "default"
              ) {
                acc[optionObj.option] = optionObj.id;
              }
              return acc;
            }, {});

            // Update connectedOptions with new IDs
            const updatedConnectedOptions = node.data.connectedOptions.map(
              (option) => {
                if (option !== "source" && option !== "default") {
                  return optionMap[option] || option;
                }
                return option;
              }
            );

            return {
              ...node,
              data: {
                ...node.data,
                options: updatedOptions,
                connectedOptions: updatedConnectedOptions,
                localizationMode: false,
                fullyLocalized: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            connectedOptions: node.data.connectedOptions.filter(
                              (opt) => opt !== "source"
                            ),
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        edge.source !== node.id &&
                        edge.sourceHandle !== "source"
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },

                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onOptionPlusClick: (option) => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      connectedOptions: [
                        ...node.data.connectedOptions,
                        // check if option is "default" or "source"
                        option === "default" || option === "source"
                          ? option
                          : option.id,
                      ],
                      selectedOption:
                        option === "default" || option === "source"
                          ? option
                          : option.id,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  // Delete the connected edge
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            connectedOptions:
                              option === "default" || option === "source"
                                ? n.data.connectedOptions.filter(
                                    (opt) => opt !== option
                                  )
                                : n.data.connectedOptions.filter(
                                    (opt) => opt !== option.id
                                  ),
                            selectedOption: [],
                          },
                        };
                      }
                      return n;
                    })
                  );

                  setEdges((prevEdges) => {
                    if (option === "default" || option === "source") {
                      return prevEdges.filter(
                        (edge) =>
                          !(
                            edge.sourceHandle === option &&
                            edge.source === node.id
                          )
                      );
                    } else {
                      return prevEdges.filter(
                        (edge) =>
                          !(
                            edge.sourceHandle &&
                            edge.sourceHandle === option.id &&
                            edge.source === node.id
                          )
                      );
                    }
                  });
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else if (node.type === "inputNode") {
            return {
              ...node,
              data: {
                ...node.data,
                localizationMode: false,
                fullyLocalized: false,

                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) => {
                    return prevEdges.filter(
                      (edge) =>
                        !(
                          edge.source === node.id &&
                          edge.sourceHandle === "source"
                        )
                    );
                  });
                  setTimeout(() => onSave(), 0);
                },
                onOptionPlusClick: (option) => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: option,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  // Delete the connected edge
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            selectedOption: null,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(edge.option === option && edge.source === node.id)
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else if (node.type === "conversationNode") {
            return {
              ...node,
              data: {
                ...node.data,
                localizationMode: false,
                fullyLocalized: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode(() => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge which has edge.sourceHandle as "source"
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(
                          edge.source === node.id &&
                          edge.sourceHandle === "source"
                        )
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
                onOptionPlusClick: (option) => {
                  setNodeSideBarOpen(true);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: option,
                    },
                  }));
                },
                onOptionMinusClick: (option) => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            selectedOption: null,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(edge.option === option && edge.source === node.id)
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          } else {
            return {
              ...node,
              data: {
                ...node.data,
                isActive: false,
                onPlusClick: () => {
                  setNodeSideBarOpen(true);
                  setClickedNode(node);
                  setClickedNode((prevClickedNode) => ({
                    ...node,
                    data: {
                      ...node.data,
                      selectedOption: "source",
                    },
                  }));
                },
                onDelete: () => {
                  setShowDeleteModal(true);
                  setClickedNode(node);
                },
                onMinusClick: () => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            isLastNode: true,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter((edge) => edge.source !== node.id)
                  );
                  setTimeout(() => onSave(), 0);
                },

                onOptionMinusClick: (option) => {
                  setNodes((prevNodes) =>
                    prevNodes.map((n) => {
                      if (n.id === node.id) {
                        return {
                          ...n,
                          data: {
                            ...n.data,
                            selectedOption: null,
                          },
                        };
                      }
                      return n;
                    })
                  );

                  // Delete the connected edge
                  setEdges((prevEdges) =>
                    prevEdges.filter(
                      (edge) =>
                        !(edge.option === option && edge.source === node.id)
                    )
                  );
                  setTimeout(() => onSave(), 0);
                },
              },
            };
          }
          // })
          // return node;
        });

        setTimeout(() => {
          setNodes(updatedNodes);
        }, 0);
        setNodeSideBarOpen(false);

        const updatedEdges = workflowData.edges.map((edge) => {
          if (
            edge.sourceHandle &&
            (edge.sourceHandle !== "source" || edge.sourceHandle !== "default")
          ) {
            const sourceNode = updatedNodes.find(
              (node) => node.id === edge.source
            );
            if (sourceNode && sourceNode.type === "messageOptionsNode") {
              const matchingOption = sourceNode.data.options.find(
                (option) => option.option === edge.option
              );
              if (matchingOption) {
                return {
                  ...edge,
                  sourceHandle: matchingOption.id,
                  option: matchingOption.id,
                };
              }
            }
          }
          return edge;
        });

        // Use setTimeout to trigger setEdges and prevent any race condition
        setTimeout(() => {
          setEdges(updatedEdges);
        }, 0);
        // setEdges(updatedEdges);
      }

      if (workflowData.edges.length === 0) {
        setEdges([]);
      } else {
        setEdges(workflowData.edges);
      }
    }
  }, [workflowData]);

  useEffect(() => {
    if (messageSaved) {
      setTimeout(() => onSave(), 0);
      setMessageSaved(false);
    }
  }, [messageSaved]);

  useEffect(() => {
    if (bot) {
      // Check if botVoices have changed
      const voicesChanged =
        prevBotVoicesRef.current === undefined ||
        prevBotVoicesRef.current.length !== bot.botVoices.length ||
        !prevBotVoicesRef.current.every(
          (voice, index) =>
            voice.languageCode === bot.botVoices[index].languageCode
        );

      if (voicesChanged) {
        setLanguages([]); // Reset languages before setting new ones to ensure uniqueness
        bot.botVoices.forEach((voice) => {
          setLanguages((prev) => {
            if (voice.default) {
              setCurrentLanguage(voice.languageCode);
              // set localizationMode key for all nodes
              setNodes((prevNodes) => {
                return prevNodes?.map((node) => ({
                  ...node,
                  data: {
                    ...node.data,
                    localizationMode: localizationMode,
                  },
                }));
              });
              return [voice.languageCode, ...prev];
            } else {
              return [...prev, voice.languageCode];
            }
          });
        });
        // Update prevBotVoicesRef with the current botVoices
        prevBotVoicesRef.current = bot.botVoices.map((voice) => ({ ...voice }));
      }
    }
  }, [bot]);

  useEffect(() => {
    getWorkflowData();
    getAllWorkFlow();
    getBot();
  }, []);

  return (
    <>
      <Box className={styles.topBar}>
        <FlexBox
          sx={{
            borderRight: `1px solid ${theme.palette.borderColor.light}`,
            height: "100%",
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            startIcon={
              <LogOut
                color="#0f0f0f"
                style={{ transform: "rotateY(180deg)" }}
                width={20}
                height={20}
              />
            }
            sx={{ border: 0, height: "100%", borderRadius: 0 }}
            onClick={handleOnExit}
          >
            Exit
          </Button>
        </FlexBox>

        <FlexBox sx={{ padding: "0 24px" }}>
          <Typography variant="caption" color={theme.palette.text.primary}>
            {workflowData?.name}
          </Typography>

          <FlexBox ml={"auto"}></FlexBox>
        </FlexBox>

        <FundamentoPopup
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          className={styles.popup}
          disabled={languages.length === 1}
          triggeringComponent={
            <Button
              disabled={languages.length === 1}
              variant="outlined"
              endIcon={<ChevronDown width={20} height={20} />}
              sx={{
                minWidth: 0,
                width: "fit-content",
                borderRadius: 12,
              }}
            >
              {
                defaultLanguages.find((lang) => lang.value === currentLanguage)
                  ?.name
              }
            </Button>
          }
        >
          <Box>
            {languages?.map((language) => {
              if (language === currentLanguage) return <></>;
              return (
                <Box
                  className={styles.popupItem}
                  key={language}
                  onClick={() => handleLanguageChange(language)}
                >
                  <Typography variant="body2">
                    {
                      defaultLanguages.find((lang) => lang.value === language)
                        .name
                    }
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </FundamentoPopup>

        <Box
          sx={{
            position: "fixed",
            right: "110px",
          }}
        >
          <Button
            variant="dark"
            onClick={handleOnWorkFlowSummary}
            sx={{ fontSize: 12, height: "36px" }}
          >
            Workflow Summary
          </Button>
        </Box>
      </Box>

      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          deleteNode(clickedNode.id);
          setShowDeleteModal(false);
        }}
        title="Confirm delete?"
        subtitle={"Are you sure you want to delete this node?"}
      />

      {summarySection && (
        <Box
          className={styles.workflowSummary}
          sx={{ right: sidebarOpen ? "26%" : "1%" }}
        >
          <Box className={styles.workflowSummaryContainer}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                variant="body2"
                color="#0f0f0f"
                sx={{ opacity: 0.8, alignSelf: "center" }}
                fontWeight={500}
              >
                Summary
              </Typography>
              <IconButton
                className={styles.workFlowSummaryCloseButton}
                onClick={handleCloseWorkFlowSummary}
              >
                <IconClose width={20} height={20} />
              </IconButton>
            </Box>

            <TextField
              variant="outlined"
              placeholder="Enter the summary here"
              multiline={true}
              minRows={3}
              maxRows={16}
              className={styles.summaryInput}
              disabled={workFlowEdited}
              onChange={handleOnChangeSummaryNote}
              value={workFlowSummary}
              inputProps={{
                style: {
                  "-webkit-text-fill-color": theme.palette.text.primary,
                },
              }}
            />

            <Box className={styles.workFlowSummaryActions}>
              {workFlowEdited ? (
                <>
                  <Button
                    onClick={handleOnCopyToClipBoard}
                    startIcon={<Clipboard width={20} height={20} />}
                    variant="text"
                    sx={{
                      color: "#575757",
                      fontSize: 12,
                      lineHeight: "130%",
                      minWidth: 0,
                    }}
                  >
                    Copy
                  </Button>

                  <Button
                    onClick={handleOnEditSummary}
                    startIcon={
                      <PencilSimple
                        width={20}
                        height={20}
                        stroke="#575757"
                        style={{ opacity: 0.8 }}
                      />
                    }
                    variant="text"
                    sx={{
                      color: "#575757",
                      fontSize: 12,
                      lineHeight: "130%",
                      minWidth: 0,
                    }}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={handleOnSaveSummary}
                    disabled={!workFlowSummary}
                  >
                    <Check width={20} height={20} />
                  </IconButton>

                  <IconButton
                    onClick={handleOnDiscardSummary}
                    disabled={!workFlowSummary}
                  >
                    <X width={20} height={20} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {/* <WorkFlowSummary open={true} onClose={() => {}} /> */}

      <FlexBox
        columnGap={0}
        sx={{
          height: "calc(100vh - 64px)",
          width: "100%",
          "& .react-flow__attribution": { display: "none" },
        }}
      >
        <ReactFlowProvider>
          {/* <ResetViewport nodes={nodes} /> */}
          <ReactFlow
            fitView
            fitViewOptions={{ maxZoom: 2 }}
            nodeTypes={nodeTypes}
            zoomOnPinch={true}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onPaneClick={() => {
              if (nodes?.length > 0) {
                setSidebarOpen(false);
                setNodeSideBarOpen(false);
                setSelectedNode(null);
                setNodes((prevNodes) =>
                  prevNodes.map((node) => {
                    return {
                      ...node,
                      data: {
                        ...node.data,
                        isActive: false,
                      },
                    };
                  })
                );
              }
            }}
            onNodeClick={(_, element) => handleNodeClick(element)}
            onInit={setRfInstance}
          >
            {
              // save workflow when ctrl + enter is pressed
              keysPressed && setTimeout(() => onSave(), 0)
            }
            <PanelForZoom nodes={nodes} />

            <Panel position="left" style={{ margin: 0 }}>
              <Slide
                in={nodeSideBarOpen}
                direction="right"
                mountOnEnter
                unmountOnExit
              >
                <Box
                  className={styles.nodeSidebar}
                  sx={{
                    padding: 0,
                    borderLeft: 0,
                    borderRight: `1px solid ${theme.palette.borderColor.main}`,
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 16,
                      top: 16,
                    }}
                    onClick={() => setNodeSideBarOpen(false)}
                  >
                    <X width={20} height={20} />
                  </IconButton>
                  <Box
                    p={6}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.borderColor.light}`,
                    }}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      Add nodes
                    </Typography>
                  </Box>
                  <FlexBox
                    columnGap={0}
                    flexWrap="wrap"
                    alignItems="stretch"
                    sx={{ overflowY: "auto" }}
                  >
                    <Box
                      className={styles.nodeItem}
                      onClick={() => {
                        addNewNode("messageNode");
                      }}
                    >
                      <Type width={32} height={32} />
                      <Typography
                        variant="caption"
                        fontWeight={500}
                        color="text.primary"
                      >
                        Text Message
                      </Typography>
                    </Box>
                    <Box
                      className={`${styles.nodeItem}`}
                      onClick={() => {
                        addNewNode("audioNode");
                      }}
                    >
                      <SpeakerHigh width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Play Audio
                      </Typography>
                    </Box>
                    <Box
                      className={styles.nodeItem}
                      onClick={() => {
                        addNewNode("inputNode");
                      }}
                    >
                      <Textbox width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Input Data
                      </Typography>
                    </Box>
                    <Box
                      className={`${styles.nodeItem}`}
                      onClick={() => {
                        addNewNode("conversationNode");
                      }}
                    >
                      <ChatBubble width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Conversation Node
                      </Typography>
                    </Box>
                    <Box
                      className={`${styles.nodeItem}`}
                      onClick={() => {
                        addNewNode("decisionNode");
                      }}
                    >
                      <DiamondsFour width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Decision
                      </Typography>
                    </Box>
                    <Box
                      className={`${styles.nodeItem}`}
                      onClick={() => addNewNode("triggerNode")}
                    >
                      <GitBranch width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Trigger Workflow
                      </Typography>
                    </Box>
                    <Box
                      className={`${styles.nodeItem}`}
                      onClick={() => {
                        addNewNode("apiNode");
                      }}
                    >
                      <BracketsCurly width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Rest API
                      </Typography>
                    </Box>
                    <Box
                      className={styles.nodeItem}
                      onClick={() => {
                        addNewNode("scriptNode");
                      }}
                    >
                      <Code width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Script Node
                      </Typography>
                    </Box>
                    <Box
                      className={styles.nodeItem}
                      onClick={() => {
                        addNewNode("messageOptionsNode");
                      }}
                    >
                      <ListBullets width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Message With Options
                      </Typography>
                    </Box>
                    <Box
                      className={styles.nodeItem}
                      onClick={() => {
                        addNewNode("googleSheetNode");
                      }}
                    >
                      <GoogleSheets width={32} height={32} />
                      <Typography
                        variant="caption"
                        color={"text.primary"}
                        fontWeight={500}
                      >
                        Google Sheet
                      </Typography>
                    </Box>
                  </FlexBox>
                </Box>
              </Slide>
            </Panel>

            <Panel position="right" style={{ margin: 0 }}>
              {/* <Slide
                in={sidebarOpen}
                direction="left"
                mountOnEnter
                unmountOnExit
              > */}
              {sidebarOpen && (
                <Box className={styles.sidebar}>
                  <Typography variant="body1" fontWeight={500} mb={5}>
                    Properties
                  </Typography>

                  <FlexBox mb={4}>
                    {selectedNode?.type === "messageNode" && (
                      <Type width={32} height={32} />
                    )}
                    {selectedNode?.type === "inputNode" && (
                      <Textbox width={32} height={32} />
                    )}
                    {selectedNode?.type === "decisionNode" && (
                      <DiamondsFour width={32} height={32} />
                    )}
                    {selectedNode?.type === "triggerNode" && (
                      <GitBranch width={32} height={32} />
                    )}
                    {selectedNode?.type === "apiNode" && (
                      <BracketsCurly width={32} height={32} />
                    )}
                    {selectedNode?.type === "messageOptionsNode" && (
                      <ListBullets width={32} height={32} />
                    )}
                    {selectedNode?.type === "audioNode" && (
                      <SpeakerHigh width={32} height={32} />
                    )}
                    {selectedNode?.type === "conversationNode" && (
                      <ChatBubble width={32} height={32} />
                    )}

                    {selectedNode?.type === "scriptNode" && (
                      <Code width={32} height={32} />
                    )}
                    {selectedNode?.type === "googleSheetNode" && (
                      <GoogleSheets width={32} height={32} />
                    )}

                    <Box>
                      <Typography
                        component={"div"}
                        variant="caption"
                        fontSize={10}
                      >
                        Node Type
                      </Typography>

                      <Typography variant="body2" fontWeight={500}>
                        {selectedNode?.type === "messageNode" && "Text Message"}
                        {selectedNode?.type === "inputNode" && "Input Data"}
                        {selectedNode?.type === "decisionNode" && "Decision"}
                        {selectedNode?.type === "triggerNode" &&
                          "Trigger Workflow"}
                        {selectedNode?.type === "apiNode" && "REST API"}
                        {selectedNode?.type === "messageOptionsNode" &&
                          "Message with Options"}
                        {selectedNode?.type === "audioNode" && "Play Audio"}
                        {selectedNode?.type === "conversationNode" &&
                          "Conversation"}
                        {selectedNode?.type === "scriptNode" && "Script"}
                        {selectedNode?.type === "googleSheetNode" &&
                          "Google Sheet"}
                      </Typography>
                    </Box>
                  </FlexBox>

                  {selectedNode?.type === "messageNode" && (
                    <MessageProperties
                      currentLanguage={currentLanguage}
                      localizationMode={localizationMode}
                      botVoices={bot.botVoices}
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  ...(data?.gcsUri && { gcsUri: data?.gcsUri }),
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );

                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "messageOptionsNode" && (
                    <MessageOptionsProperties
                      currentLanguage={currentLanguage}
                      localizationMode={localizationMode}
                      workflows={workFlowList}
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  isActive: false,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                        if (data.deleteEdges) {
                          setEdges((prevEdges) =>
                            prevEdges.filter(
                              (edge) =>
                                edge.source !== selectedNode.id ||
                                (edge.source === selectedNode.id &&
                                  edge.edgeType === "default")
                            )
                          );
                          setNodes((prevNodes) =>
                            prevNodes.map((node) => {
                              if (node.id === selectedNode.id) {
                                return {
                                  ...node,
                                  data: {
                                    ...node.data,
                                    connectedOptions:
                                      node.data.connectedOptions.filter(
                                        (opt) => opt === "default"
                                      ),
                                  },
                                };
                              }
                              return node;
                            })
                          );
                        }
                        if (data.deleteDefault) {
                          setEdges((prevEdges) =>
                            prevEdges.filter(
                              (edge) =>
                                edge.source !== selectedNode.id ||
                                (edge.source === selectedNode.id &&
                                  edge.edgeType !== "default")
                            )
                          );
                          setNodes((prevNodes) =>
                            prevNodes.map((node) => {
                              if (node.id === selectedNode.id) {
                                return {
                                  ...node,
                                  data: {
                                    ...node.data,
                                    connectedOptions:
                                      node.data.connectedOptions.filter(
                                        (opt) => opt !== "default"
                                      ),
                                  },
                                };
                              }
                              return node;
                            })
                          );
                        }
                        if (data.deleteSource) {
                          setEdges((prevEdges) =>
                            prevEdges.filter(
                              (edge) =>
                                edge.source !== selectedNode.id ||
                                (edge.source === selectedNode.id &&
                                  edge.sourceHandle !== "source")
                            )
                          );
                          setNodes((prevNodes) =>
                            prevNodes.map((node) => {
                              if (node.id === selectedNode.id) {
                                return {
                                  ...node,
                                  data: {
                                    ...node.data,
                                    connectedOptions:
                                      node.data.connectedOptions.filter(
                                        (opt) => opt !== "source"
                                      ),
                                  },
                                };
                              }
                              return node;
                            })
                          );
                        }
                      }}
                    />
                  )}

                  {selectedNode?.type === "inputNode" && (
                    <InputProperties
                      localizationMode={localizationMode}
                      currentLanguage={currentLanguage}
                      workflows={workFlowList}
                      token={token}
                      workFlowId={workflowID}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "decisionNode" && (
                    <DecisionProperties
                      nodes={nodes}
                      data={selectedNode?.data}
                      workflows={workFlowList}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "triggerNode" && (
                    <TriggerWorkflowProperties
                      workflows={workFlowList}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "audioNode" && (
                    <AudioProperties
                      localizationMode={localizationMode}
                      currentLanguage={currentLanguage}
                      token={token}
                      workFlowId={workflowID}
                      workflows={workFlowList}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              let nodeLocalized = checkIfNodeLocalized(data);
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "apiNode" && (
                    <RestApiProperties
                      workflows={workFlowList}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "conversationNode" && (
                    <ConversationProperties
                      currentLanguage={currentLanguage}
                      localizationMode={localizationMode}
                      token={token}
                      workFlowId={workflowID}
                      workflows={workFlowList}
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            let nodeLocalized = checkIfNodeLocalized(data);
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                  fullyLocalized: nodeLocalized,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "scriptNode" && (
                    <ScriptProperties
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}

                  {selectedNode?.type === "googleSheetNode" && (
                    <GoogleSheetProperties
                      key={selectedNode?.id}
                      data={selectedNode?.data}
                      onSave={(data) => {
                        // set selected node message to new value
                        setNodes((prevNodes) =>
                          prevNodes.map((node) => {
                            if (node.id === selectedNode.id) {
                              return {
                                ...node,
                                data: {
                                  ...node.data,
                                  ...data,
                                },
                              };
                            } else {
                              return node;
                            }
                          })
                        );
                        setMessageSaved(true);
                        setSidebarOpen(false);
                      }}
                    />
                  )}
                </Box>
              )}
              {/* </Slide> */}
            </Panel>

            <Background variant="cross" gap={12} size={1} />
            {/* <DevTools /> */}
          </ReactFlow>
        </ReactFlowProvider>
      </FlexBox>
    </>
  );
};

export default Diagram;
