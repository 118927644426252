import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PageWrapper from "components/PageWrapper";
import styles from "./index.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { statusColorMapping } from "text-constants/campaign";
import { IconPause, IconPlay } from "components/SVG";
import { useURLState } from "custom-hooks/useUrlState";
import theme from "theme";
import {
  getCampaignContactDetails as getCampaignContactDetailsApi,
  getCampaignDetails as getCampaignDetailsApi,
  updateCampaign as updateCampaignApi,
} from "services";
import { useEffect, useState } from "react";
import { useSkillrToast } from "context/toast";
import DetailsList from "../DetailsList";
import EmptyState from "components/EmptyState";

const tabs = [
  { name: "All Calls", values: ["all"] },
  { name: "Answered", values: ["answered"] },
  { name: "Not Answered", values: ["noAnswered", "busy", "rejected"] },
  { name: "Not Reachable Calls", values: ["networkError"] },
  { name: "Invalid Calls", values: ["invalidNumber", "undefinedError"] },
];

const recordsPerPage = 10;

const CampaignDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useURLState(tabs[0].values, "tab");
  const [currentPage, setCurrentPage] = useURLState(1, "page");
  const [campaignContact, setCampaignContact] = useState();
  const [campaignDetails, setCampaignDetails] = useState();
  const { showSkillrToast } = useSkillrToast();
  const campaignId = params.campaignId;

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
    setCurrentPage(1);
  };

  const getCampaignContactDetails = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.set("offset", (currentPage - 1) * recordsPerPage);
      queryParams.set("count", recordsPerPage);
      queryParams.set("callType", selectedTab.join(","));
      const response = await getCampaignContactDetailsApi(
        campaignId,
        queryParams.toString()
      );
      setCampaignContact(response);
    } catch (error) {
      const errorMessage = error?.data?.response?.message
        ? error.data.response.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const getCampaignDetails = async () => {
    try {
      const campaignDetailsResponse = await getCampaignDetailsApi(
        params.campaignId
      );
      setCampaignDetails(campaignDetailsResponse);
    } catch (error) {
      const errorMessage = error?.data?.response?.message
        ? error.data.response.message
        : "Something went wrong";

      showSkillrToast(errorMessage, "error");
    }
  };

  const updateCampaign = async (payload) => {
    try {
      await updateCampaignApi(campaignId, payload);
      setCampaignDetails({ ...campaignDetails, status: payload?.status });
      showSkillrToast("Campaign status updated", "success");
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleUpdateCampaignStatus = () => {
    if (campaignDetails?.status === "active") {
      updateCampaign({ status: "paused" });
    } else if (campaignDetails?.status === "paused") {
      updateCampaign({ status: "active" });
    }
  };

  const handleEndCampaign = () => {
    updateCampaign({ status: "complete" });
  };

  useEffect(() => {
    getCampaignDetails();
    getCampaignContactDetails();
  }, [currentPage, selectedTab]);

  return (
    <PageWrapper className={styles.container}>
      {campaignDetails && (
        <Box>
          <Breadcrumbs separator={">"} aria-label="breadcrumb">
            <Typography variant="body2">
              <Link
                color="#7b7b7b"
                onClick={() => navigate(-1)}
                className={styles.campaignLink}
              >
                Campaign
              </Link>
            </Typography>
            <Typography variant="body2" color="text.primary" fontWeight={500}>
              {campaignDetails?.name}
            </Typography>
          </Breadcrumbs>

          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h4">{campaignDetails?.name}</Typography>
            {/* <Box className={styles.box} sx={{ background: "#EFEFEF" }}>
              <Typography
                fontSize={10}
                fontWeight={500}
                lineHeight="normal"
                color={"#505050"}
              >
                Harold
              </Typography>
            </Box> */}
            <Box
              className={styles.box}
              sx={{
                background:
                  statusColorMapping[campaignDetails?.status ?? "pending"]
                    .backgroundColor,
              }}
            >
              <Typography
                fontSize={10}
                fontWeight={500}
                lineHeight="normal"
                color={
                  statusColorMapping[campaignDetails?.status ?? "pending"].color
                }
              >
                {campaignDetails?.status.charAt(0).toUpperCase() +
                  campaignDetails?.status.slice(1)}
              </Typography>
            </Box>
          </Box>

          <Box mt={2} display="flex" alignItems="center">
            <Typography variant="body2" color="#797979">
              Call Slot - {campaignDetails?.startTime} -{" "}
              {campaignDetails?.endTime}
            </Typography>
            {campaignDetails?.startDate && (
              <>
                <Typography sx={{ mx: 4 }} color="#9d9d9d">
                  |
                </Typography>
                <Typography variant="body2" color="#797979">
                  Date Range - {campaignDetails.startDate} -{" "}
                  {campaignDetails.endDate}
                </Typography>
              </>
            )}
            <Box ml="auto">
              <Button variant="outlined" onClick={handleEndCampaign}>
                Stop Campaign
              </Button>

              <Button
                variant="dark"
                startIcon={
                  campaignDetails?.status === "active" ? (
                    <IconPause />
                  ) : (
                    <IconPlay />
                  )
                }
                sx={{ ml: 2, height: 40, borderRadius: 12 }}
                onClick={handleUpdateCampaignStatus}
              >
                {campaignDetails?.status === "active"
                  ? "Pause Campaign"
                  : "Start Campaign"}
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Box display="flex" gap={5} mt={8}>
        <Box className={styles.headerBoxContainer} sx={{ flex: 1 }}>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Total Calls</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.statusCount?.all ?? 0}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Connected</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.statusCount?.answered ?? 0}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Not Answered</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.noAnswered ?? 0) +
                (campaignDetails?.statusCount?.busy ?? 0) +
                (campaignDetails?.statusCount?.rejected ?? 0)}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Not Reachable</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.statusCount?.networkError ?? 0}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Invalid</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.invalidNumber ?? 0) +
                (campaignDetails?.statusCount?.undefinedError ?? 0)}
            </Typography>
          </Box>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Remaining</Typography>
            <Typography variant="body1" fontWeight={500}>
              {(campaignDetails?.statusCount?.pending ?? 0) +
                (campaignDetails?.statusCount?.processing ?? 0)}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.headerBoxContainer}>
          <Box className={styles.headerBox}>
            <Typography variant="body2">Total Duration</Typography>
            <Typography variant="body1" fontWeight={500}>
              {campaignDetails?.totalCallDuration ?? 0} Sec
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          className={styles.tabs}
          TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
          sx={{
            "& span.MuiTabs-indicator": {
              borderRadius: "10px 10px 0 0",
              height: 3,
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              className={styles.tab}
              label={
                <Typography
                  fontFamily="DM Sans"
                  variant="caption"
                  fontWeight={500}
                >
                  {tab.name} (
                  {tab.values.reduce(
                    (acc, key) =>
                      acc + (campaignDetails?.statusCount?.[key] ?? 0),
                    0
                  )}
                  )
                </Typography>
              }
              value={tab.values}
              key={index}
            />
          ))}
        </Tabs>
        <Divider
          sx={{
            backgroundColor: theme.palette.borderColor.light,
          }}
        />
      </Box>

      <Box mt={10}>
        {campaignContact?.contacts?.length === 0 && (
          <EmptyState title="No Data Found" />
        )}

        {campaignContact?.contacts?.length > 0 && (
          <DetailsList
            currentPage={currentPage}
            campaingContacts={campaignContact?.contacts ?? []}
            setCurrentPage={setCurrentPage}
            totalPageCount={Math.ceil(
              campaignContact?.totalRecords / recordsPerPage
            )}
            isPreProcessEnabled={campaignDetails?.preProcessCampaign ?? false}
          />
        )}
      </Box>
    </PageWrapper>
  );
};

export default CampaignDetails;
