import { Fade, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Plus, Trash2 } from "react-feather";
import theme from "theme";
import TargetHandle from "../../TargetHandle";
import { Link } from "react-router-dom";
import FlexBox from "components/FlexBox";
import { FlowArrow } from "components/newSVG";

const TriggerNode = (props) => {
  const {
    isActive,
    isLastNode,
    onPlusClick,
    onDelete,
    workflowName,
    workflowId,
  } = props.data;
  const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  useEffect(() => {
    setShowPlus(isLastNode);
  }, [isLastNode]);

  return (
    <div
      className={`${styles.messageNode} ${isActive ? styles.active : ""}`}
      onMouseEnter={() => setIsParentHovered(true)}
      onMouseLeave={() => setIsParentHovered(false)}
    >
      <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade>

      <Typography variant="body2" fontWeight={500}>
        {/* Trigger Workflow */}
        {props.data.name
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
          .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography
        variant="body2"
        fontWeight={500}
        mt={2}
        color={"text.secondary"}
      >
        {workflowName ? (
          <FlexBox columnGap={1}>
            <FlowArrow
              width={12}
              height={12}
              className={styles.flowArrow}
              gap={1}
            />
            <Link
              to={`/workflows/${workflowId}`}
              target="_blank"
              className={styles.link}
            >
              <Typography fontSize={10} color={theme.palette.primary.main}>
                Workflow linked: {workflowName}
              </Typography>
            </Link>
          </FlexBox>
        ) : (
          "Select a workflow"
        )}
      </Typography>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />
    </div>
  );
};

export default TriggerNode;
