import { FundamentoLogoMini, IconUserCircle } from "components/SVG";
import { Box, Typography } from "@mui/material";
import { HardDrive, Link as IconLink } from "react-feather";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import {
  ChartBar,
  ChatsCircle,
  CreditCard,
  Database,
  Flask,
  FlowArrow,
  HandMic,
  LockSimple,
  PhonePlus,
  Question,
  SparkleOutlined,
  Wrench,
  UserPlus,
} from "components/newSVG";
import { useUserContext } from "context/user";
import theme from "theme";
import { getJSONDecoded } from "utils/utils";
import { useLocation } from "react-router-dom";
import FunCollapse from "components/FunCollapse";
import RoleBasedAccess from "components/RoleBasedAccess";

const Sidebar = () => {
  const {
    token,
    setToken,
    setUserDetails,
    setCompanyDetails,
    setDataSets,
    companyDetails,
    bot,
  } = useUserContext();
  const storageLeft =
    companyDetails?.maxStorageCapacityInMB -
    Math.round(companyDetails?.usedStorageCapacity);

  const storageLeftInPercentage =
    (Math.round(companyDetails?.usedStorageCapacity) /
      companyDetails?.maxStorageCapacityInMB) *
    100;
  const tokenInfo = getJSONDecoded(token ?? null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken();
    setUserDetails();
    setCompanyDetails();
    setDataSets([]);
  };
  const currentRoute = useLocation().pathname;
  let finalUrl = bot !== "no bot" ? "/bot/playground" : "/create-bot";

  return (
    <Box
      className={styles.container}
      sx={{ background: theme.palette.grey[50] }}
    >
      <Box className={styles.companyInfo}>
        <Box className={styles.logoContainer}>
          <FundamentoLogoMini />
        </Box>
        <Typography variant="subtitle1DM">Fundamento</Typography>
      </Box>

      <Box className={styles.menuList}>
        <Link to="/calls">
          <li className={currentRoute.includes("/calls") ? styles.active : ""}>
            <ChartBar width={20} height={20} />
            <Typography variant="subtitle2DM">Analytics</Typography>
          </li>
        </Link>
        <Link to="/knowledge-base">
          <li
            className={
              currentRoute.indexOf("knowledge-base") > 0 ? styles.active : ""
            }
          >
            <Question width={20} height={20} />
            <Typography variant="subtitle2DM">Knowledge Base</Typography>
          </li>
        </Link>
        <Link to="/ai-labs">
          <li
            className={currentRoute.includes("/ai-labs") ? styles.active : ""}
          >
            <Flask width={20} height={20} />
            <Typography variant="subtitle2DM">AI Labs</Typography>
          </li>
        </Link>

        <Link to="/workflows">
          <li className={currentRoute === "/workflows" ? styles.active : ""}>
            {/* <SparkleOutlined width={20} height={20} /> */}
            <FlowArrow width={20} height={20} />
            <Typography variant="subtitle2DM">Workflows</Typography>
          </li>
        </Link>
        <Link to="/bots">
          <li className={currentRoute === "/bots" ? styles.active : ""}>
            <PhonePlus width={20} height={20} />
            <Typography variant="subtitle2DM">Deploy</Typography>
          </li>
        </Link>
        <Link to="/campaign">
          <li className={currentRoute === "/campaign" ? styles.active : ""}>
            <HandMic width={20} height={20} />
            <Typography variant="subtitle2DM">Campaign</Typography>
          </li>
        </Link>

        {/* <FunCollapse
          manualOpen={currentRoute.includes("/bot")}
          header={
            <li>
              <ChatsCircle width={20} height={20} />
              <Typography variant="subtitle2DM">Playground</Typography>
            </li>
          }
        >
          <Link to={finalUrl}>
            <li
              className={
                currentRoute.includes("/playground") ? styles.active : ""
              }
            >
              <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                Assistant
              </Typography>
            </li>
          </Link>
          <Link to={bot !== "no bot" ? "/bot/history" : "/create-bot"}>
            <li
              className={currentRoute.includes("/history") ? styles.active : ""}
            >
              <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                History
              </Typography>
            </li>
          </Link>
        </FunCollapse> */}
        <Link to={finalUrl}>
          <li className={currentRoute.includes("/bot/") ? styles.active : ""}>
            <ChatsCircle width={20} height={20} />
            <Typography variant="subtitle2DM">Playground</Typography>
          </li>
        </Link>

        {/* <Link to="/datasets">
          <li className={currentRoute === "/datasets" ? styles.active : ""}>
            <Database width={20} height={20} />
            <Typography variant="subtitle2DM">Datasets</Typography>
          </li>
        </Link> */}
        {/* <Link to="#" style={{ pointerEvents: "none", opacity: 0.8 }}>
          <li>
            <CreditCard width={20} height={20} />
            <Typography variant="subtitle2DM">Billing</Typography>
            <LockSimple width={20} height={20} />
          </li>
        </Link> */}

        {/* <FunCollapse
          manualOpen={currentRoute.includes("/settings")}
          header={
            <li>
              <Wrench width={20} height={20} />
              <Typography variant="subtitle2DM">Settings</Typography>
            </li>
          }
        >
          <RoleBasedAccess>
            <Link to="/settings/user-management">
              <li
                className={
                  currentRoute.includes("/user-management") ? styles.active : ""
                }
              >
                <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                  User Management
                </Typography>
              </li>
            </Link>
          </RoleBasedAccess>
          <Link to="/settings/assistant-settings">
            <li
              className={
                currentRoute.includes("/assistant-settings")
                  ? styles.active
                  : ""
              }
            >
              <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                Assistant Settings
              </Typography>
            </li>
          </Link>
          <Link to="/settings/prompt-settings">
            <li
              className={
                currentRoute.includes("/prompt-settings") ? styles.active : ""
              }
            >
              <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                Prompt Settings
              </Typography>
            </li>
          </Link>
          <Link to="/settings/company-settings">
            <li
              className={
                currentRoute.includes("/company-settings") ? styles.active : ""
              }
            >
              <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                Company settings
              </Typography>
            </li>
          </Link>
          <Link to="/dictionary">
            <li
              className={
                currentRoute.includes("/dictionary") ? styles.active : ""
              }
            >
              <Typography style={{ marginLeft: 32 }} variant="subtitle2DM">
                Dictionary
              </Typography>
            </li>
          </Link>
        </FunCollapse> */}
      </Box>

      <Box className={styles.menuList} style={{ marginTop: "auto" }}>
        <Link to="/settings/user-management">
          <li
            className={
              currentRoute.includes("/user-management") ? styles.active : ""
            }
          >
            <UserPlus width={20} height={20} />
            <Typography variant="subtitle2DM">Manage Users</Typography>
          </li>
        </Link>

        <li>
          <div
            style={{ color: theme.palette.grey[600] }}
            className={styles.progressContainer}
          >
            <HardDrive width={20} height={20} />
            {/* <Circle
              height={20.5}
              width={20.5}
              color="#0f0f0f15"
              className={styles.fullCircle}
            /> */}
            {/* <CircularProgress
              size={20}
              className={styles.progress}
              color={"inherit"}
              variant="determinate"
              value={storageLeftInPercentage}
            /> */}
          </div>
          <Typography variant="subtitle2DM">
            {/* {storageLeft} mb storage left */}
            {Math.abs(Math.round(companyDetails?.usedStorageCapacity))} mb used
          </Typography>
        </li>
        {/* <li>
          <BookOpenText width={20} height={20} />
          <Typography variant="subtitle2DM">Learn Redirection</Typography>
        </li> */}
        {/* <li>
          <BellRinging width={20} height={20} />
          <Typography variant="subtitle2DM">Notifications</Typography>
        </li> */}
        <Link to="/settings/system-configuration">
          <li
            className={currentRoute.includes("/settings/") ? styles.active : ""}
          >
            <Wrench width={20} height={20} />
            <Typography variant="subtitle2DM">Profile Settings</Typography>
          </li>
        </Link>
        <Link to="/integrations">
          <li
            className={
              currentRoute.includes("/integrations") ? styles.active : ""
            }
          >
            <IconLink width={20} height={20} />
            <Typography variant="subtitle2DM">Integrations</Typography>
          </li>
        </Link>
        <li>
          <IconUserCircle width={20} />
          <Typography variant="subtitle2DM">{tokenInfo?.name}</Typography>
        </li>
        <li onClick={handleLogout}>
          <Logout width={20} height={20} />
          <Typography variant="subtitle2DM">Log out</Typography>
        </li>
      </Box>
    </Box>
  );
};

export default Sidebar;
