import { Fade, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import FlexBox from "components/FlexBox";
import { TrashSimple } from "components/newSVG";
import theme from "theme";
import CustomHandle from "../../CustomHandle";
import TargetHandle from "../../TargetHandle";

const ScriptNode = (props) => {
  const {
    name,
    code,
    isActive,
    isLastNode,
    onPlusClick,
    onMinusClick,
    onDelete,
  } = props.data;

  const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  const handleClick = () => {
    if (!isLastNode) {
      onMinusClick();
    } else {
      onPlusClick();
    }
  };
  useEffect(() => {
    setShowPlus(isLastNode);
  }, [isLastNode]);

  return (
    <div
      className={`${styles.messageNode} ${isActive ? styles.active : ""}`}
      onMouseEnter={() => setIsParentHovered(true)}
      onMouseLeave={() => setIsParentHovered(false)}
    >
      <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade>

      {/* <Typography component={"div"} variant="caption" fontSize={10} mb={3}>
        {currentCount} of {totalCount}
      </Typography> */}

      <Typography variant="body2" fontWeight={500}>
        {name
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
          .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography
        variant="body2"
        fontSize={10}
        mb={3}
        color="text.secondary"
        style={{ whiteSpace: "pre-line" }}
      >
        {code && code?.length > 40 ? code.slice(0, 40) + "..." : code}
      </Typography>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />

      {/* <Handle type="source" position="bottom" /> */}

      <CustomHandle
        id="source"
        position="bottom"
        clickHandler={handleClick}
        icon={
          isLastNode ? (
            <Plus color={theme.palette.text.primary} width={12} height={12} />
          ) : (
            <Minus color={theme.palette.text.primary} width={12} height={12} />
          )
        }
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 50%)",
        }}
      />

      {/* <Handle type="source" position="bottom" /> */}

      {/* {showPlus && (
        <IconButton
          className={styles.plusButton}
          onClick={(e) => {
            e.stopPropagation();
            onPlusClick();
            // setShowPlus(false);
          }}
        >
          <Plus color={theme.palette.text.primary} width={12} height={12} />
        </IconButton>
      )} */}
    </div>
  );
};

export default ScriptNode;
