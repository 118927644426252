import { Fade, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle } from "reactflow";
import styles from "./index.module.css";
import { Minus, Plus, Trash2 } from "react-feather";
import theme from "theme";
import FlexBox from "components/FlexBox";
import CustomHandle from "../../CustomHandle";
import TargetHandle from "../../TargetHandle";

const DecisionNode = (props) => {
  const {
    isActive,
    isLastNode,
    onDelete,
    onTrueClick,
    onFalseClick,
    onMinusClick,
    trueFlowAdded,
    falseFlowAdded,
    flowType,
  } = props.data;

  const [showPlus, setShowPlus] = useState();
  const [isParentHovered, setIsParentHovered] = useState(false);

  useEffect(() => {
    setShowPlus(isLastNode);
  }, [isLastNode]);

  return (
    <div
      className={`${styles.messageNode} ${isActive ? styles.active : ""}`}
      onMouseEnter={() => setIsParentHovered(true)}
      onMouseLeave={() => setIsParentHovered(false)}
    >
      <Fade in={isParentHovered} mountOnEnter unmountOnExit>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Trash2 color={theme.palette.error.main} width={12} height={12} />
        </IconButton>
      </Fade>

      <Typography variant="body2" fontWeight={500}>
        {props.data.name
          .replace(/-/g, " ")
          .replace(/\b\w/g, (l) => l.toUpperCase())
          .replace(/ (\d+)/, " ($1)")}
      </Typography>

      <Typography variant="body2" fontSize={10} mb={3} color="text.secondary">
        {props.data.node}
      </Typography>

      <FlexBox
        sx={{
          p: "16px",
          backgroundColor: theme.palette.grey[100],
          borderRadius: "8px",
          mt: 4,
        }}
      >
        <Typography variant="caption">True</Typography>

        {flowType !== "triggerWorkflow" && (
          <CustomHandle
            id="true"
            style={{ right: "15%" }}
            position={"right"}
            icon={
              trueFlowAdded ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
            clickHandler={() => {
              trueFlowAdded ? onMinusClick("true") : onTrueClick();
            }}
          />
        )}
      </FlexBox>
      <FlexBox
        sx={{
          p: "16px",
          backgroundColor: theme.palette.grey[100],
          borderRadius: "8px",
          mt: 2,
        }}
      >
        <Typography variant="caption">False</Typography>

        {flowType !== "triggerWorkflow" && (
          <CustomHandle
            id="false"
            style={{ right: "15%" }}
            position={"right"}
            icon={
              falseFlowAdded ? (
                <Minus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              ) : (
                <Plus
                  color={theme.palette.text.primary}
                  width={12}
                  height={12}
                />
              )
            }
            clickHandler={() => {
              falseFlowAdded ? onMinusClick("false") : onFalseClick();
            }}
          />
        )}
      </FlexBox>

      <Handle
        type="target"
        position="top"
        style={{
          background: "transparent",
          border: `1px solid #666`,
          // border: 0,
          // height: 0,
          // width: 0,
          top: 5,
        }}
      />
    </div>
  );
};

export default DecisionNode;
