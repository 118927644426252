import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "pages/Auth";
import AuthCard from "pages/Auth/AuthCard";
import ForgotPassword from "pages/Auth/ForgotPassword";
import CreateCompany from "pages/CreateCompany";
import ProtectedRoute from "./protectedRoute";
import { useUserContext } from "context/user";
import { getJSONDecoded } from "utils/utils";
import Dashboard from "pages/Dashboard";
import DatasetDetail from "pages/DatasetDetail";
import Bot from "pages/Bot";
import CreateBot from "pages/CreateBot";
import Playground from "pages/Bot/container/Playground";
import PlayGroundSettings from "pages/Bot/container/Settings";
import Logs from "pages/Bot/container/Logs";
import AgentRoute from "./AgentRoute";
import Demo from "pages/Demo";
import Agent from "pages/Agent/";
import CallSummary from "pages/Agent/CallSummary";
import Chat from "pages/Agent/Chat";
import Files from "pages/DatasetDashboard/Files";
import DatasetDashboard from "pages/DatasetDashboard";
import Questions from "pages/DatasetDashboard/Questions";
import Answers from "pages/DatasetDashboard/Answers";
import DatasetsLanding from "pages/DatasetsLanding";
import UserManagement from "pages/Settings/UserManagement";
// import AssistantSettings from "pages/Settings/AssistantSettings";
import Settings from "pages/Settings";
import Calls from "pages/Calls";
// import Dictionary from "pages/Dictionary";
import PromptSettings from "pages/PromptSettings";
import CompanySettings from "pages/CompanySettings";
import React from "react";
import Diagram from "pages/Diagram";
import WorkFlow from "pages/WorkFlow";
import KnowledgeBase from "pages/KnowledgeBase";
import AILabs from "pages/AILabs";
import Intent from "pages/KnowledgeBase/Intents";
import AllProcess from "pages/AILabs/components/AllProcess";
import SingleProcess from "pages/AILabs/components/SingleProcess";
import AILabsConfigure from "pages/AILabs/components/Configure";
import Dictionary from "pages/KnowledgeBase/Dictionary";
import Bots from "pages/Bots";
import History from "pages/Bot/container/History";
import HistoryDetail from "pages/Bot/container/HistoryDetail";
import Audit from "pages/Calls/container/Audit";
import CallDetail from "pages/Calls/container/CallDetail";
import ProfileSettings from "pages/ProfileSettings";
import SystemConfiguration from "pages/ProfileSettings/components/SystemConfiguration";
import Campaign from "pages/Campaign";
import CreateCampaign from "pages/Campaign/components/CreateCampaign";
import CampaignDetails from "pages/Campaign/components/Details";
import Integration from "pages/Integrations/Integration";
import Integrations from "pages/Integrations";
import GoogleSheet from "pages/Integrations/GoogleSheet";
import CampaignLogs from "pages/Campaign/components/logs";

const AllRoutes = () => {
  const { token } = useUserContext();
  const tokenInfo = getJSONDecoded(token ?? null);

  const AuthRoute = () => {
    if (
      token &&
      tokenInfo?.companies?.length > 0
      // && tokenInfo.isAgent === false
    ) {
      return <Navigate to="/ai-labs" />;
    } else if (
      token &&
      tokenInfo?.companies?.length === 0
      //  && tokenInfo.isAgent === false
    ) {
      return <Navigate to="/create-company" />;
    }
    return (
      <Auth centered>
        <AuthCard showLogin={true} />
      </Auth>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<AuthRoute />} />
      <Route
        path="/create-account"
        element={
          <Auth centered>
            <AuthCard showSignup={true} />
          </Auth>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Auth centered>
            <ForgotPassword />
          </Auth>
        }
      />
      <Route
        path="/create-company"
        element={token ? <CreateCompany /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/datasets"
        element={
          <ProtectedRoute>
            <AllDatasets />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/dataset-detail/:datasetId"
        element={
          <ProtectedRoute>
            <DatasetDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-bot/"
        element={
          <ProtectedRoute>
            <CreateBot />
          </ProtectedRoute>
        }
      />

      <Route
        path="/bot"
        element={
          <ProtectedRoute>
            <Bot />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="playground" />} />
        <Route path="playground" element={<Playground />} />
        <Route path="logs" element={<Logs />} />
        <Route path="history" element={<History />} />
        <Route path="history/:historyId" element={<HistoryDetail />} />
        <Route path="settings" element={<PlayGroundSettings />} />
      </Route>
      <Route
        path="/demo"
        element={
          <ProtectedRoute>
            <Demo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/agent"
        element={
          <AgentRoute>
            <Agent />
          </AgentRoute>
        }
      >
        <Route path="" element={<CallSummary />} />
        {/* <Route path="summary" element={<CallSummary />} /> */}
        <Route path="chat" element={<Chat />} />
      </Route>

      <Route
        path="/dataset/:dataSetId"
        element={
          <ProtectedRoute>
            <DatasetDashboard />
          </ProtectedRoute>
        }
      >
        <Route path="files" element={<Files />} />
        <Route path="questions" element={<Questions />} />
        <Route path="answers" element={<Answers />} />
      </Route>

      <Route
        path="/datasets"
        element={
          <ProtectedRoute>
            <DatasetsLanding />
          </ProtectedRoute>
        }
      />

      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      >
        <Route path="user-management" element={<UserManagement />} />
        {/* <Route path="assistant-settings" element={<AssistantSettings />} /> */}
        {/* <Route path="prompt-settings" element={<PromptSettings />} /> */}
        <Route path="company-settings" element={<CompanySettings />} />
      </Route>

      <Route
        path="/calls"
        element={
          <ProtectedRoute>
            <Calls />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="audit" />} />
        <Route path="audit" element={<Audit />} />
        <Route path=":callId" element={<CallDetail />} />
        <Route path="dashboard" element={<Audit />} />
      </Route>

      {/* <Route
        path="/dictionary"
        element={
          <ProtectedRoute>
            <Dictionary />
          </ProtectedRoute>
        }
      /> */}

      <Route
        path="/workflows/:workflowID"
        element={
          <ProtectedRoute>
            <Diagram />
          </ProtectedRoute>
        }
      />

      <Route
        path="/workflows"
        element={
          <ProtectedRoute>
            <WorkFlow />
          </ProtectedRoute>
        }
      />

      <Route
        path="/knowledge-base"
        element={
          <ProtectedRoute>
            <KnowledgeBase />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="intents" />} />
        <Route path="intents" element={<Intent />} />
        <Route path="dictionary" element={<Dictionary />} />
      </Route>

      <Route
        path="/ai-labs"
        element={
          <ProtectedRoute>
            <AILabs />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="process" />} />
        <Route path="process" element={<AllProcess />} />
        <Route path="process/:processId" element={<SingleProcess />} />
        <Route path="configure" element={<AILabsConfigure />} />
      </Route>

      <Route
        path="/bots"
        element={
          <ProtectedRoute>
            <Bots />
          </ProtectedRoute>
        }
      />

      <Route
        path="/campaign"
        element={
          <ProtectedRoute>
            <Campaign />
          </ProtectedRoute>
        }
      />

      <Route
        path="/campaign/create"
        element={
          <ProtectedRoute>
            <CreateCampaign />
          </ProtectedRoute>
        }
      />

      <Route
        path="/campaign/edit/:campaignId"
        element={
          <ProtectedRoute>
            <CreateCampaign />
          </ProtectedRoute>
        }
      />

      <Route
        path="/campaign/:campaignId"
        element={
          <ProtectedRoute>
            <CampaignDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/campaign/:campaignId/logs"
        element={
          <ProtectedRoute>
            <CampaignLogs />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Navigate to="/ai-labs" />} />

      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <ProfileSettings />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="system-configuration" />} />
        <Route path="system-configuration" element={<SystemConfiguration />} />
      </Route>

      <Route
        path="/integrations"
        element={
          <ProtectedRoute>
            <Integrations />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Integration />} />
        <Route path="google-sheet" element={<GoogleSheet />} />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
