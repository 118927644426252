import axios from "axios";
import { checkTokenExpiry } from "utils/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_ENDPOINT + "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const axiosConfiguration = config;
    const queryParams = new URLSearchParams(window.location.search.slice(1));
    const agentToken = queryParams.get("token");
    const userToken = localStorage.getItem("token");
    const token = agentToken ?? userToken;

    if (checkTokenExpiry(userToken)) {
      /**
       * If token expired, we are removing the token
       * from local storage and we are navigating user to login page.
       */
      localStorage.removeItem("token");
      window.location.href = "/";
      return;
    }
    axiosConfiguration.headers = {
      Authorization: `Bearer ${token}`,
    };
    return axiosConfiguration;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (window.location.pathname !== "/" && error?.response?.status === 401) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default http;
