import {
  Box,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./index.module.css";
import theme from "theme";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import FlexBox from "components/FlexBox";
import { IconSearch } from "components/SVG";
import FundamentoDatePicker from "components/FundamentoDatePicker";
import _ from "lodash";

const Header = (props) => {
  const {
    title,
    subTitle,
    tabs,
    defaultActiveTab,
    onTabChange,
    searchBox,
    dateRange,
    onSearch,
    defaultSearchValue,
    onStartDateChange,
    onEndDateChange,
    searchDisabled,
  } = props;
  const [selectedTab, setSelectedTab] = useState(defaultActiveTab);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    onTabChange(newValue);
  };

  const handleOnSearchChange = _.debounce((inputEvent) => {
    onSearch(inputEvent.target.value);
  }, 1500);

  useEffect(() => {
    setSelectedTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return (
    <Box
      className={styles.container}
      sx={{ background: theme.palette.grey[50] }}
    >
      <FlexBox>
        <div>
          <Typography variant="h4" className={styles.title}>
            {title}
          </Typography>

          {subTitle && (
            <Typography variant="body2" color={theme.palette.grey[600]}>
              {subTitle}
            </Typography>
          )}
        </div>
        {searchBox && (
          <Box sx={{ ml: "auto", width: "30%" }}>
            <TextField
              disabled={searchDisabled}
              fullWidth
              placeholder="Search"
              defaultValue={defaultSearchValue}
              className={styles.searchBox}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch
                      width={20}
                      height={20}
                      style={{ flexShrink: 0 }}
                      color="#0f0f0f4d"
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleOnSearchChange}
            />
          </Box>
        )}
      </FlexBox>
      <FlexBox mt={8}>
        <div>
          {tabs?.length > 0 && (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="tabs"
              className={styles.tabs}
              TabIndicatorProps={{ style: { background: "#0f0f0f" } }}
              sx={{
                "& span.MuiTabs-indicator": {
                  borderRadius: "10px 10px 0 0",
                  height: 3,
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  className={styles.tab}
                  label={tab}
                  value={tab}
                  key={index}
                />
              ))}
            </Tabs>
          )}
        </div>

        {dateRange && (
          <FundamentoDatePicker
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        )}
      </FlexBox>
    </Box>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  tabs: PropTypes.array,
  defaultActiveTab: PropTypes.string,
  tabValue: PropTypes.string,
  searchDisabled: PropTypes.bool,
};

Header.defaultProps = {
  title: "title",
  subTitle: "subTitle",
  tabs: [],
  searchDisabled: false,
};

export default Header;
