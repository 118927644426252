import Header from "components/Header";
import PageWrapper from "components/PageWrapper";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import { useURLState } from "custom-hooks/useUrlState";
import { useState } from "react";
import { Box } from "@mui/material";
import moment from "moment";

const Calls = () => {
  const location = useLocation();
  const tabs = ["Dashboard", "Audit"];
  const [search, setSearch] = useURLState("", "search");
  // const [date, setDate] = useState({
  //   startDate: moment().startOf("month"),
  //   endDate: moment(),
  //   changed: "default",
  // });

  const [date, setDate] = useState(() => {
    const newUrlSearchParams = new URLSearchParams(window.location.search);
    return {
      startDate: newUrlSearchParams.has("startDate")
        ? moment(newUrlSearchParams.get("startDate"))
        : moment().subtract(30, "days"),
      endDate: newUrlSearchParams.has("endDate")
        ? moment(newUrlSearchParams.get("endDate"))
        : moment(),
      changed: "default",
    };
  });

  const handleOnSearchValueChange = (newSearch) => {
    setSearch(newSearch);
  };

  const handleStartDateChange = (startDateDetails) => {
    setDate((prevDate) => ({ ...prevDate, ...startDateDetails }));
  };

  const handleEndDateChange = (endDateDetails) => {
    setDate((prevDate) => ({ ...prevDate, ...endDateDetails }));
  };

  const searchBarCondition = () => {
    const [, parentPath, childPath] = location.pathname.split("/");
    if (!childPath) {
      return true;
    } else if (childPath.includes("audit")) {
      return true;
    }

    return false;
  };

  const dateRangeCondition = () => {
    const [, parentPath, childPath] = location.pathname.split("/");
    if (!childPath) {
      return true;
    } else if (childPath.includes("audit")) {
      return true;
    }

    return false;
  };

  return (
    <PageWrapper className={styles.pageWrapper}>
      <Box
        sx={{
          "& [role='tab']:first-of-type ": {
            pointerEvents: "none",
            cursor: "default",
          },
        }}
      >
        <Header
          title="Analytics"
          subTitle="Review every detail of all calls without any hassle"
          tabs={tabs}
          defaultActiveTab="Audit"
          searchBox={searchBarCondition()}
          dateRange={dateRangeCondition()}
          onSearch={handleOnSearchValueChange}
          defaultSearchValue={search}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
        />
      </Box>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Outlet context={{ search, date, setDate }} />
      </div>
    </PageWrapper>
  );
};

export default Calls;
