import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grow,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Radio,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import classes from "./index.module.css";
import {
  CheckSquare,
  Eye,
  EyeClose,
  RadioButton,
  TextFlatMp3,
  TextFlatWav,
} from "components/newSVG";
import { ChevronRight, Circle, Plus, Trash2 } from "react-feather";
import { Controller, useController, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { ChevronDown, IconInfo2, IconUpload2, RedAlert } from "components/SVG";
import theme from "theme";
import FlexBox from "components/FlexBox";
import { useSkillrToast } from "context/toast";
import uploadAudioFileApi from "services/uploadAudioFile";
import { gscUriPrefix } from "text-constants/common";
import VariableDropdown from "../VariableDropdown";
import {
  dateFormatTypes,
  multiModelResponseTypes,
} from "text-constants/diagram";
import { getAudioDuration, getNewFileName } from "utils/utils";
import UploadFile from "../../UploadFile";
import { languages as defaultLanguages } from "text-constants/common";
import AudioPlayer from "components/AudioPlayer";
import AutoComplete from "components/AutoComplete";
import AddFillerModal from "../../AddFillerModal";
import { createWorkFlow } from "services";
import InputModal from "components/InputModal";
const MAX_AUDIO_TIME = 20; // 20 sec

const InputProperties = (props) => {
  const {
    onSave,
    data,
    workFlowId,
    workflows,
    localizationMode,
    currentLanguage,
    token,
  } = props;

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "text",
      message: "",
      validationType: "text",
      variableName: "",
      responseValidation: false,
      responseValidationType: "validationCounter",
      validationCount: 2,
      failureMessage: "",
      defaultType: "addNode",
      defaultFlowAdded: false,
      multiModelResponse: false,
      multiModelResponseType: "dtmf",
      multiModelFirstResponse: true,
      multiModelRetryResponse: false,
      numberValidation: false,
      numberCustomLength: "",
      dateFormat: dateFormatTypes[0].value,
      selectedWorkflow: "",
      retryAudio: "",
      localizedAudio: "",
      localizedRetryAudio: "",
      filler: [],
    },
  });

  const filesType = {
    "audio/mpeg": (
      <TextFlatMp3 width={24} height={32} style={{ flexShrink: 0 }} />
    ),
    "audio/wav": (
      <TextFlatWav width={24} height={32} style={{ flexShrink: 0 }} />
    ),
  };

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: "message",
    control,
    rules: { required: "Message is required" },
    defaultValue: "",
  });

  const supportedFileTypes = ["audio/wav", "audio/mpeg"];
  const MAX_AUDIO_SIZE = 2 * 1024 * 1024; // 2MB
  const fileInputRef = useRef();
  const [uploadedDetails, setUploadedDetails] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const { showSkillrToast } = useSkillrToast();
  // const [showDropdown, setShowDropdown] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [showDefault, setShowDefault] = useState(false);
  const [showFillerModal, setShowFillerModal] = useState(false);
  const [showInputModal, setShowInputModal] = useState(false);
  const [optionsList, setOptionsList] = useState();

  const handleOnSave = (data) => {
    if (data?.validationType === "text") {
      data.multiModelResponse = false;
    }
    if (
      data?.validationType === "name" ||
      data?.validationType === "dateTime"
    ) {
      data.multiModelResponse = false;
    }
    if (data?.responseValidation === false) {
      data.responseValidationType = "";
      data.validationCount = 2;
      data.failureMessage = "";
      data.retryAudio = "";
      data.multiModelRetryResponse = false;
    }
    /* Checking for audio */
    if (
      data?.type === "audio" &&
      !uploadedDetails?.gcsUri &&
      !data.localizedAudio
    ) {
      showSkillrToast("Please upload audio file", "error");
      return;
    }

    data.selectedWorkflow = {
      workflowName: data.selectedWorkflow?.name,
      workflowId: data.selectedWorkflow?._id,
    };

    /* Checking for retry audio */
    if (
      data?.type === "audio" &&
      data?.responseValidation &&
      !data?.retryAudio &&
      !data?.localizedRetryAudio
    ) {
      showSkillrToast("Please upload retry audio", "error");
      return;
    }

    // add localized message, retry message, and audio
    if (localizationMode && currentLanguage) {
      data.languageVariations = {
        ...(data?.languageVariations || {}),
        [currentLanguage]: {
          ...(data?.languageVariations?.[currentLanguage] || {}),
          message: data.localizedMessage,
          gcsUri: data.localizedAudio,
          failureMessage: data.localizedFailureMessage,
          retryAudio: data.localizedRetryAudio,
        },
      };
    }
    if (data.type === "audio") {
      data.gcsUri = uploadedDetails?.gcsUri;
    }
    onSave(data);
  };

  const handleUploadProgress = (progressEvent) => {
    if (progressEvent?.loaded > 0) {
      setUploadProgress(
        Math.floor((progressEvent?.loaded / progressEvent?.total) * 100)
      );
    }
  };

  const uploadFile = async (file, isLocalized = false) => {
    try {
      const formData = new FormData();
      formData.append("audioFile", file);
      const config = {
        onUploadProgress: handleUploadProgress,
      };
      const uploadResponse = await uploadAudioFileApi(
        workFlowId,
        formData,
        config
      );

      if (isLocalized) {
        setValue("localizedAudio", uploadResponse.gcsUri);
      } else {
        setUploadedDetails(uploadResponse);
      }
      fileInputRef.current.value = "";
    } catch (error) {
      fileInputRef.current.value = "";
      setUploadProgress();
      const errorMessage = error?.message
        ? error.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };

  const handleFileChange = async (event, isLocalized = false) => {
    try {
      const file = event.target.files[0];
      const renamedFile = getNewFileName(file);
      const audioDuration = await getAudioDuration(renamedFile);
      if (audioDuration < MAX_AUDIO_TIME) {
        uploadFile(renamedFile, isLocalized);
      } else {
        showSkillrToast("Audio duration should not exceed 20 seconds", "error");
      }
    } catch (error) {
      showSkillrToast(error?.message, "error");
    }
  };

  const renderAudioSelector = () => {
    return uploadedDetails?.gcsUri ? (
      <div style={{ marginBottom: 16 }}>
        {watch("localizedAudio") ? (
          <Box display="flex" gap={3} alignItems="center">
            <audio controls key={watch("localizedAudio")}>
              <source
                src={watch("localizedAudio").replace("gs://", gscUriPrefix)}
                type={"audio/" + watch("localizedAudio").split(".").pop()}
              ></source>
            </audio>

            <IconButton onClick={() => handleOnRemoveAudio(true)}>
              <Trash2 width={16} height={16} />
            </IconButton>
          </Box>
        ) : (
          <Box
            display="flex"
            sx={{
              border: `1px dashed #0f0f0f`,
              background: "#fff",
              gap: 2,
              padding: 4,
              borderRadius: 3,
              cursor: "pointer",
            }}
            onClick={() => handleFileUpload(true)}
          >
            {uploadProgress ? (
              <LinearProgress
                value={uploadProgress}
                color="primary"
                variant="determinate"
                style={{ width: "100%" }}
                sx={{
                  height: 5,
                  borderRadius: 5,
                  bgcolor: "#E9E9E9",
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 5,
                    bgcolor: "#0F0F0F",
                  },
                }}
              />
            ) : (
              <>
                <IconUpload2 width={16} height={16} />
                <Typography variant="caption" color="#0f0f0f">
                  Upload File
                </Typography>

                <Typography variant="caption" sx={{ fontSize: 10, ml: "auto" }}>
                  Max time: 20 Sec
                </Typography>
              </>
            )}
          </Box>
        )}

        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          accept={supportedFileTypes.join(",")}
        />
      </div>
    ) : (
      <Typography variant="body2" sx={{ fontStyle: "italic", mb: 4 }}>
        Please upload audio in default language first
      </Typography>
    );
  };

  const handleOnRemoveAudio = (isLocalized = false) => {
    if (isLocalized) {
      setValue("localizedAudio", "");
    } else {
      setUploadedDetails(null);
      setValue("gcsUri", "");
    }
    setUploadProgress();
  };

  const handleFileUpload = (isLocalized = false) => {
    fileInputRef.current.click();
    fileInputRef.current.onchange = (event) =>
      handleFileChange(event, isLocalized);
  };

  const handleOnSaveFillerDetails = (newFillerDetails) => {
    const currentFillers = getValues().filler || [];
    const newFiller = newFillerDetails?.text
      ? {
          type: "text",
          text: { value: newFillerDetails.text },
          language: {
            languageCode: currentLanguage,
            languageName: defaultLanguages.find(
              (l) => l.value === currentLanguage
            ).name,
          },
        }
      : {
          type: "audio",
          audio: {
            type: newFillerDetails?.file?.type,
            name: newFillerDetails?.file?.name,
            url: newFillerDetails?.fileUrl,
            duration: newFillerDetails?.fileDuration,
          },
          language: {
            languageCode: currentLanguage,
            languageName: defaultLanguages.find(
              (l) => l.value === currentLanguage
            ).name,
          },
        };

    const updatedFillers = currentFillers.filter(
      (filler) => filler.language.languageCode !== currentLanguage
    );
    updatedFillers.push(newFiller);

    setValue("filler", updatedFillers);
    setShowFillerModal(false);
  };

  const handleOnDelete = () => {
    const currentFillers = getValues().filler;
    setValue(
      "filler",
      currentFillers.filter(
        (filler) => filler.language.languageCode !== currentLanguage
      )
    );
  };

  const createNewWorkFlow = async (data) => {
    try {
      const response = await createWorkFlow(data);
      setOptionsList((prev) => [
        { name: response.name, _id: response._id },
        ...prev,
      ]);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message
        ? error.response.data.message
        : "Something went wrong";
      showSkillrToast(errorMessage, "error");
    }
  };
  const handleCreateWorkflow = (name) => {
    setShowInputModal(false);
    createNewWorkFlow({ name }).then((response) => {
      if (response) {
        const newWorkflow = {
          name: response.name,
          _id: response._id,
        };
        reset({
          ...getValues(),
          selectedWorkflow: newWorkflow,
        });
      }
    });
  };

  useEffect(() => {
    if (
      (data.type === "audio" &&
        (data?.gcsUri ||
          data?.languageVariations?.[currentLanguage]?.gcsUri)) ||
      (data?.type === "text" &&
        (data?.message || data?.languageVariations?.[currentLanguage]?.message))
    ) {
      reset({
        ...getValues(),
        ...data,
        selectedWorkflow: {
          name: data.selectedWorkflow?.workflowName,
          _id: data.selectedWorkflow?.workflowId,
        },
        localizedMessage:
          data.languageVariations?.[currentLanguage]?.message || "",
        localizedFailureMessage:
          data.languageVariations?.[currentLanguage]?.failureMessage || "",
        localizedAudio:
          data.languageVariations?.[currentLanguage]?.gcsUri || "",
        localizedRetryAudio:
          data.languageVariations?.[currentLanguage]?.retryAudio || "",
      });
      setUploadedDetails(data);
    }
  }, [data, currentLanguage]);

  useEffect(() => {
    setOptionsList([
      { name: "Create Workflow", _id: "create_workflow" },
      ...(workflows?.workFlows || []),
    ]);
  }, [workflows]);

  useEffect(() => {
    setUploadedDetails(data);
  }, [data]);

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {/* a type radio selector for text or audio */}
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value } }) => (
          <Box display="flex" mb={4} gap={5}>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              component={"label"}
              sx={{ cursor: "pointer" }}
            >
              <Radio
                onClick={() => onChange("text")}
                checked={value === "text"}
                sx={{ padding: 0 }}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
              <Typography
                variant="caption"
                fontWeight={400}
                color="text.primary"
              >
                Text
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              component={"label"}
              sx={{ cursor: "pointer" }}
            >
              <Radio
                onClick={() => onChange("audio")}
                checked={value === "audio"}
                sx={{ padding: 0 }}
                icon={<Circle width={20} height={20} />}
                checkedIcon={<RadioButton width={20} height={20} />}
              />
              <Typography
                variant="caption"
                fontWeight={400}
                color="text.primary"
              >
                Audio
              </Typography>
            </Box>
          </Box>
        )}
      />
      {localizationMode ? (
        <>
          <Button
            variant="text"
            endIcon={
              showDefault ? (
                <Eye width={20} height={20} />
              ) : (
                <EyeClose width={20} height={20} />
              )
            }
            color="inherit"
            sx={{ minWidth: 0, width: "fit-content", mb: 4 }}
            onClick={() => setShowDefault(!showDefault)}
          >
            View Default
          </Button>

          <Grow in={showDefault} mountOnEnter unmountOnExit>
            <Box
              fullWidth
              sx={{
                backgroundColor: theme.palette.grey[50],
                borderRadius: 3,
                border: `1px solid ${theme.palette.grey[100]}`,
                padding: `16px 20px`,
                mb: 4,
              }}
            >
              {watch("type") === "text" ? (
                <Typography variant="body2">{data?.message}</Typography>
              ) : (
                <Box display="flex" gap={3} alignItems="center">
                  {data?.gcsUri && (
                    <audio controls key={data?.gcsUri}>
                      <source
                        src={data?.gcsUri.replace("gs://", gscUriPrefix)}
                        type={"audio/" + data?.gcsUri.split(".").pop()}
                      ></source>
                    </audio>
                  )}
                </Box>
              )}
            </Box>
          </Grow>

          {/* Localized audio file selector */}
          {watch("type") === "audio" && renderAudioSelector(true)}

          <Controller
            control={control}
            name="localizedMessage"
            rules={{
              required:
                watch("type") === "text"
                  ? "Message is required"
                  : "Transcript is required",
            }}
            render={({ field: { onChange, value } }) => (
              <VariableDropdown
                control={control}
                watch={watch}
                setValue={setValue}
                value={value}
                onChange={onChange}
              >
                <TextField
                  variant="outlined"
                  multiline
                  rows={5}
                  fullWidth
                  placeholder={
                    watch("type") === "text"
                      ? `Add message for ${
                          defaultLanguages.find(
                            (l) => l.value === currentLanguage
                          )?.name
                        }`
                      : `Add transcript for ${
                          defaultLanguages.find(
                            (l) => l.value === currentLanguage
                          )?.name
                        }`
                  }
                  sx={{
                    "& div": {
                      borderRadius: "12px",
                      padding: 0,
                    },
                    "& textarea": {
                      marginBottom: "32px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          alignSelf: "flex-end",
                          mb: 4,
                          mr: 2,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {`${value?.length || 0} / 500`}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 500 }}
                />
              </VariableDropdown>
            )}
          />

          {errors?.localizedMessage && (
            <FlexBox
              mt={2}
              component="span"
              columnGap={1}
              fontSize={12}
              color={"error.main"}
            >
              <RedAlert style={{ marginRight: 8 }} />
              {errors?.localizedMessage?.message}
            </FlexBox>
          )}

          {watch("responseValidation") && (
            <Box mt={3} mb={2} fullWidth>
              <Typography
                variant="caption"
                fontWeight={500}
                component="p"
                color="#0f0f0f"
              >
                Retry Message
              </Typography>

              <Grow in={showDefault} mountOnEnter unmountOnExit>
                <Box
                  fullWidth
                  sx={{
                    backgroundColor: theme.palette.grey[50],
                    borderRadius: 3,
                    border: `1px solid ${theme.palette.grey[100]}`,
                    padding: `16px 20px`,
                    mt: 4,
                  }}
                >
                  {watch("type") === "text" ? (
                    <Typography variant="body2">
                      {data?.failureMessage}
                    </Typography>
                  ) : (
                    <Box display="flex" gap={3} alignItems="center">
                      <audio controls key={data?.retryAudio}>
                        <source
                          src={data?.retryAudio.replace("gs://", gscUriPrefix)}
                          type={"audio/" + data?.gcsUri.split(".").pop()}
                        ></source>
                      </audio>
                    </Box>
                  )}
                </Box>
              </Grow>

              {watch("type") === "audio" && watch("responseValidation") && (
                <Box mt={4} mb={3}>
                  {getValues("localizedRetryAudio") ? (
                    <Box display="flex" gap={3} alignItems="center">
                      <audio controls key={getValues("localizedRetryAudio")}>
                        <source
                          src={getValues("localizedRetryAudio")?.replace(
                            "gs://",
                            gscUriPrefix
                          )}
                          type={
                            "audio/" +
                            getValues("localizedRetryAudio")?.split(".").pop()
                          }
                        ></source>
                      </audio>

                      <IconButton
                        onClick={() => {
                          setValue("localizedRetryAudio", "", {
                            shouldValidate: true,
                          });
                        }}
                      >
                        <Trash2 width={16} height={16} />
                      </IconButton>
                    </Box>
                  ) : (
                    <UploadFile
                      workFlowId={workFlowId}
                      onUploadComplete={(uploadedData) => {
                        if (uploadedData?.gcsUri) {
                          setValue("localizedRetryAudio", uploadedData.gcsUri, {
                            shouldValidate: true,
                          });
                        }
                      }}
                    />
                  )}
                </Box>
              )}
              <Controller
                name="localizedFailureMessage"
                control={control}
                rules={{
                  required: "Validation text is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    className={classes.failureMessage}
                    fullWidth
                    onChange={onChange}
                    value={value}
                    multiline
                    rows={4}
                    helperText={
                      errors?.localizedFailureMessage && (
                        <Box
                          component="span"
                          display="flex"
                          color={"error.main"}
                          alignItems="center"
                        >
                          <RedAlert style={{ marginRight: 8 }} />
                          {errors?.localizedFailureMessage?.message}
                        </Box>
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            alignSelf: "flex-end",
                            mb: 4,
                            mr: 2,
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          {`${value?.length || 0} / 500`}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 500 }}
                  />
                )}
              />
            </Box>
          )}

          {watch("filler") && watch("filler").length > 0 && (
            <Box mt={4}>
              <Typography variant="body2" fontWeight={500}>
                Filler Message
              </Typography>

              {watch("filler")
                .filter(
                  (filler) => filler.language.languageCode === currentLanguage
                )
                .map((filler, index) => (
                  <FlexBox
                    key={index}
                    sx={{
                      border: `1px solid ${theme.palette.borderColor.light}`,
                      borderRadius: 3,
                      mt: 1,
                    }}
                    padding={4}
                  >
                    {filler.type === "text" ? (
                      <Tooltip
                        title={
                          filler.text?.value?.length > 64
                            ? filler.text?.value
                            : ""
                        }
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                          fontWeight={400}
                          className={classes.textOverFlow}
                          ml={2}
                        >
                          {filler.text?.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <>
                        {filesType[filler.audio?.type]}
                        <Tooltip
                          title={
                            filler.audio?.name?.length > 64
                              ? filler.audio?.name
                              : ""
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={classes.textOverFlow}
                            ml={3}
                          >
                            {filler.audio?.name}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ ml: "auto" }}>
                          <AudioPlayer
                            key={filler.audio?.url}
                            url={filler.audio?.url.replace(
                              "gs://",
                              gscUriPrefix
                            )}
                            type={filler.audio?.type}
                          />
                        </Box>
                      </>
                    )}
                    <IconButton
                      sx={{ marginLeft: "auto" }}
                      onClick={() => handleOnDelete(index)}
                    >
                      <Trash2 style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </FlexBox>
                ))}
            </Box>
          )}

          <Button
            fullWidth
            variant="outlined"
            sx={{ borderRadius: 82, mt: 4 }}
            onClick={() => setShowFillerModal(true)}
          >
            {/* check if current language filler already exists, if it does say replace filler else add filler */}
            {watch("filler") &&
            watch("filler").find(
              (filler) => filler.language.languageCode === currentLanguage
            )
              ? "Replace Filler"
              : "Add Filler"}
          </Button>

          <AddFillerModal
            open={showFillerModal}
            onClose={() => setShowFillerModal(false)}
            onSave={handleOnSaveFillerDetails}
          />
        </>
      ) : (
        <>
          {/* audio file selector */}
          {watch("type") === "audio" && (
            <div style={{ marginBottom: 16 }}>
              {uploadedDetails?.gcsUri && (
                <Box display="flex" gap={3} alignItems="center">
                  <audio controls key={uploadedDetails?.gcsUri}>
                    <source
                      src={uploadedDetails?.gcsUri.replace(
                        "gs://",
                        gscUriPrefix
                      )}
                      type={"audio/" + uploadedDetails?.gcsUri.split(".").pop()}
                    ></source>
                  </audio>
                  <IconButton onClick={() => handleOnRemoveAudio(false)}>
                    <Trash2 width={16} height={16} />
                  </IconButton>
                </Box>
              )}

              {!uploadedDetails?.gcsUri && (
                <Box
                  display="flex"
                  sx={{
                    border: `1px dashed #0f0f0f`,
                    background: "#fff",
                    gap: 2,
                    padding: 4,
                    borderRadius: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => handleFileUpload()}
                >
                  {uploadProgress ? (
                    <LinearProgress
                      value={uploadProgress}
                      color="primary"
                      variant="determinate"
                      style={{ width: "100%" }}
                      sx={{
                        height: 5,
                        borderRadius: 5,
                        bgcolor: "#E9E9E9",
                        "& .MuiLinearProgress-bar": {
                          borderRadius: 5,
                          bgcolor: "#0F0F0F",
                        },
                      }}
                    />
                  ) : (
                    <>
                      <IconUpload2 width={16} height={16} />
                      <Typography variant="caption" color="#0f0f0f">
                        Upload File
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{ fontSize: 10, ml: "auto" }}
                      >
                        Max time: 20 Sec
                      </Typography>
                    </>
                  )}
                </Box>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept={supportedFileTypes.join(",")}
              />
            </div>
          )}

          <>
            <Controller
              control={control}
              name="message"
              rules={{
                required:
                  watch("type") === "text"
                    ? "Message is required"
                    : "Transcript is required",
              }}
              render={({ field: { onChange, value } }) => (
                <VariableDropdown
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  value={value}
                  onChange={onChange}
                >
                  <TextField
                    variant="outlined"
                    multiline
                    rows={5}
                    fullWidth
                    placeholder={
                      watch("type") === "text"
                        ? "Add Message*"
                        : "Add Transcript*"
                    }
                    sx={{
                      "& div": {
                        // overflow: "hidden",
                        borderRadius: "12px",
                        padding: 0,
                      },
                      "& textarea": {
                        marginBottom: "32px",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            alignSelf: "flex-end",
                            mb: 4,
                            mr: 2,
                            position: "absolute",
                            right: 0,
                          }}
                        >
                          {`${watch("message")?.length} / 500`}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 500 }}
                  />
                </VariableDropdown>
              )}
            />
            {errors?.message && (
              <FlexBox
                mt={2}
                component="span"
                columnGap={1}
                fontSize={12}
                color={"error.main"}
              >
                <RedAlert style={{ marginRight: 8 }} />
                {watch("type") === "text"
                  ? errors?.message?.message
                  : "Transcript is required"}
              </FlexBox>
            )}
          </>

          {/* validation type selector */}
          <Box mt={6}>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{ whiteSpace: "nowrap", mb: 2 }}
            >
              Validation Type
            </Typography>
            <Controller
              control={control}
              name="validationType"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    onChange(newValue);
                    if (newValue === "text") {
                      setValue("multiModelResponse", false);
                      setValue("responseValidation", false);
                    } else if (newValue === "name" || newValue === "dateTime") {
                      setValue("multiModelResponse", false);
                    }
                  }}
                  IconComponent={ChevronDown}
                  sx={{
                    flexGrow: 1,
                    borderRadius: 4,
                    "& li": {
                      fontSize: 12,
                    },
                  }}
                  fullWidth
                  className={`${classes.validationTypeDropDown} ${classes.select}`}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: 0,
                        boxShadow: "none",
                        backgroundColor: theme.palette.grey[50],
                      },
                    },
                  }}
                >
                  <MenuItem value="text" key="text">
                    Text
                  </MenuItem>
                  <MenuItem value="name" key="name">
                    Name
                  </MenuItem>
                  <MenuItem value="number" key="number">
                    Number
                  </MenuItem>
                  <MenuItem value="phoneNumber" key="phoneNumber">
                    Phone Number
                  </MenuItem>
                  <MenuItem value="date" key="date">
                    Date
                  </MenuItem>
                  <MenuItem value="time" key="time">
                    Time
                  </MenuItem>
                  <MenuItem value="dateTime" key="dateTime">
                    Date & Time
                  </MenuItem>
                  <MenuItem value="alphanumeric" key="alphanumeric">
                    Alphanumeric
                  </MenuItem>
                </Select>
              )}
            />
          </Box>

          {/* Number custom length */}
          {watch("validationType") === "number" && (
            <>
              <Controller
                control={control}
                name="numberValidation"
                render={({ field: { onChange, value } }) => (
                  <FlexBox
                    columnGap={2}
                    mt={4}
                    mb={4}
                    component="label"
                    sx={{ cursor: "pointer" }}
                  >
                    <Checkbox
                      onChange={onChange}
                      checked={value}
                      sx={{ padding: 0, "& > svg": { width: 24, height: 24 } }}
                      checkedIcon={<CheckSquare width={24} height={24} />}
                    />
                    <Typography variant="body2" fontWeight={500}>
                      Custom Length
                    </Typography>
                  </FlexBox>
                )}
              />

              {watch("numberValidation") && (
                <Controller
                  control={control}
                  name="numberCustomLength"
                  rules={{
                    required: "Length is required",
                    min: {
                      value: 1,
                      message: "Minimun value should be 1",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type="number"
                      fullWidth
                      autoFocus
                      className={classes.variableInput}
                      value={value}
                      onChange={onChange}
                      helperText={
                        errors?.numberCustomLength && (
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            <RedAlert style={{ marginRight: 8 }} />
                            {errors?.numberCustomLength?.message}
                          </Box>
                        )
                      }
                    />
                  )}
                />
              )}
            </>
          )}

          {/* variable name selector */}
          <Box mt={6}>
            <Typography
              variant="body2"
              fontWeight={500}
              sx={{ whiteSpace: "nowrap", mb: 2 }}
            >
              Variable name
            </Typography>

            <Controller
              control={control}
              name="variableName"
              rules={{
                required: "Variable name is required",
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  autoFocus
                  className={classes.variableInput}
                  value={value}
                  onChange={onChange}
                  helperText={
                    errors?.variableName && (
                      <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        <RedAlert style={{ marginRight: 8 }} />
                        {errors?.variableName?.message}
                      </Box>
                    )
                  }
                />
              )}
            />
          </Box>

          {/* Multimodel response switch */}
          <Box mt={6}>
            <Controller
              control={control}
              name="multiModelResponse"
              render={({ field: { onChange, value } }) => {
                const validationType = watch("validationType");

                return (
                  <Box display="flex" gap={2}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      className={
                        validationType === "text" || validationType === "name"
                          ? classes.disabled
                          : {}
                      }
                    >
                      Multimodel Response
                    </Typography>
                    <Tooltip title="Enable this option to allow the customer to respond via voice, DTMF (keypad input), or both.">
                      <IconInfo2
                        width={20}
                        height={20}
                        className={
                          validationType === "text" || validationType === "name"
                            ? classes.disabled
                            : {}
                        }
                      />
                    </Tooltip>
                    <Switch
                      checked={value}
                      onChange={onChange}
                      size="small"
                      className={
                        validationType === "text" || validationType === "name"
                          ? classes.disabled
                          : {}
                      }
                    />
                  </Box>
                );
              }}
            />
            {watch("multiModelResponse") && (
              <>
                <Controller
                  control={control}
                  name="multiModelResponseType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      IconComponent={ChevronDown}
                      sx={{
                        mt: 4,
                        flexGrow: 1,
                        borderRadius: 3,
                        "& li": {
                          fontSize: 12,
                        },
                      }}
                      fullWidth
                      className={`${classes.validationTypeDropDown} ${classes.select}`}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                    >
                      {multiModelResponseTypes.map((mmtype) => (
                        <MenuItem value={mmtype.value} key={mmtype.value}>
                          {mmtype.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />

                <Box display="flex" justifyContent="space-between" mt={4}>
                  <Controller
                    control={control}
                    name="multiModelFirstResponse"
                    render={({ field: { onChange, value } }) => (
                      <FlexBox
                        columnGap={2}
                        mt={3}
                        component="label"
                        sx={{ cursor: "pointer" }}
                      >
                        <Checkbox
                          onChange={onChange}
                          checked={value}
                          sx={{
                            padding: 0,
                            "& > svg": { width: 24, height: 24 },
                          }}
                          checkedIcon={<CheckSquare width={24} height={24} />}
                        />
                        <Typography variant="body2" fontWeight={500}>
                          First Response
                        </Typography>
                      </FlexBox>
                    )}
                  />
                  <Controller
                    control={control}
                    name="multiModelRetryResponse"
                    render={({ field: { onChange, value } }) => (
                      <FlexBox
                        columnGap={2}
                        mt={3}
                        component="label"
                        sx={{ cursor: "pointer" }}
                      >
                        <Checkbox
                          onChange={onChange}
                          checked={value}
                          sx={{
                            padding: 0,
                            "& > svg": { width: 24, height: 24 },
                          }}
                          checkedIcon={<CheckSquare width={24} height={24} />}
                        />
                        <Typography variant="body2" fontWeight={500}>
                          Retry Response
                        </Typography>
                      </FlexBox>
                    )}
                  />
                </Box>
                {!watch("multiModelFirstResponse") &&
                  !watch("multiModelRetryResponse") && (
                    <Box
                      component="span"
                      display="flex"
                      alignItems="center"
                      mt={2}
                    >
                      <RedAlert style={{ marginRight: 8 }} />
                      <Typography variant="caption" color="error">
                        One option needs to be selected
                      </Typography>
                    </Box>
                  )}
              </>
            )}
          </Box>

          {/* Time capture format */}
          {watch("validationType") === "time" && (
            <Box display="flex" gap={2} alignItems="center" mt={4}>
              <IconInfo2 width={20} height={20} />
              <Typography variant="caption">
                Time will be captured in HHMM format
              </Typography>
            </Box>
          )}

          {/* Date format drop down */}
          {watch("validationType") === "date" &&
            watch("multiModelResponse") && (
              <>
                <Typography variant="caption" color="text.primary" mt={4}>
                  Format
                </Typography>
                <Controller
                  control={control}
                  name="dateFormat"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      IconComponent={ChevronDown}
                      sx={{
                        mt: 1,
                        flexGrow: 1,
                        borderRadius: 3,
                        "& li": {
                          fontSize: 12,
                        },
                      }}
                      fullWidth
                      className={`${classes.validationTypeDropDown} ${classes.select}`}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            borderRadius: 0,
                            boxShadow: "none",
                            backgroundColor: theme.palette.grey[50],
                          },
                        },
                      }}
                    >
                      {dateFormatTypes.map((df) => (
                        <MenuItem value={df.value} key={df.value}>
                          {df.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </>
            )}

          {/* response validation switch */}
          <Box mt={6} mb={6}>
            <Controller
              control={control}
              name="responseValidation"
              render={({ field: { onChange, value } }) => {
                const validationType = watch("validationType");
                return (
                  <Box display="flex" gap={2}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      className={
                        validationType === "text" ? classes.disabled : {}
                      }
                    >
                      Response Validation
                    </Typography>
                    <Tooltip title="Validate customer response and trigger retry if incorrect.">
                      <IconInfo2
                        width={20}
                        height={20}
                        className={
                          validationType === "text" ? classes.disabled : {}
                        }
                      />
                    </Tooltip>
                    <Switch
                      checked={value}
                      onChange={(event) => {
                        onChange(event.target.checked);
                        event.target.checked
                          ? setValue(
                              "responseValidationType",
                              "validationCounter"
                            )
                          : setValue("responseValidationType", "");
                      }}
                      size="small"
                      className={
                        validationType === "text" ? classes.disabled : {}
                      }
                    />
                  </Box>
                );
              }}
            />

            {/* response validation type selector */}
            <Grow in={watch("responseValidation")} unmountOnExit mountOnEnter>
              <div>
                <Controller
                  control={control}
                  name="responseValidationType"
                  render={({ field: { onChange, value } }) => (
                    <div style={{ width: "100%" }}>
                      {/* Retry message */}
                      <Box mt={3}>
                        {/* failure message/validation text */}
                        <Box mt={3} fullWidth>
                          <Typography
                            variant="caption"
                            fontWeight={500}
                            component="p"
                            color="#0f0f0f"
                          >
                            Retry Message
                          </Typography>
                          {watch("type") === "text" && (
                            <Controller
                              name="failureMessage"
                              control={control}
                              rules={{
                                required: "Validation text is required",
                              }}
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  className={classes.failureMessage}
                                  fullWidth
                                  onChange={onChange}
                                  value={value}
                                  multiline
                                  rows={4}
                                  helperText={
                                    errors?.failureMessage && (
                                      <Box
                                        component="span"
                                        display="flex"
                                        alignItems="center"
                                        color={"error.main"}
                                      >
                                        <RedAlert style={{ marginRight: 8 }} />
                                        {errors?.failureMessage?.message}
                                      </Box>
                                    )
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        sx={{
                                          alignSelf: "flex-end",
                                          mb: 4,
                                          mr: 2,
                                          position: "absolute",
                                          right: 0,
                                        }}
                                      >
                                        {`${
                                          watch("failureMessage")?.length
                                        } / 500`}
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{ maxLength: 500 }}
                                />
                              )}
                            />
                          )}

                          {watch("type") === "audio" && (
                            <Box mt={4} mb={3}>
                              {getValues("retryAudio") ? (
                                <Box display="flex" gap={3} alignItems="center">
                                  <audio controls key={getValues("retryAudio")}>
                                    <source
                                      src={getValues("retryAudio")?.replace(
                                        "gs://",
                                        gscUriPrefix
                                      )}
                                      type={
                                        "audio/" +
                                        getValues("retryAudio")
                                          ?.split(".")
                                          .pop()
                                      }
                                    ></source>
                                  </audio>

                                  <IconButton
                                    onClick={() => {
                                      setValue("retryAudio", "", {
                                        shouldValidate: true,
                                      });
                                    }}
                                  >
                                    <Trash2 width={16} height={16} />
                                  </IconButton>
                                </Box>
                              ) : (
                                <UploadFile
                                  workFlowId={workFlowId}
                                  onUploadComplete={(uploadedData) => {
                                    if (uploadedData?.gcsUri) {
                                      setValue(
                                        "retryAudio",
                                        uploadedData.gcsUri,
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    }
                                  }}
                                />
                              )}
                            </Box>
                          )}
                        </Box>

                        <Typography variant="caption" color="text.primary">
                          Count
                        </Typography>

                        <Controller
                          name="validationCount"
                          control={control}
                          rules={{
                            required: "Count is required",
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                borderRadius: 0,
                                boxShadow: "none",
                                backgroundColor: theme.palette.grey[50],
                              },
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              value={value}
                              onChange={onChange}
                              className={classes.select}
                              sx={{
                                borderRadius: 3,
                              }}
                              fullWidth
                              IconComponent={ChevronDown}
                            >
                              {[...Array(10)].map((_, i) => (
                                <MenuItem key={i} value={i + 1}>
                                  {i + 1}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </Box>
                    </div>
                  )}
                />
              </div>
            </Grow>
          </Box>

          {watch("filler") && watch("filler").length > 0 && (
            <Box>
              <Typography variant="body2" fontWeight={500}>
                Filler Message
              </Typography>

              {watch("filler")
                .filter(
                  (filler) => filler.language.languageCode === currentLanguage
                )
                .map((filler, index) => (
                  <FlexBox
                    key={index}
                    sx={{
                      border: `1px solid ${theme.palette.borderColor.light}`,
                      borderRadius: 3,
                      mt: 1,
                    }}
                    padding={4}
                  >
                    {filler.type === "text" ? (
                      <Tooltip
                        title={
                          filler.text?.value?.length > 64
                            ? filler.text?.value
                            : ""
                        }
                      >
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                          fontWeight={400}
                          className={classes.textOverFlow}
                          ml={2}
                        >
                          {filler.text?.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <>
                        {filesType[filler.audio?.type]}
                        <Tooltip
                          title={
                            filler.audio?.name?.length > 64
                              ? filler.audio?.name
                              : ""
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            fontWeight={400}
                            className={classes.textOverFlow}
                            ml={3}
                          >
                            {filler.audio?.name}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ ml: "auto" }}>
                          <AudioPlayer
                            key={filler.audio?.url}
                            url={filler.audio?.url.replace(
                              "gs://",
                              gscUriPrefix
                            )}
                            type={filler.audio?.type}
                          />
                        </Box>
                      </>
                    )}
                    <IconButton
                      sx={{ marginLeft: "auto" }}
                      onClick={() => handleOnDelete(index)}
                    >
                      <Trash2 style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </FlexBox>
                ))}
            </Box>
          )}

          <Button
            fullWidth
            variant="outlined"
            sx={{ borderRadius: 82, mt: 4 }}
            onClick={() => setShowFillerModal(true)}
          >
            {/* check if current language filler already exists, if it does say replace filler else add filler */}
            {watch("filler") &&
            watch("filler").find(
              (filler) => filler.language.languageCode === currentLanguage
            )
              ? "Replace Filler"
              : "Add Filler"}
          </Button>

          <AddFillerModal
            open={showFillerModal}
            onClose={() => setShowFillerModal(false)}
            onSave={handleOnSaveFillerDetails}
          />

          {/* Failure Configuration */}
          <Box style={{ width: "100%" }} mt={4} mb={6}>
            <FlexBox columnGap={1}>
              <Typography variant="body2" fontWeight={500}>
                Failure Configuration
              </Typography>
            </FlexBox>

            <FlexBox mt={2} mb={4}>
              <FlexBox
                columnGap={1}
                component="label"
                sx={{ cursor: "pointer" }}
              >
                <Controller
                  control={control}
                  name="defaultType"
                  render={({ field: { onChange, value } }) => (
                    <Radio
                      onClick={() => onChange("addNode")}
                      checked={value === "addNode"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                  )}
                />
                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                  sx={{ cursor: "pointer" }}
                >
                  Add Node
                </Typography>
              </FlexBox>

              <FlexBox
                columnGap={1}
                component="label"
                sx={{ cursor: "pointer" }}
              >
                <Controller
                  control={control}
                  name="defaultType"
                  render={({ field: { onChange, value } }) => (
                    <Radio
                      onClick={() => onChange("triggerWorkflow")}
                      checked={value === "triggerWorkflow"}
                      sx={{ padding: 0 }}
                      icon={<Circle width={20} height={20} />}
                      checkedIcon={<RadioButton width={20} height={20} />}
                    />
                  )}
                />

                <Typography
                  variant="caption"
                  fontWeight={400}
                  color="text.primary"
                  sx={{ cursor: "pointer" }}
                >
                  Trigger Workflow
                </Typography>
              </FlexBox>
            </FlexBox>

            {watch("defaultType") === "addNode" && (
              <FlexBox mb={6} gap={2}>
                <IconInfo2 style={{ width: 20, height: 20 }} />
                <Typography variant="caption" color="#0f0f0f">
                  Configure directly from the node.
                </Typography>
              </FlexBox>
            )}

            {watch("defaultType") === "triggerWorkflow" && (
              <>
                <Typography variant="caption" mt={4} color="#0f0f0f">
                  Failure Workflow
                </Typography>
                <Controller
                  control={control}
                  name="selectedWorkflow"
                  rules={{ required: "Workflow is required" }}
                  render={({ field: { onChange, value } }) => (
                    <AutoComplete
                      value={value}
                      renderOptionKey="name"
                      autoComplete
                      error={errors?.workflow}
                      // optionList={workflows?.workFlows}
                      optionList={optionsList}
                      // onChange={onChange}
                      closeIcon={false}
                      ListboxProps={{ style: { maxHeight: "12rem" } }}
                      className={classes.formField}
                      onChange={(newValue) => {
                        if (newValue && newValue._id === "create_workflow") {
                          setShowInputModal(true);
                        } else {
                          onChange(newValue);
                        }
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Typography variant="body2">
                            {option._id === "create_workflow" ? (
                              <FlexBox
                                style={{ backgroundColor: `white !important` }}
                              >
                                <Plus size={16} />
                                {option.name}
                              </FlexBox>
                            ) : (
                              option.name
                            )}
                          </Typography>
                        </li>
                      )}
                      PaperComponent={({ children }) => (
                        <Grow in={true} mountOnEnter unmountOnExit>
                          <div className={classes.autoCompleteList}>
                            {children}
                          </div>
                        </Grow>
                      )}
                    />
                  )}
                />
                <InputModal
                  open={showInputModal}
                  onClose={() => setShowInputModal(false)}
                  onConfirm={(name) => {
                    handleCreateWorkflow(name);
                  }}
                  title={"Create Workflow"}
                  submitText={"Create"}
                  placeholder="Enter workflow name"
                />
              </>
            )}
            {errors?.selectedWorkflow && (
              <Box
                mb={4}
                mt={2}
                component="span"
                display="flex"
                alignItems="center"
              >
                <RedAlert style={{ marginRight: 8 }} />
                <Typography variant="caption" color="error">
                  {errors?.selectedWorkflow?.message}
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
      <Button
        variant="dark"
        sx={{
          borderRadius: 82,
          minWidth: 0,
          mt: "auto",
          width: "max-content",
        }}
        onClick={handleSubmit(handleOnSave)}
        endIcon={<ChevronRight width={20} height={20} />}
      >
        Save
      </Button>
    </Box>
  );
};

export default InputProperties;
